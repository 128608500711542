import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

export const ExplorerBudgetBySector: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/budget-by-sector/{years}`
  ),
};

export const ExplorerHomeBudgetBySector: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/budget-by-sector/{years}`
  ),
};
