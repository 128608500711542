import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowForward from "@mui/icons-material/ArrowForward";
import ExploreHeroImage from "app/assets/images/explorer-hero-image.png";

const ButtonStyle = {
  color: colors.primary.white,
  padding: "10px 15px",
  width: "max-content",
  fontSize: "18px",
  lineHeight: "normal",
};

export const Hero: React.FC = () => {
  return (
    <Grid
      container
      columnSpacing={"50px"}
      columns={100}
      sx={{
        padding: "20px 0",
      }}
    >
      <Grid
        item
        xs={100}
        md={44.6}
        sx={{
          "> img": {
            width: "100%",
            height: "auto",
          },
        }}
      >
        <img src={ExploreHeroImage} alt="explore-hero-image" />
      </Grid>
      <Grid
        item
        xs={100}
        md={54.4}
        sx={{
          "> img": {
            width: "100%",
            height: "auto",
          },
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h3">Unlock IATI Insights, Spark Change</Typography>
        <Box height={10} />
        <Typography variant="body1" fontSize={"18px"}>
          Empower your mission with AIDA Explorer. Dive into IATI data, gain
          valuable insights, and drive impactful decisions. Your journey to
          transform data into meaningful impact starts here.
        </Typography>
        <Box height={30} />
        <Box
          sx={{
            display: "flex",
            columnGap: "20px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              ...ButtonStyle,
              background: colors.primary.blue,
            }}
            endIcon={<ArrowForward />}
            component={Link}
            to="/explorer"
          >
            AIDA Explorer
          </Button>

          <Button
            variant="contained"
            sx={{
              ...ButtonStyle,
              background: colors.primary.green,
              ":hover": {
                background: colors.shades.green[400],
              },
            }}
            endIcon={<ArrowForward />}
            component={Link}
            to="/publisher"
          >
            AIDA Publisher
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
