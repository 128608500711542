export interface SDGChartProps {
  expanded?: boolean;
  labelGroup?: string;
  data: { sdg: number; value: number; name: string }[];
}

export const SDGSampleData = [
  { sdg: 1, value: 1963, name: "End poverty in all its forms everywhere" },
  {
    sdg: 2,
    value: 1852,
    name: "End hunger, achieve food security and improved nutrition and promote sustainable agriculture",
  },
  {
    sdg: 3,
    value: 1569,
    name: "Ensure healthy lives and promote well-being for all at all ages",
  },
  {
    sdg: 4,
    value: 1503,
    name: "Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all",
  },
  {
    sdg: 5,
    value: 2305,
    name: "Achieve gender equality and empower all women and girls",
  },
  {
    sdg: 6,
    value: 2304,
    name: "Ensure availability and sustainable management of water and sanitation for all",
  },
  {
    sdg: 7,
    value: 632,
    name: "Ensure access to affordable, reliable, sustainable and modern energy for all",
  },
  {
    sdg: 8,
    value: 549,
    name: "Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all",
  },
  {
    sdg: 9,
    value: 1053,
    name: "Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation",
  },
  { sdg: 10, value: 502, name: "Reduce inequality within and among countries" },
  {
    sdg: 11,
    value: 1032,
    name: "Make cities and human settlements inclusive, safe, resilient and sustainable",
  },
  {
    sdg: 12,
    value: 609,
    name: "Ensure sustainable consumption and production patterns",
  },
  {
    sdg: 13,
    value: 4509,
    name: "Take urgent action to combat climate change and its impacts",
  },
  {
    sdg: 14,
    value: 1034,
    name: "Conserve and sustainably use the oceans, seas and marine resources for sustainable development",
  },
  {
    sdg: 15,
    value: 1125,
    name: "Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss",
  },
  {
    sdg: 16,
    value: 3459,
    name: "Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels",
  },
  {
    sdg: 17,
    value: 945,
    name: "Strengthen the means of implementation and revitalize the Global Partnership for Sustainable Development",
  },
];

export const SDGSampleData2 = [
  {
    sdg: 3,
    value: 3000,
    name: "Ensure healthy lives and promote well-being for all at all ages",
  },
  {
    sdg: 13,
    value: 1300,
    name: "Take urgent action to combat climate change and its impacts",
  },
  {
    sdg: 5,
    value: 5000,
    name: "Achieve gender equality and empower all women and girls",
  },
  {
    sdg: 10,
    value: 1000,
    name: "Reduce inequality within and among countries",
  },
  { sdg: 1, value: 1000, name: "End poverty in all its forms everywhere" },
  {
    sdg: 2,
    value: 2000,
    name: "End hunger, achieve food security and improved nutrition and promote sustainable agriculture",
  },
];
