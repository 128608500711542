import React from "react";
import { colors } from "app/theme";

export interface GenericPageBlockItemProps {
  title: string;
  description: string | React.ReactNode;
  content: React.ReactNode | React.ReactNode[];
  rightToolbar?: React.ReactNode;
  toolbarShare?: boolean;
  toolbarExpand?: boolean;
  onExpand?: () => void;
  expanded?: boolean;
  height?: string;
  loading?: boolean;
  altLoading?: boolean;
}

export const GenericPageBlockItemLocationDescription: React.FC<{
  items: string[];
  period: string;
}> = (props) => {
  if (props.items.length === 0) {
    return (
      <React.Fragment>
        No countries found for period {props.period}.
      </React.Fragment>
    );
  }
  if (props.items.length === 1) {
    return (
      <React.Fragment>
        <span
          style={{
            color: colors.primary.blue,
          }}
        >
          {props.items[0]}
        </span>{" "}
        was the largest recipient country in {props.period}.
      </React.Fragment>
    );
  }
  const countries = props.items.slice(0, props.items.length - 1).map((i) => (
    <span
      key={i}
      style={{
        color: colors.primary.blue,
      }}
    >
      {i}
      {", "}
    </span>
  ));

  return (
    <React.Fragment>
      {countries}
      and{" "}
      <span
        style={{
          color: colors.primary.blue,
        }}
      >
        {props.items[props.items.length - 1]}
      </span>{" "}
      were the largest recipient countries in {props.period}.
    </React.Fragment>
  );
};
