import { Box, Button } from "@mui/material";
import { PageHeader } from "app/components/page-header";
import { ReactComponent as AccountProfileHeaderIcon } from "app/assets/vectors/AccountProfileHeaderIcon.svg";
import { ReactComponent as AccountProfileDeleteIcon } from "app/assets/vectors/AccountProfileDeleteIcon.svg";
import { ReactComponent as LogOutIcon } from "app/assets/vectors/HeaderSignInViewLogOut.svg";
import { colors } from "app/theme";

export const AccountProfileLayout: React.FC<{
  children: React.ReactNode;
}> = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box>
      <PageHeader
        variant="explorer"
        title="Account Profile"
        subtitle="Sign up, manage your account settings and get notifications on your activity alerts."
        icon={<AccountProfileHeaderIcon />}
      />
      <Box height={40} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "8px",
          "> button": {
            gap: "10px",
            height: "32px",
            display: "flex",
            fontSize: "12px",
            fontWeight: "700",
            borderRadius: "5px",
            alignItems: "center",
            textTransform: "none",
            justifyContent: "center",
            color: colors.primary.white,
            background: colors.secondary.red,
            ":hover": {
              background: colors.secondary.darkRed,
            },
            "> .MuiButton-icon": {
              margin: 0,
            },
          },
        }}
      >
        <Button startIcon={<LogOutIcon />} onClick={() => {}} sx={{}}>
          Log Out
        </Button>
        <Button
          startIcon={<AccountProfileDeleteIcon />}
          onClick={() => {}}
          sx={{}}
        >
          Delete Account
        </Button>
      </Box>
      <Box height={40} />

      {children}
    </Box>
  );
};
