import React from "react";
import Box from "@mui/material/Box";
import { Hero } from "app/pages/home/explorer/components/hero";
import { IatiDataInNumbers } from "app/pages/home/explorer/components/iati-in-numbers";
import { LiveDataUpdates } from "app/pages/home/explorer/components/live-data-updates";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import get from "lodash/get";

export const ExplorerLanding: React.FC = () => {
  const fetchSDGs = useStoreActions(
    (actions) => actions.ExplorerHomeSDGs.fetch
  );
  const fetchBudgetBySector = useStoreActions(
    (actions) => actions.ExplorerHomeBudgetBySector.fetch
  );
  const fetchOrgNetwork = useStoreActions(
    (actions) => actions.ExplorerHomeOrganisationsNetwork.fetch
  );
  const fetchSectors = useStoreActions(
    (actions) => actions.ExplorerHomeSectors.fetch
  );
  const fetchOrgDonut = useStoreActions(
    (actions) => actions.ExplorerHomeOrganisationsDonut.fetch
  );
  const fetchActivities = useStoreActions(
    (actions) => actions.ExplorerHomeActivityTable.fetch
  );

  const sdgsData: {
    count: number;
    data: {
      code: number;
      name: string;
      value: number;
    }[];
    changePercentage: number;
  } = useStoreState((state) =>
    get(state.ExplorerHomeSDGs.data, "data", {
      count: 0,
      data: [],
      changePercentage: 0,
    })
  );
  const budgetBySector = useStoreState((state) =>
    get(state.ExplorerHomeBudgetBySector, "data.data", {
      bars: [],
      series: [],
      values: [],
    })
  );
  const orgNetwork = useStoreState((state) =>
    get(state.ExplorerHomeOrganisationsNetwork, "data.data", {
      nodes: [],
      links: [],
      categories: [],
    })
  );
  const sectors = useStoreState((state) =>
    get(state.ExplorerHomeSectors, "data.data", [])
  );
  const orgDonut = useStoreState((state) =>
    get(state.ExplorerHomeOrganisationsDonut, "data.data", [])
  );
  const activities = useStoreState((state) =>
    get(state.ExplorerHomeActivityTable, "data.data", [])
  );

  React.useEffect(() => {
    fetchSDGs({
      routeParams: {
        year: "2023",
        transactionTypeCode: "1",
      },
    });
    fetchBudgetBySector({
      routeParams: {
        years: "2019|2023",
      },
    });
    fetchOrgNetwork({
      routeParams: {
        period: "2023-01|2023-12",
      },
    });
    fetchSectors({
      routeParams: {
        year: "2023",
        transactionTypeCode: "3",
      },
    });
    fetchOrgDonut({
      routeParams: {
        transactionTypeCode: "1",
        period: "2023-01|2023-12",
      },
    });
    const date = new Date();
    date.setDate(date.getDate() - 2);
    fetchActivities({
      routeParams: { page: "1" },
      filterString: `lastUpdatedDate=${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`,
    });
  }, []);

  return (
    <React.Fragment>
      <Hero />
      <Box height={50} />
      <IatiDataInNumbers
        sdgsData={sdgsData}
        orgDonut={orgDonut}
        orgNetwork={orgNetwork}
        sectors={sectors.slice(0, 4)}
        budgetBySector={budgetBySector}
      />
      <Box height={50} />
      <LiveDataUpdates activities={activities} />
    </React.Fragment>
  );
};
