import { Box, Grid, SelectChangeEvent, Typography } from "@mui/material";
import React from "react";
import { SelectField } from "../select-field";

export const YearSelectWidget = () => {
  const [yearData, setYearData] = React.useState({
    startYear: "2022",
    endYear: "2023",
    startMonth: "January",
    endMonth: "December",
  });

  const years = [
    { value: "2018", label: "2018" },
    { value: "2019", label: "2019" },
    { value: "2020", label: "2020" },
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
  ];

  const months = [
    { value: "January", label: "January" },
    { value: "February", label: "February" },
    { value: "March", label: "March" },
    { value: "April", label: "April" },
    { value: "May", label: "May" },
    { value: "June", label: "June" },
    { value: "July", label: "July" },
    { value: "August", label: "August" },
    { value: "September", label: "September" },
    { value: "October", label: "October" },
    { value: "November", label: "November" },
    { value: "December", label: "December" },
  ];

  const handleChange = (event: SelectChangeEvent) => {
    setYearData({
      ...yearData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Grid display={"flex"} alignItems={"flex-end"} columnGap={"10px"}>
      <Typography variant="button" paddingBottom={"4.5px"}>
        from
      </Typography>
      <Grid display={"flex"} alignItems={"center"} columnGap={"10px"}>
        <Box width={130} flexShrink={"0"}>
          <SelectField
            id="startMonth"
            value={yearData.startMonth}
            options={months}
            onChange={handleChange}
            variant="outlined"
            name="startMonth"
            label="Month"
          />
        </Box>
        <Box width={100} flexShrink={"0"}>
          <SelectField
            id="startYear"
            value={yearData.startYear}
            options={years}
            onChange={handleChange}
            variant="outlined"
            name="startYear"
            label="Year"
          />
        </Box>
      </Grid>
      <Typography variant="button" paddingBottom={"4.5px"}>
        to
      </Typography>
      <Grid display={"flex"} alignItems={"center"} columnGap={"10px"}>
        <Box width={130} flexShrink={"0"}>
          <SelectField
            id="endMonth"
            value={yearData.endMonth}
            options={months}
            onChange={handleChange}
            variant="outlined"
            name="endMonth"
            label="Month"
          />
        </Box>
        <Box width={100} flexShrink={"0"}>
          <SelectField
            id="endYear"
            value={yearData.endYear}
            options={years}
            onChange={handleChange}
            variant="outlined"
            name="endYear"
            label="Year"
          />
        </Box>
      </Grid>
      <Typography variant="button" lineHeight={"20px"} paddingBottom={"7.5px"}>
        {yearData.startMonth.substring(0, 3)} {yearData.startYear} -{" "}
        {yearData.endMonth.substring(0, 3)} {yearData.endYear}{" "}
        <Typography variant="body2" fontSize={"12px"} component={"span"}>
          selected.
        </Typography>
      </Typography>
    </Grid>
  );
};
