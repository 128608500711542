import React from "react";

export const IDealLogo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="37"
      height="32"
      viewBox="0 0 37 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 2.37213V29.6279C0 30.9325 1.06746 32 2.37213 32H18.6568C30.9681 32 36.3054 25.109 36.3054 15.9644C36.3054 6.86731 30.9681 0 18.6568 0H2.37213C1.06746 0 0 1.06746 0 2.37213Z"
        fill="white"
      />
      <path
        d="M10.9004 6.69004V26.8294H19.6654C27.6239 26.8294 31.0753 22.3342 31.0753 15.9769C31.0753 9.89241 27.6239 5.17188 19.6654 5.17188H12.4186C11.5764 5.17187 10.9004 5.85979 10.9004 6.69004Z"
        fill="#CC0066"
      />
      <path
        d="M18.6576 29.8288H4.49599C3.23876 29.8288 2.21875 28.8088 2.21875 27.5515V4.45888C2.21875 3.20165 3.23876 2.18164 4.49599 2.18164H18.6576C32.0957 2.18164 34.1001 10.828 34.1001 15.9756C34.1001 24.9066 28.6087 29.8288 18.6576 29.8288ZM4.49599 2.94072C3.65389 2.94072 2.97783 3.61678 2.97783 4.45888V27.5515C2.97783 28.3936 3.65389 29.0697 4.49599 29.0697H18.6576C28.1224 29.0697 33.341 24.4203 33.341 15.9756C33.341 4.63679 24.1372 2.94072 18.6576 2.94072H4.49599Z"
        fill="black"
      />
      <path
        d="M13.9835 13.2599C14.2919 13.2599 14.5766 13.3074 14.8494 13.4022C15.1221 13.4971 15.3475 13.6513 15.5491 13.8411C15.7389 14.0427 15.8931 14.2918 16.0117 14.5764C16.1184 14.873 16.1777 15.2169 16.1777 15.6202C16.1777 15.976 16.1303 16.2962 16.0473 16.5927C15.9524 16.8893 15.8219 17.1502 15.644 17.3637C15.4661 17.5772 15.2408 17.7432 14.968 17.8737C14.6952 17.9923 14.3749 18.0635 14.0072 18.0635H11.9316V13.248H13.9835V13.2599ZM13.9124 17.1858C14.0666 17.1858 14.2089 17.1621 14.3631 17.1146C14.5054 17.0672 14.6359 16.9841 14.7426 16.8655C14.8494 16.7469 14.9442 16.6046 15.0154 16.4148C15.0866 16.2251 15.1221 16.0116 15.1221 15.7388C15.1221 15.5016 15.0984 15.2762 15.051 15.0864C15.0035 14.8967 14.9205 14.7188 14.8138 14.5883C14.707 14.4578 14.5647 14.3392 14.3868 14.2681C14.2089 14.1969 13.9954 14.1613 13.7345 14.1613H12.9754V17.1976H13.9124V17.1858Z"
        fill="white"
      />
      <path
        d="M20.459 13.2599V14.1495H17.9208V15.1813H20.2574V15.9997H17.9208V17.1739H20.5183V18.0635H16.8652V13.248H20.459V13.2599Z"
        fill="white"
      />
      <path
        d="M24.0896 13.2598L25.8924 18.0752H24.7894L24.4217 17.0077H22.6189L22.2393 18.0752H21.1719L22.9866 13.2598H24.0896ZM24.1489 16.2131L23.544 14.4458H23.5321L22.9035 16.2131H24.1489Z"
        fill="white"
      />
      <path
        d="M27.6103 13.2598V17.1856H29.9587V18.0752H26.5547V13.2598H27.6103Z"
        fill="white"
      />
      <path
        d="M6.93865 17.8851C8.16358 17.8851 9.15659 16.8921 9.15659 15.6672C9.15659 14.4422 8.16358 13.4492 6.93865 13.4492C5.71371 13.4492 4.7207 14.4422 4.7207 15.6672C4.7207 16.8921 5.71371 17.8851 6.93865 17.8851Z"
        fill="black"
      />
      <path
        d="M8.61047 26.828C6.74835 26.828 5.25391 25.3217 5.25391 23.4714V20.8502C5.25391 19.9251 6.00113 19.166 6.93812 19.166C7.86325 19.166 8.62233 19.9132 8.62233 20.8502V26.828H8.61047Z"
        fill="black"
      />
    </svg>
  );
};
