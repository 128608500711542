import { Box, SxProps, Typography } from "@mui/material";
import { GenericTableColumn } from "app/components/generic-table/data";
import { get } from "lodash";
import React from "react";
interface SimpleTableProps {
  columns: GenericTableColumn[];
  rows: any[];
  variant: "horizontal" | "vertical";
  gridTemplateColumns?: string;
  boxStyle?: SxProps;
  headerBoxStyle?: SxProps;
}
export const SimpleTable = (props: SimpleTableProps) => {
  const headerBoxStyle = {
    ...props.boxStyle,
    ...props.headerBoxStyle,
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns:
            props.gridTemplateColumns ??
            `repeat(${props.variant === "horizontal" ? props.columns.length : 1 + props.rows.length}, minmax(0, 1fr))`,
        }}
      >
        {props.columns.map((column, index) => (
          <React.Fragment key={index}>
            <Box
              sx={{
                padding: "10px 5px",
                border: "0.5px solid #D7D8D9",
                background: "white",
                ...headerBoxStyle,
              }}
            >
              <Typography
                variant="h6"
                fontSize={"12px"}
                color={"#373D43"}
                lineHeight={"normal"}
              >
                {column.headerName}
              </Typography>
            </Box>
            {props.variant === "vertical"
              ? props.rows.map((row, rowIndex) => (
                  <Box
                    key={rowIndex}
                    sx={{
                      padding: "10px 5px",
                      border: "0.5px solid #D7D8D9",
                      background: "white",

                      ...props.boxStyle,
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight={"400"}
                      fontSize={"12px"}
                      color={"#373D43"}
                      sx={{ wordBreak: "break-word" }}
                      lineHeight={"normal"}
                    >
                      {column.renderCell
                        ? column.renderCell({
                            row,
                            value: get(row, column.field, ""),
                          })
                        : get(row, column.field, "-")}
                    </Typography>
                  </Box>
                ))
              : null}
          </React.Fragment>
        ))}

        {props.variant === "horizontal"
          ? props.rows.map((row, rowIndex) =>
              props.columns.map((column, columnIndex) => (
                <Box
                  key={columnIndex}
                  sx={{
                    padding: "10px 5px",
                    border: "0.5px solid #D7D8D9",
                    background: "white",
                    ...props.boxStyle,
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight={"400"}
                    fontSize={"12px"}
                    color={"#373D43"}
                    lineHeight={"normal"}
                  >
                    {column.renderCell
                      ? column.renderCell({
                          row,
                          value: get(row, column.field, ""),
                        })
                      : get(row, column.field, "-")}
                  </Typography>
                </Box>
              ))
            )
          : null}
      </Box>
    </React.Fragment>
  );
};
