import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { CircleFlag } from "react-circle-flags";
import Typography from "@mui/material/Typography";
import { formatNumber } from "app/utils/formatNumber";
import { PageHeader } from "app/components/page-header";
import { GeomapChart } from "app/components/charts/geomap";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { ExploreListAllBlock } from "app/components/explore-list-all-block";
import { ExploreListBlockItem } from "app/components/explore-list-block-Item";
import { LOCATION_COLUMNS as cols } from "app/pages/explorer/pages/locations/tableColumns";
import { ReactComponent as ExplorerLocationsHeaderIcon } from "app/assets/vectors/ExplorerLocationsHeaderIcon.svg";

const box = <Box height={40} />;

export const ExplorerLocations: React.FC = () => {
  const stats = useStoreState((state) =>
    get(state.ExplorerLocationStats, "data.data", {
      disbursement: 0,
      sdgActivities: 0,
      orgs: 0,
      leastFundedSector: "",
    })
  );
  const statsLoading = useStoreState(
    (state) => state.ExplorerLocationStats.loading
  );
  const fetchStats = useStoreActions(
    (actions) => actions.ExplorerLocationStats.fetch
  );
  const list = useStoreState((state) =>
    get(state.ExplorerLocationsList, "data.data", [])
  );
  const listLoading = useStoreState(
    (state) => state.ExplorerLocationsList.loading
  );
  const fetchList = useStoreActions(
    (actions) => actions.ExplorerLocationsList.fetch
  );
  const map = useStoreState((state) =>
    get(state.ExplorerLocationMap, "data.data", {
      data: [],
      geoData: [],
      coveragePercentage: 0,
    })
  );
  const fetchMap = useStoreActions(
    (actions) => actions.ExplorerLocationMap.fetch
  );

  const LOCATIONS = React.useMemo(() => {
    return list.map((c: any) => ({
      ...c,
      location: c.name,
      incomingFunds: c.incomingFunds
        ? formatNumber(c.incomingFunds, true)
        : "-",
    }));
  }, [list]);

  const LOCATION_COLUMNS = React.useMemo(() => {
    const r = cols;
    r[5].headerName = "Location highlights reflect 2023 data.";
    return r;
  }, []);

  React.useEffect(() => {
    fetchStats({
      routeParams: {
        code: "AF",
        year: "2023",
        transactionTypeCode: "1",
      },
    });
    fetchList({
      filterString:
        "startDate=2023-01-01T00:00:00.000Z&endDate=2023-12-31T00:00:00.000Z",
    });
    fetchMap({
      routeParams: { code: "AF" },
      filterString:
        "startDate=2023-01-01T00:00:00.000Z&endDate=2023-12-31T00:00:00.000Z",
    });
  }, []);

  return (
    <Box>
      <PageHeader
        variant="explorer"
        title="AIDA Explorer: Discover Locations"
        subtitle="Embark on a journey to discover the vast landscape of humanitarian efforts around the world. With AIDA's location-specific data, you'll gain insights into how aid is distributed and utilized in various nations. "
        icon={<ExplorerLocationsHeaderIcon />}
      />
      {box}
      <Typography variant="h2" fontSize="36px" lineHeight="normal">
        Explore the Location of the Month
      </Typography>
      {box}
      <ExploreListBlockItem
        icon={<CircleFlag countryCode="af" height="22" width="22" />}
        title="LOCATION OF THE MONTH"
        description="Afghanistan"
        loading={statsLoading}
        leftItems={[
          {
            title: "Overview",
            description: (
              <React.Fragment>
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {formatNumber(stats.disbursement, true)}
                </span>{" "}
                was disbursed to Afghanistan in 2023.
              </React.Fragment>
            ),
          },
          {
            title: "Results",
            description: (
              <React.Fragment>
                In 2023, available results show activities surpassed about{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  4.8%
                </span>{" "}
                of their target results on average.
              </React.Fragment>
            ),
          },
          {
            title: "SUSTAINABLE DEVELOPMENT GOALS",
            description: (
              <React.Fragment>
                In 2023,{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {stats.sdgActivities} activities{" "}
                </span>
                were contributing to the SDGs.
              </React.Fragment>
            ),
          },
          {
            title: "NETWORKS",
            description: (
              <React.Fragment>
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {stats.orgs} organisations{" "}
                </span>
                have published data on their development and humanitarian
                resources and activities.
              </React.Fragment>
            ),
          },
          {
            title: "FUNDING Analysis",
            description: (
              <React.Fragment>
                Funding gaps analysis by sectors shows that{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {stats.leastFundedSector}
                </span>{" "}
                was the least funded sector of 2023.
              </React.Fragment>
            ),
          },
        ]}
        rightContent={{
          title: "GEOSPATIAL DATA",
          description: (
            <React.Fragment>
              <span
                style={{
                  color: colors.primary.blue,
                }}
              >
                {map.coveragePercentage.toFixed(2)}% of the activities
              </span>{" "}
              have provided exact locations.
            </React.Fragment>
          ),
          content: (
            <GeomapChart
              zoom={8}
              showScatter
              height="319px"
              data={map.geoData}
              scatterData={map.data}
              focusCountry="Afghanistan"
            />
          ),
        }}
        buttonText="See the Location Page"
        buttonLink="/explorer/locations/AF"
      />
      {box}
      <Typography variant="h2" fontSize="36px" lineHeight="normal">
        Explore All Locations & Search
      </Typography>
      {box}
      <ExploreListAllBlock
        rows={LOCATIONS}
        loading={listLoading}
        columns={LOCATION_COLUMNS}
      />
    </Box>
  );
};
