import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { AIDAArrowForward } from "app/assets/vectors/jsx/arrow";
import { GenericTableColumn } from "app/components/generic-table/data";

export const ORGANISATION_COLUMNS: GenericTableColumn[] = [
  {
    field: "name",
    headerName: "Publishing Organisation Name",
    align: "left",
    headerAlign: "left",
    headerStyle: {
      color: colors.text.title,
    },
  },
  {
    field: "iatiIdentifier",
    headerName: "IATI Organisation Identifier",
    width: 200,
    headerStyle: {
      color: colors.text.title,
    },
    headerInfo: "Publishing Organisation Name",
  },
  {
    field: "organisationType",
    headerName: "Organisation Type",
    width: 200,
    headerStyle: {
      color: colors.text.title,
    },
    headerInfo: "Publishing Organisation Name",
  },
  {
    field: "organisationHQ",
    headerName: "Organisation HQ",
    width: 150,
    headerStyle: {
      color: colors.text.title,
    },
    headerInfo: "Publishing Organisation Name",
  },
  {
    field: "totalDatasets",
    headerName: "Total Datasets",
    width: 120,
    headerStyle: {
      color: colors.text.title,
    },
    headerInfo: "Publishing Organisation Name",
  },
  {
    field: "totalActivities",
    headerName: "Total Activities",
    headerStyle: {
      color: colors.text.title,
    },
    headerInfo: "Publishing Organisation Name",
  },
  {
    field: "",
    headerName: "",
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
        <IconButton
          sx={{
            padding: 0,
          }}
          component={Link}
          to={`/explorer/organisations/${params.row.iatiIdentifier}`}
        >
          <AIDAArrowForward />
        </IconButton>
      </Box>
    ),
  },
];
