import React from "react";
import { AccountProfileCard } from "app/components/account-profile-card";
import { Box, Typography } from "@mui/material";
import { colors } from "app/theme";

export const UsernameSettings = (props: {
  setActiveSetting?: (value: string) => void;
}) => {
  const [username, setUsername] = React.useState("");
  return (
    <AccountProfileCard
      title="profile"
      disableSave={!username}
      content={
        <Box
          sx={{
            width: "100%",
            input: {
              width: "300px",
              height: "32px",
              fontSize: "12px",
              padding: "0 20px",
              borderRadius: "5px",
              color: colors.text.title,
              background: colors.secondary.lightGrey,
              border: `1px solid ${colors.secondary.iconGray}`,
              ":focus": {
                outline: "none",
              },
            },
          }}
        >
          <Typography
            variant="body2"
            lineHeight={"20px"}
            marginBottom={"10px"}
            component={"label"}
            htmlFor="username"
            display={"block"}
          >
            User Name
          </Typography>

          <input
            type="text"
            placeholder="Jane Doe"
            name="username"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Box>
      }
      setActiveSetting={props.setActiveSetting}
      onBack={() => props.setActiveSetting?.("")}
    />
  );
};
