import React from "react";
import get from "lodash/get";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import { InfoIcon } from "app/assets/vectors/jsx/InfoIcon";
import { GenericTableProps } from "app/components/generic-table/data";

export const GenericTable: React.FC<GenericTableProps> = (
  props: GenericTableProps
) => {
  const tableEl = React.useRef<any>();
  const [distanceBottom, setDistanceBottom] = React.useState(0);

  const scrollListener = React.useCallback(() => {
    if (!tableEl.current) return;
    let bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;
    // if you want to change distanceBottom every time new data is loaded
    // don't use the if statement
    if (!distanceBottom) {
      // calculate distanceBottom that works for you
      setDistanceBottom(Math.round(bottom * 0.2));
    }
    if (
      tableEl.current.scrollTop > bottom - distanceBottom &&
      props.hasMore &&
      !props.loading
    ) {
      props.loadMore?.();
    }
  }, [props.hasMore, props.loadMore, props.loading, distanceBottom]);

  React.useLayoutEffect(() => {
    const tableRef = tableEl.current;
    tableRef.addEventListener("scroll", scrollListener);
    return () => {
      tableRef.removeEventListener("scroll", scrollListener);
    };
  }, [scrollListener]);

  return (
    <TableContainer
      id={props.id}
      ref={tableEl}
      sx={{
        maxHeight: "500px",
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow sx={props.headerRowStyle ?? {}}>
            {props.columns.map((column) => (
              <TableCell
                align={column?.headerAlign ?? "left"}
                key={column.field}
                width={column.width}
              >
                <Typography
                  variant="body2"
                  fontSize={"12px"}
                  fontWeight={700}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    ...column.headerStyle,
                  }}
                >
                  {column.headerName}{" "}
                  {column.headerInfo && <InfoIcon width={14} height={14} />}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => (
            <TableRow key={index} sx={props.rowStyle}>
              {props.columns.map((column) => (
                <TableCell
                  align={column?.align ?? "left"}
                  key={column.field}
                  sx={{ lineHeight: "1.43" }}
                >
                  {column.renderCell ? (
                    column.renderCell({
                      row,
                      value: get(row, column.field),
                    })
                  ) : (
                    <Typography variant="body2" fontSize={"12px"}>
                      {get(row, column.field)}
                    </Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
