import { Box } from "@mui/material";
import GeneralChartPlaceholder from "app/assets/vectors/GeneralChartPlaceholder.svg";

export const ChartPlaceholder: React.FC<{
  width?: string;
  height?: string;
}> = (props: { width?: string; height?: string }) => {
  return (
    <Box
      sx={{
        width: props.width ?? "100%",
        height: props.height ?? "400px",
        backgroundImage: `url(${GeneralChartPlaceholder})`,
      }}
    />
  );
};
