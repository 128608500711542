import React from "react";
import { AccountProfileCard } from "app/components/account-profile-card";
import { Box, Typography } from "@mui/material";
import { GoogleDriveLogo } from "app/assets/vectors/jsx/GoogleDriveLogo";
import { MicrosoftCloudLogo } from "app/assets/vectors/jsx/MicrosoftCloudLogo";
import { ConnectionOptionCard } from "./components/option-card";

export const ConnectedAccountsSetting = (props: {
  setActiveSetting?: (value: string) => void;
}) => {
  const connectionOptions = [
    {
      name: "Google Drive",
      icon: GoogleDriveLogo,
      connected: true,
      fileAutomation: true,
      key: "google-drive",
    },
    {
      name: "Microsoft Cloud",
      icon: MicrosoftCloudLogo,
      connected: false,
      fileAutomation: false,
      key: "microsoft-cloud",
    },
  ];
  return (
    <AccountProfileCard
      title="CONNECTED ACCOUNTS"
      content={
        <React.Fragment>
          <Box>
            <Typography
              variant="h6"
              fontSize={"12px"}
              lineHeight={"normal"}
              fontWeight={"400"}
              marginBottom={"20px"}
            >
              Manage your connected accounts.
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
              }}
            >
              {connectionOptions.map((option) => (
                <ConnectionOptionCard
                  key={option.key}
                  option={option}
                  setActiveSetting={props.setActiveSetting}
                />
              ))}
            </Box>
          </Box>
        </React.Fragment>
      }
      setActiveSetting={props.setActiveSetting}
      onBack={() => props.setActiveSetting?.("")}
    />
  );
};
