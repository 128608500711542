import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { HomeIcon } from "app/assets/vectors/jsx/HomeIcon";
import { UpDownIcon } from "app/assets/vectors/jsx/UpDownIcon";
import LocationIcon from "app/assets/vectors/jsx/LocationIcon";
import { AIDAArrowForward } from "app/assets/vectors/jsx/arrow";
import { GenericTableColumn } from "app/components/generic-table/data";
import { ReactComponent as ListIcon } from "app/assets/vectors/GenericListIcon.svg";

export const SECTOR_COLUMNS: GenericTableColumn[] = [
  {
    field: "sector",
    headerName: "Sector",
    align: "left",
    headerAlign: "left",
    headerStyle: {
      color: colors.text.title,
    },
  },
  {
    field: "incomingFunds",
    headerName: "Incoming Funds",
    width: 120,
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
        <UpDownIcon width={19} height={19} />
        <Typography
          variant="body2"
          fontSize={"12px"}
          textTransform={"uppercase"}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "activities",
    headerName: "Activities",
    width: 120,
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
        <ListIcon width={19} height={19} />
        <Typography
          variant="body2"
          fontSize={"12px"}
          textTransform={"uppercase"}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "locations",
    headerName: "Locations",
    width: 120,
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
        <LocationIcon width={19} height={19} />
        <Typography
          variant="body2"
          fontSize={"12px"}
          textTransform={"uppercase"}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "organisations",
    headerName: "Organisations",
    width: 120,
    headerStyle: {
      color: colors.text.title,
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
        <HomeIcon width={19} height={19} />
        <Typography
          variant="body2"
          fontSize={"12px"}
          textTransform={"uppercase"}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "highlights",
    headerName: "Sector highlights reflect 2023 data.",
    headerAlign: "right",
    headerStyle: {
      color: colors.text.title,
      fontWeight: 400,
      justifyContent: "flex-end",
    },
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
        <IconButton
          sx={{
            padding: 0,
          }}
          component={Link}
          to={`/explorer/sectors/${params.row.id}`}
        >
          <AIDAArrowForward />
        </IconButton>
      </Box>
    ),
  },
];
