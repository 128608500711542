import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { PageHeader } from "app/components/page-header";
import { InsightsBlock } from "app/pages/data-hub/components/insights-block";
import { FreePlanBanner } from "app/pages/data-hub/components/free-plan-banner";
import { CONVERTED_FILES_ROWS, ConvertedFile } from "app/components/table/data";
import { UploadedDataBlock } from "app/pages/data-hub/components/uploaded-data-block";
import { ReactComponent as PublisherHeaderIcon } from "app/assets/vectors/PublisherHeader.svg";

export const DataHub: React.FC = () => {
  const [convertedFiles, setConvertedFiles] =
    React.useState<ConvertedFile[]>(CONVERTED_FILES_ROWS);
  const [showFreePlanBanner, setShowFreePlanBanner] = React.useState(false);
  const [expandedBlock, setExpandedBlock] = React.useState<
    "locations" | "results" | "sdgs" | "network" | "impact" | "budget" | null
  >(null);

  const handleConvertedFilePublishStatusChange = (
    fileId: string,
    value: boolean
  ) => {
    setConvertedFiles((prev) =>
      prev.map((file) =>
        file.id === fileId ? { ...file, isPublished: value } : file
      )
    );
  };

  const handleSetExpandedBlock = (block: typeof expandedBlock) => {
    setExpandedBlock((prev) => (prev === block ? null : block));
  };

  const uploadedDataBlock = React.useMemo(() => {
    return (
      <React.Fragment>
        <Typography variant="h2" fontSize="36px">
          Your Data Hub
        </Typography>
        <UploadedDataBlock
          files={convertedFiles}
          changePublishStatus={handleConvertedFilePublishStatusChange}
        />
      </React.Fragment>
    );
  }, [convertedFiles]);

  return (
    <Box
      sx={{
        gap: "30px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <PageHeader
        variant="publisher"
        title="AIDA Publisher: Publish, Validate, Visualise Data"
        subtitle="Ready to share your impact with the world? Publish your data to contribute to a global network of meaningful insights."
        icon={<PublisherHeaderIcon />}
      />
      {!expandedBlock && uploadedDataBlock}
      {showFreePlanBanner && (
        <FreePlanBanner setShowFreePlanBanner={setShowFreePlanBanner} />
      )}
      <InsightsBlock
        expandedBlock={expandedBlock}
        setExpandedBlock={handleSetExpandedBlock}
      />
      {expandedBlock && uploadedDataBlock}
    </Box>
  );
};
