export interface ImpactChartItemProps {
  id: string;
  title: string;
  output: string;
  indicator: string;
  baseline: number;
  target: number;
  actual: number;
  period: string;
  variant?: "target" | "impact";
}

export interface ImpactChartProps {
  items: ImpactChartItemProps[];
  title?: string;
  variant?: "target" | "impact";
}

export const IMPACT_CHART_ITEMS: ImpactChartItemProps[] = [
  {
    id: "1",
    title: "HSA Amref Health Africa in Kenya",
    output:
      "(Online) Meetings (informal & formal) with CSOs and other (non-profit) organisations/institutions.",
    indicator: "Number of meetings (co)organised",
    baseline: 0,
    target: 25,
    actual: 64,
    period: "2022-01-01 : 2022-12-31",
  },
  {
    id: "2",
    title: "HSA Amref Health Africa in Kenya",
    output:
      "(Online) Meetings (informal & formal) with CSOs and other (non-profit) organisations/institutions.",
    indicator: "Number of meetings (co)organised",
    baseline: 0,
    target: 25,
    actual: 64,
    period: "2022-01-01 : 2022-12-31",
  },
];

export const IMPACT_CHART_ITEMS_EXTRA: ImpactChartItemProps[] = [
  {
    id: "1",
    title: "HSA Amref Health Africa in Kenya",
    output:
      "(Online) Meetings (informal & formal) with CSOs and other (non-profit) organisations/institutions.",
    indicator: "Number of meetings (co)organised",
    baseline: 0,
    target: 25,
    actual: 64,
    period: "2022-01-01 : 2022-12-31",
  },
  {
    id: "2",
    title: "HSA Amref Health Africa in Kenya",
    output:
      "(Online) Meetings (informal & formal) with CSOs and other (non-profit) organisations/institutions.",
    indicator: "Number of meetings (co)organised",
    baseline: 0,
    target: 25,
    actual: 64,
    period: "2022-01-01 : 2022-12-31",
  },
  {
    id: "3",
    title: "HSA Amref Health Africa in Kenya",
    output:
      "(Online) Meetings (informal & formal) with CSOs and other (non-profit) organisations/institutions.",
    indicator: "Number of meetings (co)organised",
    baseline: 0,
    target: 25,
    actual: 64,
    period: "2022-01-01 : 2022-12-31",
  },
  {
    id: "4",
    title: "HSA Amref Health Africa in Kenya",
    output:
      "(Online) Meetings (informal & formal) with CSOs and other (non-profit) organisations/institutions.",
    indicator: "Number of meetings (co)organised",
    baseline: 0,
    target: 25,
    actual: 64,
    period: "2022-01-01 : 2022-12-31",
  },
  {
    id: "5",
    title: "HSA Amref Health Africa in Kenya",
    output:
      "(Online) Meetings (informal & formal) with CSOs and other (non-profit) organisations/institutions.",
    indicator: "Number of meetings (co)organised",
    baseline: 0,
    target: 25,
    actual: 64,
    period: "2022-01-01 : 2022-12-31",
  },
  {
    id: "6",
    title: "HSA Amref Health Africa in Kenya",
    output:
      "(Online) Meetings (informal & formal) with CSOs and other (non-profit) organisations/institutions.",
    indicator: "Number of meetings (co)organised",
    baseline: 0,
    target: 25,
    actual: 64,
    period: "2022-01-01 : 2022-12-31",
  },
  {
    id: "7",
    title: "HSA Amref Health Africa in Kenya",
    output:
      "(Online) Meetings (informal & formal) with CSOs and other (non-profit) organisations/institutions.",
    indicator: "Number of meetings (co)organised",
    baseline: 0,
    target: 25,
    actual: 64,
    period: "2022-01-01 : 2022-12-31",
  },
  {
    id: "8",
    title: "HSA Amref Health Africa in Kenya",
    output:
      "(Online) Meetings (informal & formal) with CSOs and other (non-profit) organisations/institutions.",
    indicator: "Number of meetings (co)organised",
    baseline: 0,
    target: 25,
    actual: 64,
    period: "2022-01-01 : 2022-12-31",
  },
];
