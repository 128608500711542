import React from "react";
import { SignInMethods } from "app/components/header/sub-components/expanded-view/views/signin";
import { colors } from "app/theme";
import { Box, Button, Divider, Typography } from "@mui/material";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";
import OtpInput from "react-otp-input";

const LabelCheckBox = (props: CheckboxProps & { label: React.ReactNode }) => {
  return (
    <FormControlLabel
      sx={{
        alignItems: "center",
      }}
      control={
        <Checkbox
          {...props}
          aria-label="Remember my credentials"
          sx={{
            "&.Mui-checked": {
              color: `${colors.primary.blue} !important`,
            },
          }}
        />
      }
      label={
        <Typography variant="body2" lineHeight="20px">
          {props.label}
        </Typography>
      }
    />
  );
};

export const LeftBlock: React.FC = () => {
  const [step, setStep] = React.useState<"signin-options" | "email" | "otp">(
    "signin-options"
  );
  const [rememberMe, setRememberMe] = React.useState(false);
  const [trustDevice, setTrustDevice] = React.useState(false);
  const [otp, setOtp] = React.useState("");

  const signInOptions = (
    <Box>
      <Box
        sx={{
          rowGap: "15px",
          display: "flex",
          flexDirection: "column",
          width: "280px",
          margin: "0 auto",
          marginTop: "25px",
          "> button": {
            width: "100%",
            height: "32px",
            display: "flex",
            fontSize: "12px",
            fontWeight: "700",
            borderRadius: "5px",
            alignItems: "center",
            textTransform: "none",
            justifyContent: "center",
            color: colors.primary.white,
            background: colors.primary.blue,
            ":hover": {
              background: colors.shades.blue[400],
            },
          },
        }}
      >
        {SignInMethods.map((method) => (
          <Button
            startIcon={method.icon}
            key={method.title}
            //   onClick={handleSignInButtonClick}
          >
            {method.title}
          </Button>
        ))}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "20px",
          }}
        >
          <Divider
            sx={{
              flex: "1",
            }}
          />
          <Typography variant="body1" fontSize="14px" lineHeight="20px">
            OR
          </Typography>
          <Divider
            sx={{
              flex: "1",
            }}
          />
        </Box>
        <Button onClick={() => setStep("email")}>Sign In with my Email</Button>
      </Box>
      <Box
        sx={{
          width: "max-content",
          margin: "20px auto 27px auto",
        }}
      >
        <LabelCheckBox
          checked={rememberMe}
          onChange={(e) => setRememberMe(e.target.checked)}
          label={"Remember my credentials."}
        />
      </Box>
    </Box>
  );

  const emailSignIn = (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: "20px",
        }}
      >
        <Box
          sx={{
            cursor: "pointer",
            height: "24px",
          }}
          onClick={() => setStep("signin-options")}
        >
          <ArrowBackIcon />
        </Box>

        <Typography variant="h3" fontSize={"24px"}>
          Sign in with your email
        </Typography>
      </Box>

      <Box
        sx={{
          gap: "15px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "280px",
          margin: "20px auto",
          input: {
            width: "100%",
            height: "32px",
            fontSize: "12px",
            padding: "0 20px",
            borderRadius: "5px",
            color: colors.text.title,
            background: colors.secondary.lightGrey,
            border: `1px solid ${colors.secondary.iconGray}`,
            "::placeholder": {
              color: colors.text.title,
              fontStyle: "italic",
            },
            ":focus": {
              outline: "none",
            },
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <label htmlFor="email">
            <Typography
              variant="body2"
              lineHeight={"20px"}
              marginBottom={"10px"}
            >
              Email
            </Typography>
          </label>
          <input type="text" placeholder="Janedoe@gmail.com" id="email" />
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <label htmlFor="password">
            <Typography
              variant="body2"
              lineHeight={"20px"}
              marginBottom={"10px"}
            >
              Password
            </Typography>
          </label>
          <input type="password" placeholder="****" id="password" />
        </Box>
        <Link to={"."}>
          <Typography variant="body2">Forgot Password</Typography>
        </Link>

        <Button
          sx={{
            width: "100%",
            position: "relative",
          }}
          variant="contained"
          onClick={() => setStep("otp")}
        >
          Sign in
          <ArrowForward
            sx={{
              width: "15px",
              height: "15px",
              position: "absolute",
              right: "10px",
              top: "calc(50% - 7.5px)",
            }}
          />
        </Button>
      </Box>
      <Box
        sx={{
          width: "max-content",
          margin: "20px auto 0 auto",
        }}
      >
        <LabelCheckBox
          checked={rememberMe}
          onChange={(e) => setRememberMe(e.target.checked)}
          label={"Remember my credentials."}
        />
      </Box>
    </Box>
  );

  const otpVerification = (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: "20px",
        }}
      >
        <Box
          sx={{
            cursor: "pointer",
            height: "24px",
          }}
          onClick={() => setStep("signin-options")}
        >
          <ArrowBackIcon />
        </Box>

        <Typography variant="h3" fontSize={"24px"} lineHeight={"normal"}>
          Enter Verification Code
        </Typography>
      </Box>

      <Box
        sx={{
          gap: "15px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "280px",
          margin: "20px auto 0 auto",
        }}
      >
        <Box
          sx={{
            padding: "22.5px 0",
            gap: "15px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" lineHeight={"20px"}>
            User your code generator app to generate a code and enter it below.
          </Typography>

          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<Box width={11} />}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              border: `2px solid ${colors.text.body}`,
              width: "30px",
              height: "40px",
              borderRadius: "5px",
            }}
          />

          <Box
            sx={{
              width: "max-content",
              margin: "12px auto",
            }}
          >
            <LabelCheckBox
              checked={trustDevice}
              onChange={(e) => setTrustDevice(e.target.checked)}
              label={"Trust this device"}
            />
          </Box>
        </Box>

        <Button
          sx={{
            width: "100%",
            position: "relative",
          }}
          variant="contained"
          onClick={() => setStep("otp")}
        >
          Verify
          <ArrowForward
            sx={{
              width: "15px",
              height: "15px",
              position: "absolute",
              right: "10px",
              top: "calc(50% - 7.5px)",
            }}
          />
        </Button>
      </Box>
    </Box>
  );
  return (
    <Box sx={{ padding: "30px 50px", background: "#ffffff" }}>
      {step === "signin-options" && signInOptions}
      {step === "email" && emailSignIn}
      {step === "otp" && otpVerification}
    </Box>
  );
};
