import { ArrowForward } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import { AssistanceLaptop } from "app/assets/vectors/jsx/AssistanceLaptop";
import React from "react";

export const FurtherAssistance = () => {
  return (
    <Box>
      <Divider sx={{ margin: "5px 0" }} />
      <Box
        sx={{
          padding: "36px 0",
          display: "flex",
          gap: "50px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Typography variant="h1" fontSize={"40px"} lineHeight={"normal"}>
            Need Further Assistance?
          </Typography>
          <Typography
            marginTop={"10px"}
            variant="h4"
            fontSize={"18px"}
            lineHeight={"24px"}
            fontWeight={"400"}
          >
            Hi there, stuck somewhere? Our team of experts is here to help you.
          </Typography>
          <Box height={30} />

          <Box
            sx={{
              display: "flex",
              gap: "30px",
              "> button": {
                fontSize: "18px",
                padding: "10px 15px",
                lineHeight: "normal",
              },
            }}
          >
            <Button variant="contained" endIcon={<ArrowForward />}>
              Talk to an IATI expert
            </Button>{" "}
            <Button variant="contained" endIcon={<ArrowForward />}>
              Contact Us
            </Button>
          </Box>
        </Box>
        <AssistanceLaptop />
      </Box>
      <Divider sx={{ margin: "5px 0" }} />
    </Box>
  );
};
