import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

export const ExplorerLocations: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/locations/{transactionTypeCode}/{period}`
  ),
};

export const ExplorerLocationStats: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/location/stats/{code}/{transactionTypeCode}/{year}`
  ),
};

export const ExplorerLocationsList: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/explorer/location/list`),
};

export const ExplorerLocationMap: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/location/map-locations/{code}`
  ),
};

export const ExplorerProjectionsLocations: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/locations/{transactionTypeCode}/{period}`
  ),
};

// Location page

export const ExplorerLocationPageOverviewStats: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/location/detail/{code}/{transactionTypeCode}/{period}`
  ),
};

export const ExplorerLocationPageMap: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/location/map-locations/{code}`
  ),
};

export const ExplorerLocationPageSDGs: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/sdgs/{transactionTypeCode}/{year}`
  ),
};

export const ExplorerLocationPageOrganisationsNetwork: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/organisations-network/{period}`
  ),
};

export const ExplorerLocationPageOrganisationsDonut: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/organisations-donut/{transactionTypeCode}/{period}`
  ),
};

export const ExplorerLocationPageBudgetBySector: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/budget-by-sector/{years}`
  ),
};

export const ExplorerLocationPageSectors: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/sectors/{transactionTypeCode}/{year}`
  ),
};

export const ExplorerLocationPageActivityTable: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/explorer/activity/list/{page}`),
};
