import React from "react";
import Box from "@mui/material/Box";
import { SearchInput } from "app/components/search-input";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ExplorerSearchProps } from "app/pages/explorer/components/search/data";
import { ExplorerSearchResultsPopup } from "app/pages/explorer/components/search/searchResultsPopup";
import { useNavigate } from "react-router-dom";

export const ExplorerSearch: React.FC<ExplorerSearchProps> = (
  props: ExplorerSearchProps
) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement, Element>
  ) => {
    if (e.target.value.length > 2) {
      setAnchorEl(e.currentTarget);
    } else {
      handleClose();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleOpen(e);
    props.setSearchValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      navigate(`/explorer/activities?search=${props.searchValue}`);
    }
  };

  const id = "explorer-search-input";

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        <SearchInput
          showBorder
          type="text"
          placeholder="Search"
          onFocus={handleOpen}
          aria-describedby={id}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={props.searchValue}
          width="200px"
        />
        <ExplorerSearchResultsPopup
          id={id}
          anchorEl={anchorEl}
          results={props.results}
          loading={props.loading}
          handleClose={handleClose}
          loadMore={props.loadMore}
          searchValue={props.searchValue}
        />
      </Box>
    </ClickAwayListener>
  );
};
