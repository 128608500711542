import { Box, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as Astronaut } from "app/assets/vectors/Astronaut.svg";
import { SearchInput } from "app/components/search-input";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { colors } from "app/theme";
import { ChevronDown } from "app/assets/vectors/jsx/arrow";
import { FAQs } from "./data";
import { About } from "./components/about";
import { FAQS } from "./components/faqs";
import { Tutorials } from "./components/tutorials";
import { FurtherAssistance } from "./components/further-assistance";
import { ContactUs } from "./components/contact-us";

export const UserGuide = () => {
  return (
    <Box>
      <Box height={50} />

      <Box
        sx={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <Astronaut width="223" height="227" />
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Box
            sx={{
              padding: "0 10px",
            }}
          >
            <Typography
              variant="h5"
              fontSize={"18px"}
              lineHeight={"24px"}
              fontWeight={"400"}
            >
              Welcome to User Guide of AIDA.
            </Typography>
            <Typography
              variant="h1"
              fontSize={"40px"}
              marginTop={"10px"}
              lineHeight={"normal"}
            >
              What can we help you find?
            </Typography>
          </Box>
          <Box height={30} />
          <SearchInput
            showBorder
            type="text"
            placeholder="Search"
            padding="8px 8px"
          />
        </Box>
      </Box>
      <Box height={100} />
      <About />
      <Box height={20} />
      <FAQS />
      <Box height={20} />
      <Tutorials />
      <Box height={100} />
      <FurtherAssistance />
      <Box height={50} />
      <ContactUs />
    </Box>
  );
};
