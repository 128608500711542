import React from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";

export const HeaderResourcesView: React.FC = () => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        "> div": {
          paddingTop: "0px !important",
        },
      }}
    >
      <Grid
        item
        xs={12}
        sm={4}
        sx={{
          borderRight: "1px solid #A1AEBD",
          a: {
            display: "block",
            textDecoration: "none",
            color: "#000",
            marginBottom: "16px",
            "&:hover": {
              textDecoration: "underline",
            },
          },
        }}
      >
        <Typography variant="h5" fontSize="14px" marginBottom="24px">
          AIDA User Guide
        </Typography>
        <Link to="/about">
          <Typography
            variant="h5"
            fontSize="14px"
            fontWeight="400"
            marginBottom="10px"
          >
            About AIDA
          </Typography>
        </Link>
        <Link to="/faq">
          <Typography variant="h5" fontSize="14px" fontWeight="400">
            Frequently Asked Questions
          </Typography>
        </Link>
        <Link to="/tutorials">
          <Typography variant="h5" fontSize="14px" fontWeight="400">
            Tutorials
          </Typography>
        </Link>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          a: {
            display: "block",
            textDecoration: "none",
            color: "#000",
            marginBottom: "16px",
            "&:hover": {
              textDecoration: "underline",
            },
          },
        }}
      >
        <Typography variant="h5" fontSize="14px" marginBottom="24px">
          Data Services
        </Typography>
        <Link to="/data-services?v=publisher">
          <Typography
            variant="h5"
            fontSize="14px"
            fontWeight="400"
            marginBottom="10px"
          >
            AIDA Publisher
          </Typography>
        </Link>
        <Link to="/data-services?v=explorer">
          <Typography variant="h5" fontSize="14px" fontWeight="400">
            AIDA Explorer
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};
