import React from "react";

export const GoogleDriveLogo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="34"
      viewBox="0 0 37 34"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_2809_194759)">
        <path
          d="M2.789 28.7489L4.41595 31.559C4.75402 32.1507 5.23994 32.6156 5.81045 32.9536C7.4446 30.8794 8.58187 29.2876 9.22285 28.1784C9.8732 27.0528 10.6725 25.292 11.6209 22.8963C9.06506 22.5598 7.12829 22.3916 5.81059 22.3916C4.54579 22.3916 2.60902 22.5598 0 22.8963C0 23.5512 0.169035 24.2062 0.507105 24.7979L2.789 28.7489Z"
          fill="#0066DA"
        />
        <path
          d="M31.0813 32.9536C31.6519 32.6156 32.1378 32.1507 32.4758 31.5591L33.1519 30.3971L36.3848 24.7979C36.7166 24.219 36.8913 23.5635 36.8917 22.8963C34.2676 22.5598 32.3344 22.3916 31.0919 22.3916C29.7567 22.3916 27.8233 22.5598 25.292 22.8963C26.2291 25.3051 27.0179 27.0659 27.6585 28.1784C28.3045 29.3009 29.4454 30.8925 31.0813 32.9536Z"
          fill="#EA4335"
        />
        <path
          d="M18.4452 11.0645C20.3359 8.78113 21.6389 7.02031 22.3541 5.78231C22.9301 4.78539 23.564 3.19361 24.2557 1.0071C23.6852 0.669035 23.0302 0.5 22.3541 0.5H14.5364C13.8602 0.5 13.2054 0.690218 12.6348 1.0071C13.5147 3.51482 14.2614 5.29955 14.8747 6.36117C15.5526 7.53448 16.7428 9.10219 18.4452 11.0645Z"
          fill="#00832D"
        />
        <path
          d="M25.2711 22.8965H11.6218L5.81152 32.9538C6.38189 33.2919 7.03685 33.4609 7.71299 33.4609H29.18C29.8561 33.4609 30.5112 33.2707 31.0816 32.9537L25.2713 22.8965H25.2711Z"
          fill="#2684FC"
        />
        <path
          d="M18.4456 11.065L12.6353 1.00781C12.0646 1.34588 11.5787 1.81062 11.2406 2.40231L0.507105 20.9957C0.17525 21.5746 0.000438435 22.2301 0 22.8973H11.6209L18.4457 11.065H18.4456Z"
          fill="#00AC47"
        />
        <path
          d="M31.017 11.6991L25.6501 2.40231C25.3122 1.81062 24.8261 1.34588 24.2556 1.00781L18.4453 11.0652L25.2698 22.8975H36.8697C36.8697 22.2424 36.7007 21.5876 36.3626 20.9959L31.017 11.6991Z"
          fill="#FFBA00"
        />
      </g>
      <defs>
        <clipPath id="clip0_2809_194759">
          <rect
            width="36.8908"
            height="33"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
