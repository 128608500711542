import { ArrowForwardIos } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { colors } from "app/theme";
import React from "react";
import { Link } from "react-router-dom";
import { ProductOfferCardProps } from "./data";

export const ProductOfferCard = (props: ProductOfferCardProps) => {
  const color =
    props.variant === "publisher" ? colors.primary.green : colors.primary.blue;
  return (
    <Box
      sx={{
        padding: "20px",
        display: "flex",
        alignItems: "center",
        gap: "30px",
        background: colors.primary.white,
      }}
    >
      <Box>{props.image}</Box>
      <Box
        sx={{
          "> a": {
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            columnGap: "6px",
            justifyContent: "flex-end",
          },
        }}
      >
        <Typography variant="h3" fontSize={"24px"} lineHeight={"normal"}>
          {props.title}
        </Typography>

        <Box
          sx={{
            marginTop: "15px",
            marginBottom: "15px",
            gap: "5px",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {props.tags.map((tag) => (
            <Typography
              variant="h6"
              sx={{
                background: color,
                padding: "6px 12px",
                borderRadius: "29px",
                fontSize: "12px",
                lineHeight: "normal",
                color: colors.primary.white,
              }}
            >
              {tag}
            </Typography>
          ))}
        </Box>
        <Link to={props.linkUrl || ""}>
          <Typography
            variant="h6"
            lineHeight={"normal"}
            fontSize={"12px"}
            color={colors.secondary.red}
          >
            {props.linkText}
          </Typography>

          <ArrowForwardIos
            sx={{
              width: "10px",
              height: "10px",
              color: colors.secondary.red,
            }}
          />
        </Link>
      </Box>
    </Box>
  );
};
