import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { LinearProgress } from "app/components/linear-progress";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { LandingPageBlockItemProps } from "app/components/landing-page-block-item/data";

export const LandingPageBlockItem: React.FC<LandingPageBlockItemProps> = (
  props: LandingPageBlockItemProps
) => {
  return (
    <Box
      id={`block-${props.title}`}
      sx={{
        padding: "20px 30px",
        background: colors.primary.white,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        "> a": {
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
          columnGap: "6px",
        },
      }}
    >
      <Box height="100%">
        {props.loading ? (
          <Box
            width="100%"
            display="flex"
            height="200px"
            alignItems="center"
            justifyContent="center"
          >
            <Box width="40%">
              <LinearProgress />
            </Box>
          </Box>
        ) : (
          props.content
        )}
      </Box>
      <Box
        sx={{
          "> a": {
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            columnGap: "6px",
          },
        }}
      >
        <Divider />
        <Box height={20} />
        <Typography variant="h6" lineHeight={"normal"}>
          {props.description}
        </Typography>

        {props.title ? (
          <React.Fragment>
            <Box height={10} />
            <Typography variant="body2" fontSize={"12px"} lineHeight={"normal"}>
              {props.title}
            </Typography>
          </React.Fragment>
        ) : null}
        <Box height={10} />
        <Link to={props.linkUrl || ""}>
          <Typography
            variant="button"
            lineHeight={"20px"}
            color={colors.secondary.red}
          >
            {props.linkText}
          </Typography>

          <ArrowForwardIos
            sx={{
              width: "10px",
              height: "10px",
              color: colors.secondary.red,
            }}
          />
        </Link>
      </Box>
    </Box>
  );
};
