import { ArrowBack, ChevronRight } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import { colors } from "app/theme";
import isArray from "lodash/isArray";
import React from "react";

interface AccountProfileCardProps {
  title: string;
  content:
    | {
        key: string;
        content: React.ReactNode[];
      }[]
    | React.ReactNode;
  disableSave?: boolean;
  onSave?: () => void;
  setActiveSetting?: (value: string) => void;
  onBack?: () => void;
  hideSaveButton?: boolean;
  extraButtons?: React.ReactNode | React.ReactNode[];
}

export const AccountProfileCard = (props: AccountProfileCardProps) => {
  return (
    <Box
      sx={{
        background: colors.primary.white,
        padding: "20px 30px",
      }}
    >
      <Typography
        variant="h6"
        fontSize={"14px"}
        fontWeight={"400"}
        textTransform={"uppercase"}
        lineHeight={"normal"}
      >
        {props.title}
      </Typography>

      <Box>
        {isArray(props.content) ? (
          props.content.map((item, index) => (
            <Box key={index}>
              <Divider sx={{ margin: "20px 0" }} />
              <Box
                onClick={() => props.setActiveSetting?.(item.key)}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "20px",
                  alignItems: "center",
                  gap: "20px",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {item.content.map((child, index) => (
                  <Typography
                    key={index}
                    variant="h5"
                    fontWeight={"400"}
                    fontSize={"14px"}
                    lineHeight={"20px"}
                    sx={{
                      width:
                        index === 0 && item.content.length > 1
                          ? "197px"
                          : "auto",
                      flex:
                        index === 0 && item.content.length > 1 ? "none" : "1",
                    }}
                  >
                    {child}
                  </Typography>
                ))}
                <ChevronRight />
              </Box>
            </Box>
          ))
        ) : (
          <Box>
            <Divider sx={{ margin: "20px 0" }} />
            {props.content}
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              <Button
                sx={{
                  fontSize: "12px",
                  lineHeight: "normal",
                }}
                startIcon={
                  <ArrowBack
                    sx={{
                      width: "16px",
                      height: "16px",
                      lineHeight: "normal",
                    }}
                  />
                }
                variant="contained"
                onClick={() => props.onBack?.()}
              >
                Back
              </Button>

              {props.hideSaveButton ? null : (
                <Button
                  disabled={props.disableSave}
                  sx={{
                    fontSize: "12px",
                    lineHeight: "normal",
                    minWidth: "unset",
                    ":disabled": {
                      color: colors.primary.white,
                      background: colors.secondary.lightGrayText,
                    },
                  }}
                  variant="contained"
                  onClick={props.onSave}
                >
                  Save
                </Button>
              )}
              {props.extraButtons}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
