import React from "react";
import ExplorerHeroImage from "app/assets/images/data-services-explorer-hero-image.png";
import { Banner } from "app/components/banner";
import { DataServiceCard } from "app/components/data-service-card";
import { Box } from "@mui/material";
import { ReactComponent as DataServiceExplorerDataVisualisation } from "app/assets/vectors/DataServiceExplorerDataVisualisation.svg";
import { ReactComponent as DataServiceExplorerPieChart } from "app/assets/vectors/DataServiceExplorerPieChart.svg";
import { ReactComponent as DataServiceExplorerProjection } from "app/assets/vectors/DataServiceExplorerProjection.svg";
import { ReactComponent as DataServiceExplorerFilter } from "app/assets/vectors/DataServiceExplorerFilter.svg";

export const ExplorerBanner = () => {
  return (
    <React.Fragment>
      <Banner
        image={ExplorerHeroImage}
        title="AIDA Explorer: Uncover Trends, Drive Impact, and Empower Your Insights"
        subtitle="Embark on a journey of insights with AIDA Explorer. Navigate through a wealth of data, uncovering trends and patterns that drive impactful decisions. From detailed activity searches to visualizing funding landscapes, AIDA Explorer transforms raw data into actionable intelligence. Empower your exploration, make informed decisions, and shape a better future with AIDA Explorer."
        linkText="See AIDA Explorer"
        variant="explorer"
        linkUrl="/explorer"
      />
      <Box height={60} />
      <Box
        sx={{
          gap: "30px",
          display: "grid",
          gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        }}
      >
        <DataServiceCard
          image={<DataServiceExplorerPieChart />}
          title="Rich Dataset Insights"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
          linkText="See AIDA Explorer"
          linkUrl="/explorer"
          variant="explorer"
        />
        <DataServiceCard
          image={<DataServiceExplorerDataVisualisation />}
          title="Curated Visualisations"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
          linkText="See AIDA Explorer"
          linkUrl="/explorer"
          variant="explorer"
        />
        <DataServiceCard
          image={<DataServiceExplorerProjection />}
          title="Future Projections"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
          linkText="See AIDA Explorer"
          linkUrl="/explorer"
          variant="explorer"
        />
        <DataServiceCard
          image={<DataServiceExplorerFilter />}
          title="Dynamic Filters"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
          linkText="See AIDA Explorer"
          linkUrl="/explorer"
          variant="explorer"
        />
      </Box>
    </React.Fragment>
  );
};
