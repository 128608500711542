import { Box, Typography } from "@mui/material";
import { colors } from "app/theme";
import React from "react";

export const ContactUs = () => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "30px",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <Typography fontSize={"36px"} variant="h2" lineHeight={"normal"}>
          Contact Us
        </Typography>

        <Typography
          variant="h4"
          fontSize={"18px"}
          fontWeight={"400"}
          lineHeight={"24px"}
        >
          If you have any questions about AIDA, The OpenData Company or any
          other requests, please contact us using this form.
        </Typography>
        <Typography
          variant="h4"
          fontSize={"18px"}
          fontWeight={"400"}
          lineHeight={"24px"}
        >
          Tel: +31 20 503 9091
          <br />
          info@aida.tools
        </Typography>
        <Typography
          variant="h4"
          fontSize={"18px"}
          fontWeight={"400"}
          lineHeight={"24px"}
        >
          The Open Data Company B.V.
          <br />
          Herengracht 449A,
          <br />
          1017 BR Amsterdam
          <br />
          The Netherlands
        </Typography>
      </Box>
      <Box
        sx={{
          flex: 1,
          "> textarea": {
            resize: "none",
            height: "109px",
          },
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          "> textarea, input": {
            padding: "12px 20px",
            borderRadius: "5px",
            border: `1px solid ${colors.secondary.iconGray}`,
            backgroundColor: "transparent",
            fontSize: "14px",
            fontFamily: "Inter, sans-serif",
            "&:focus": {
              outline: "none",
              border: `1px solid ${colors.primary.blue}`,
            },
          },
        }}
        component={"form"}
      >
        <input type="text" placeholder="Email*" />
        <input type="text" placeholder="First Name*" />
        <input type="text" placeholder="Last Name*" />
        <input type="text" placeholder="Company Name" />
        <textarea placeholder="Your Message*" />
      </Box>
    </Box>
  );
};
