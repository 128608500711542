import { Box, Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { FavouriteIcon } from "app/assets/vectors/jsx/FavouriteIcon";
import { GenericTableColumn } from "app/components/generic-table/data";
import { colors } from "app/theme";
import exp from "constants";

interface Dataset {
  id?: string;
  dataset: string;
  date: string;
  noOfActivities: number;
  showMetadata?: boolean;
  showValidationReport?: boolean;
  metadata?: {
    license: string;
    licenseLink?: string;
    fileType: string;
    language: string;
    receipientCountry: string;
    publisherType: string;
    publisherOrganisationType: string;
    publisherIdentifier: string;
    publisherCountry: string;
    iatiDataUpdated: string;
    iatiRegistryUpdated: string;
  };
  content?: {
    iatiStandardVersion: string;
    noOfActivities: number;
  };
  validationReport?: {
    license: string;
    identifiedInRegistry: string;
    validatedDate: string;
    validationStatus: string;
    availableInIatiDatastore: string;
    iatiValidatorLink: string;
    iatiValidator: string;
  };
}

export const DATASETS: Dataset[] = [
  {
    id: "1",
    dataset: "Algeria",
    date: "2024-07-22 10:47:21",
    noOfActivities: 45,
    metadata: {
      license: "Creative Commons Attribution",
      licenseLink: "https://creativecommons.org/licenses/by/4.0/",
      fileType: "Activity",
      language: "-",
      receipientCountry: "Algeria",
      publisherType: "Primary Source",
      publisherOrganisationType: "Multilateral",
      publisherIdentifier: "GB-CHC-202918",
      publisherCountry: "(No country assigned)",
      iatiDataUpdated: "2024-07-22 10:47:21",
      iatiRegistryUpdated: "2024-08-12 23:04",
    },
    content: {
      iatiStandardVersion: "2.03",
      noOfActivities: 45,
    },
    validationReport: {
      license: "iatialgeriadata.xml",
      identifiedInRegistry: "Activity",
      validatedDate: "2024-08-13 01:46 (GMT+2)",
      validationStatus: "Working",
      availableInIatiDatastore: "Yes - 2024-08-13 01:50 (GMT+2)",
      iatiValidatorLink:
        "https://validator.iatistandard.org/report.html?filename=GB-CHC-202918.xml",
      iatiValidator: "IATI Validation Report",
    },
  },
  {
    id: "2",
    dataset: "Angola",
    date: "2024-07-22 10:43:44",
    noOfActivities: 40,
  },
  {
    id: "3",
    dataset: "Benin",
    date: "2024-07-22 10:43:44",
    noOfActivities: 40,
  },
  {
    id: "4",
    dataset: "Botswana",
    date: "2024-07-22 10:43:44",
    noOfActivities: 40,
  },
  {
    id: "5",
    dataset: "Burkina Faso",
    date: "2024-07-22 10:43:44",
    noOfActivities: 40,
  },
  {
    id: "6",
    dataset: "Burundi",
    date: "2024-07-22 10:43:44",
    noOfActivities: 40,
  },
  {
    id: "7",
    dataset: "Cabo Verde",
    date: "2024-07-22 10:43:44",
    noOfActivities: 40,
  },
  {
    id: "8",
    dataset: "Cameroon",
    date: "2024-07-22 10:43:44",
    noOfActivities: 40,
  },
  {
    id: "9",
    dataset: "Central African Republic",
    date: "2024-07-22 10:43:44",
    noOfActivities: 40,
  },
  {
    id: "10",
    dataset: "Chad",
    date: "2024-07-22 10:43:44",
    noOfActivities: 40,
  },
  {
    id: "11",
    dataset: "Comoros",
    date: "2024-07-22 10:43:44",
    noOfActivities: 40,
  },
  {
    id: "12",
    dataset: "Congo",
    date: "2024-07-22 10:43:44",
    noOfActivities: 40,
  },
  {
    id: "13",
    dataset: "Chad",
    date: "2024-07-22 10:43:44",
    noOfActivities: 40,
  },
  {
    id: "14",
    dataset: "Comoros",
    date: "2024-07-22 10:43:44",
    noOfActivities: 40,
  },
  {
    id: "15",
    dataset: "Congo",
    date: "2024-07-22 10:43:44",
    noOfActivities: 40,
  },
];

const capitalizeCell = (props: any) => (
  <Typography
    variant="h6"
    fontSize="12px"
    fontWeight={400}
    sx={{
      textTransform: "capitalize",
    }}
  >
    {props.value}
  </Typography>
);

export const METADATA_COLUMNS: GenericTableColumn[] = [
  {
    field: "license",
    headerName: "License",
    renderCell: (props: any) => {
      const content = props.row?.license ?? "-";
      return (
        <Typography variant="h6" fontSize={"12px"} fontWeight={400}>
          {props.row?.licenseUrl ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={props.row?.licenseUrl}
              style={{ color: colors.primary.blue }}
            >
              {content}
            </a>
          ) : (
            content
          )}
        </Typography>
      );
    },
  },
  {
    field: "fileType",
    headerName: "File Type",
    renderCell: capitalizeCell,
  },
  {
    field: "language",
    headerName: "Language",
  },
  {
    field: "country",
    headerName: "Recipient Country",
    renderCell: capitalizeCell,
  },
  {
    field: "publisherType",
    headerName: "Publisher Type",
    renderCell: capitalizeCell,
  },
  {
    field: "publisherOrgType",
    headerName: "Publisher Organisation Type",
    renderCell: capitalizeCell,
  },
  {
    field: "publisherIatiId",
    headerName: "Publisher Identifier",
  },
  {
    field: "publisherCountry",
    headerName: "Publisher Country",
    renderCell: capitalizeCell,
  },
  {
    field: "updated",
    headerName: "IATI Data Updated",
  },
  {
    field: "metadataUpdated",
    headerName: "IATI Registry Updated",
  },
];

export const CONTENT_COLUMNS: GenericTableColumn[] = [
  {
    field: "iatiVersion",
    headerName: "IATI Standard Version",
  },
  {
    field: "activityCount",
    headerName: "# of Activities",
  },
];

export const VALIDATION_REPORT_COLUMNS: GenericTableColumn[] = [
  {
    field: "license",
    headerName: "License",
  },
  {
    field: "identifiedInRegistry",
    headerName: "Identified in Registry",
  },
  {
    field: "validatedDate",
    headerName: "Validated",
  },
  {
    field: "validationStatus",
    headerName: "Validation Status",
  },
  {
    field: "availableInIatiDatastore",
    headerName: "Available in IATI Datastore",
  },
  {
    field: "iatiValidatorLink",
    headerName: "IATI Validator Link",
    renderCell: (row: any) => (
      <Typography variant="h6" fontSize={"12px"} fontWeight={400}>
        <a
          href={row?.iatiValidatorLink}
          target="_blank"
          rel="noreferrer"
          style={{ color: colors.primary.blue }}
        >
          {row?.iatiValidator}
        </a>
      </Typography>
    ),
  },
];

export const getDatasetColumns = (
  expandRow: (rowId: string, field: string) => void,
  expandedRowIds: string[]
) => {
  const DATASETS_COLUMNS: GridColDef[] = [
    {
      field: "favourite",
      headerName: "Favourite",
      width: 76,
      align: "center",
      renderCell: (params: any) => (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FavouriteIcon />
        </Box>
      ),
    },
    {
      field: "title",
      headerName: "Dataset",
      width: 250,
      flex: 1,
    },
    {
      field: "updated",
      headerName: "IATI Data Updated",
      width: 200,
    },
    {
      field: "activityCount",
      headerName: "No. of Activities",
      width: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params: any) => (
        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          columnGap={"20px"}
          height={"100%"}
          alignItems={"center"}
        >
          <Typography
            variant="subtitle1"
            fontSize={"12px"}
            fontWeight={400}
            color={
              expandedRowIds.includes(`${params.row.id}.showMetadata`)
                ? colors.secondary.grayText
                : colors.primary.blue
            }
            component={"a"}
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              expandRow(params.row.id, "showMetadata");
            }}
          >
            View Metadata
          </Typography>
          <Typography
            variant="subtitle1"
            fontSize={"12px"}
            fontWeight={400}
            color={
              expandedRowIds.includes(`${params.row.id}.showValidationReport`)
                ? colors.secondary.grayText
                : colors.primary.blue
            }
            component={"a"}
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => expandRow(params.row.id, "showValidationReport")}
          >
            Validation Report
          </Typography>
          <Typography
            variant="subtitle1"
            fontSize={"12px"}
            fontWeight={400}
            color={colors.primary.blue}
            component={"a"}
            sx={{
              textDecoration: "underline",
            }}
          >
            Download (1.6 MB)
          </Typography>
        </Box>
      ),
    },
  ];
  return DATASETS_COLUMNS;
};
