import { Avatar, Box, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { SelectField } from "app/components/select-field";

export const InvoiceColumns: GridColDef[] = [
  {
    field: "date",
    headerName: "Date",
    width: 150,
  },
  {
    field: "fileName",
    headerName: "File Name",
    flex: 1,
  },
];

export const Invoices = [
  {
    id: 1,
    date: "20/03/2021",
    fileName: "Invoice 0112",
  },

  {
    id: 2,
    date: "20/03/2021",
    fileName: "Invoice 0113",
  },

  {
    id: 3,
    date: "20/03/2021",
    fileName: "Invoice 0114",
  },

  {
    id: 4,
    date: "20/03/2021",
    fileName: "Invoice 0115",
  },

  {
    id: 5,
    date: "20/03/2021",
    fileName: "Invoice 0116",
  },

  {
    id: 6,
    date: "20/03/2021",
    fileName: "Invoice 0117",
  },

  {
    id: 7,
    date: "20/03/2021",
    fileName: "Invoice 0118",
  },

  {
    id: 8,
    date: "20/03/2021",
    fileName: "Invoice 0119",
  },

  {
    id: 9,
    date: "20/03/2021",
    fileName: "Invoice 0120",
  },

  {
    id: 10,
    date: "20/03/2021",
    fileName: "Invoice 0121",
  },

  {
    id: 11,
    date: "20/03/2021",
    fileName: "Invoice 0122",
  },

  {
    id: 12,
    date: "20/03/2021",
    fileName: "Invoice 0123",
  },

  {
    id: 13,
    date: "20/03/2021",
    fileName: "Invoice 0124",
  },

  {
    id: 14,
    date: "20/03/2021",
    fileName: "Invoice 0125",
  },

  {
    id: 15,
    date: "20/03/2021",
    fileName: "Invoice 0126",
  },

  {
    id: 16,
    date: "20/03/2021",
    fileName: "Invoice 0127",
  },

  {
    id: 17,
    date: "20/03/2021",
    fileName: "Invoice 0128",
  },

  {
    id: 18,
    date: "20/03/2021",
    fileName: "Invoice 0129",
  },

  {
    id: 19,
    date: "20/03/2021",
    fileName: "Invoice 0130",
  },

  {
    id: 20,
    date: "20/03/2021",
    fileName: "Invoice 0131",
  },

  {
    id: 21,
    date: "20/03/2021",
    fileName: "Invoice 0132",
  },
];
