import { Box, Grid, Typography } from "@mui/material";
import { PageHeader } from "app/components/page-header";
import { ReactComponent as AccountProfileHeaderIcon } from "app/assets/vectors/AccountProfileHeaderIcon.svg";
import { ReactComponent as LaptopCat } from "app/assets/vectors/LaptopCat.svg";
import { LeftBlock } from "./components/left-block";

export const Login: React.FC = () => {
  return (
    <Box>
      <PageHeader
        variant="explorer"
        title="Account Profile"
        subtitle="Sign up, manage your account settings and get notifications on your activity alerts."
        icon={<AccountProfileHeaderIcon />}
      />

      <Box height={40} />
      <Typography variant="h2" fontSize="36px" lineHeight="normal">
        Sign In
      </Typography>
      <Box height={40} />
      <Grid container columnSpacing={"40px"}>
        <Grid item xs={12} md={6}>
          <LeftBlock />
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              padding: "30px 50px",
              background: "#ffffff",
            }}
          >
            <Typography variant="h3" fontSize="24px" lineHeight="normal">
              Sign in to AIDA to benefit from;
            </Typography>

            <Typography
              variant="body1"
              fontSize="14px"
              lineHeight="20px"
              marginTop={"10px"}
            >
              3-step publishing process that makes publishing development aid
              data easier than ever, and bookmarking the selected activities and
              projects to follow the latest updates.
            </Typography>

            <Box height={20} />
            <Box
              sx={{
                margin: "0 auto",
                width: "max-content",
              }}
            >
              <LaptopCat />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
