import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { IDealLogo } from "app/assets/vectors/jsx/IDealLogo";
import { MastercardLogo } from "app/assets/vectors/jsx/MastercardLogo";
import { PayPalLogo } from "app/assets/vectors/jsx/PayPalLogo";
import { VisaLogo } from "app/assets/vectors/jsx/VisaLogo";
import { AccountProfileCard } from "app/components/account-profile-card";
import { SelectField } from "app/components/select-field";
import { colors } from "app/theme";

export const ChangePaymentMethodSetting = (props: {
  setActiveSetting?: (value: string) => void;
}) => {
  const radio = (
    <Radio
      sx={{
        "&.Mui-checked": {
          color: `${colors.text.body} !important`,
        },
      }}
    />
  );
  return (
    <AccountProfileCard
      title="Change Payment Method"
      content={
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="ideal"
            name="radio-buttons-group"
            sx={{ width: "100%" }}
          >
            <FormControlLabel
              value="ideal"
              control={radio}
              label={
                <Box>
                  <Typography
                    variant="h5"
                    fontWeight={"400"}
                    fontSize={"14px"}
                    lineHeight={"20px"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={"12px"}
                    marginBottom={"12px"}
                  >
                    <IDealLogo />
                    Ideal
                  </Typography>
                  <SelectField
                    id="select-a-bank"
                    value={""}
                    onChange={() => {}}
                    options={[]}
                    placeholder="Select a bank"
                    variant="outlined"
                    width="300px"
                    height="32px"
                    fontWeight="400"
                  />
                </Box>
              }
              sx={{
                alignItems: "center",
                padding: "10px",
                gap: "20px",
              }}
            />
            <Divider />
            <FormControlLabel
              value="paypal"
              control={radio}
              label={<PayPalLogo />}
              sx={{
                alignItems: "center",
                padding: "10px",
                gap: "20px",
              }}
            />
            <Divider />
            <FormControlLabel
              value="credit-card"
              control={radio}
              label={
                <Box
                  sx={{
                    display: "flex",
                    gap: "18px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    fontWeight={"400"}
                    fontSize={"14px"}
                    lineHeight={"20px"}
                  >
                    Credit card
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <VisaLogo />
                    <MastercardLogo />
                  </Box>
                </Box>
              }
              sx={{
                alignItems: "center",
                padding: "10px",
                gap: "20px",
              }}
            />
          </RadioGroup>
        </FormControl>
      }
      setActiveSetting={props.setActiveSetting}
      onBack={() => props.setActiveSetting?.("payment-details")}
    />
  );
};
