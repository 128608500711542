import React from "react";
import get from "lodash/get";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useDebounce } from "react-use";
import Typography from "@mui/material/Typography";
import { PageHeader } from "app/components/page-header";
import { ShareButton } from "app/components/share-button";
import { DownloadButton } from "app/components/download-button";
import { YearSelectButton } from "app/components/year-select-button";
import { ExplorerSearch } from "app/pages/explorer/components/search";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { TRANSACTION_TYPES } from "./components/transaction-type/data";
import { InsightsBlock } from "app/pages/explorer/components/insights-block";
import { emptySearchResults } from "app/pages/explorer/components/search/data";
import { TransactionTypeSelect } from "app/pages/explorer/components/transaction-type";
import { KeyProjectionsBlock } from "app/pages/explorer/components/key-projections-block";
import { ReactComponent as ExplorerHeaderIcon } from "app/assets/vectors/ExplorerHeader.svg";

const box = <Box height={40} />;

export const Explorer: React.FC = () => {
  const [search1, setSearch1] = React.useState("");
  const [transactionType1, setTransactionType1] = React.useState(
    TRANSACTION_TYPES[0]
  );
  const [selectedPeriod1, setSelectedPeriod1] = React.useState({
    startYear: "2023",
    endYear: "2023",
    startMonth: "1",
    endMonth: "12",
  });
  const [search2, setSearch2] = React.useState("");
  const [transactionType2, setTransactionType2] = React.useState(
    TRANSACTION_TYPES[0]
  );
  const [selectedPeriod2, setSelectedPeriod2] = React.useState({
    startYear: new Date().getFullYear().toString(),
    endYear: "2050",
    startMonth: (new Date().getMonth() + 1).toString(),
    endMonth: "12",
  });
  const [searchActivitiesPage, setSearchActivitiesPage] = React.useState(1);

  const searchFetch = useStoreActions(
    (actions) => actions.ExplorerSearch.fetch
  );
  const overviewStatsFetch = useStoreActions(
    (actions) => actions.ExplorerOverviewStats.fetch
  );
  const locationsFetch = useStoreActions(
    (actions) => actions.ExplorerLocations.fetch
  );
  const sdgsFetch = useStoreActions((actions) => actions.ExplorerSDGs.fetch);
  const organisationsNetworkFetch = useStoreActions(
    (actions) => actions.ExplorerOrganisationsNetwork.fetch
  );
  const fetchBudgetBySector = useStoreActions(
    (actions) => actions.ExplorerBudgetBySector.fetch
  );
  const organisationsDonutFetch = useStoreActions(
    (actions) => actions.ExplorerOrganisationsDonut.fetch
  );
  const sectorsFetch = useStoreActions(
    (actions) => actions.ExplorerSectors.fetch
  );
  const impactFetch = useStoreActions(
    (actions) => actions.ExplorerImpactResults.fetch
  );

  const projectionsOverviewStatsFetch = useStoreActions(
    (actions) => actions.ExplorerProjectionsOverviewStats.fetch
  );
  const projectionsLocationsFetch = useStoreActions(
    (actions) => actions.ExplorerProjectionsLocations.fetch
  );
  const projectionsOrganisationsDonutFetch = useStoreActions(
    (actions) => actions.ExplorerProjectionsOrganisationsDonut.fetch
  );
  const projectionsSectorsFetch = useStoreActions(
    (actions) => actions.ExplorerProjectionsSectors.fetch
  );

  const searchResults = useStoreState((state) =>
    get(state.ExplorerSearch, "data.data", {
      activities: {
        count: 0,
        items: [],
        hasMore: false,
      },
      locations: {
        count: 0,
        items: [],
      },
      organisations: {
        count: 0,
        items: [],
      },
      sectors: {
        count: 0,
        items: [],
      },
    })
  );
  const search1Loading = useStoreState((state) => state.ExplorerSearch.loading);

  const period1Label = React.useMemo(() => {
    if (
      selectedPeriod1.startMonth === "1" &&
      selectedPeriod1.endMonth === "12"
    ) {
      if (selectedPeriod1.startYear === selectedPeriod1.endYear) {
        return selectedPeriod1.startYear;
      }
      return `${selectedPeriod1.startYear} - ${selectedPeriod1.endYear}`;
    }
    return `${selectedPeriod1.startMonth.length === 1 ? "0" : ""}${
      selectedPeriod1.startMonth
    }-${selectedPeriod1.startYear} - ${
      selectedPeriod1.endMonth.length === 1 ? "0" : ""
    }${selectedPeriod1.endMonth}-${selectedPeriod1.endYear}`;
  }, [selectedPeriod1]);

  const period1RouteParam = React.useMemo(() => {
    return `${selectedPeriod1.startYear}-${
      selectedPeriod1.startMonth.length < 2 ? "0" : ""
    }${selectedPeriod1.startMonth}|${selectedPeriod1.endYear}-${
      selectedPeriod1.endMonth.length < 2 ? "0" : ""
    }${selectedPeriod1.endMonth}`;
  }, [selectedPeriod1]);

  const period2Label = React.useMemo(() => {
    if (selectedPeriod2.startYear === selectedPeriod2.endYear) {
      return selectedPeriod2.startYear;
    }
    if (
      selectedPeriod2.startMonth === "1" &&
      selectedPeriod2.endMonth === "12"
    ) {
      return `${selectedPeriod2.startYear} - ${selectedPeriod2.endYear}`;
    }
    return `${selectedPeriod2.startMonth.length === 1 ? "0" : ""}${
      selectedPeriod2.startMonth
    }-${selectedPeriod2.startYear} - ${
      selectedPeriod2.endMonth.length === 1 ? "0" : ""
    }${selectedPeriod2.endMonth}-${selectedPeriod2.endYear}`;
  }, [selectedPeriod2]);

  const period2RouteParam = React.useMemo(() => {
    return `${selectedPeriod2.startYear}-${
      selectedPeriod2.startMonth.length < 2 ? "0" : ""
    }${selectedPeriod2.startMonth}|${selectedPeriod2.endYear}-${
      selectedPeriod2.endMonth.length < 2 ? "0" : ""
    }${selectedPeriod2.endMonth}`;
  }, [selectedPeriod2]);

  const onLoadMoreSearchActivities = () => {
    if (search1Loading) return;
    searchFetch({
      addOnData: true,
      addOnDataKey: "activities",
      routeParams: { q: search1 },
      filterString: `page=${searchActivitiesPage + 1}`,
    });
    setSearchActivitiesPage((prev) => prev + 1);
  };

  useDebounce(
    () => {
      if (search1.length > 0) {
        searchFetch({ routeParams: { q: search1 } });
      }
    },
    250,
    [search1]
  );

  React.useEffect(() => {
    overviewStatsFetch({
      routeParams: {
        period: period1RouteParam,
        transactionTypeCode: transactionType1.code.toString(),
      },
    });
    locationsFetch({
      routeParams: {
        period: period1RouteParam,
        transactionTypeCode: transactionType1.code.toString(),
      },
    });
    organisationsDonutFetch({
      routeParams: {
        period: period1RouteParam,
        transactionTypeCode: transactionType1.code.toString(),
      },
    });
  }, [period1RouteParam, transactionType1.code]);

  React.useEffect(() => {
    sectorsFetch({
      routeParams: {
        year: selectedPeriod1.startYear,
        transactionTypeCode: transactionType1.code.toString(),
      },
    });
  }, [selectedPeriod1.startYear, transactionType1.code]);

  React.useEffect(() => {
    let sdgsFetchPeriod = period1RouteParam;
    if (
      selectedPeriod1.startYear === selectedPeriod1.endYear &&
      selectedPeriod1.startMonth === "1" &&
      selectedPeriod1.endMonth === "12"
    ) {
      sdgsFetchPeriod = selectedPeriod1.startYear;
    }
    sdgsFetch({
      routeParams: {
        year: sdgsFetchPeriod,
        transactionTypeCode: transactionType1.code.toString(),
      },
    });
  }, [selectedPeriod1, transactionType1]);

  React.useEffect(() => {
    let fetchBudgetBySectorYears = [];
    if (selectedPeriod1.startYear !== selectedPeriod1.endYear) {
      fetchBudgetBySectorYears = [
        selectedPeriod1.startYear,
        selectedPeriod1.endYear,
      ];
    } else {
      fetchBudgetBySectorYears = [
        (parseInt(selectedPeriod1.startYear, 10) - 10).toString(),
        selectedPeriod1.startYear,
      ];
    }
    fetchBudgetBySector({
      routeParams: {
        years: fetchBudgetBySectorYears.join("|"),
      },
    });
  }, [selectedPeriod1]);

  React.useEffect(() => {
    organisationsNetworkFetch({
      routeParams: {
        period: period1RouteParam,
      },
    });
    impactFetch({
      routeParams: {
        period: period1RouteParam,
      },
    });
  }, [period1RouteParam]);

  React.useEffect(() => {
    projectionsOverviewStatsFetch({
      routeParams: {
        period: period2RouteParam,
        transactionTypeCode: transactionType2.code.toString(),
      },
    });
    projectionsLocationsFetch({
      routeParams: {
        period: period2RouteParam,
        transactionTypeCode: transactionType2.code.toString(),
      },
    });
    projectionsOrganisationsDonutFetch({
      routeParams: {
        period: period2RouteParam,
        transactionTypeCode: transactionType2.code.toString(),
      },
    });
    projectionsSectorsFetch({
      routeParams: {
        period: period2RouteParam,
        transactionTypeCode: transactionType2.code.toString(),
      },
    });
  }, [period2RouteParam, transactionType2.code]);

  const search1Results = React.useMemo(() => {
    return {
      ...searchResults,
      activities: {
        ...searchResults.activities,
        hasMore:
          searchResults.activities.count >
          searchResults.activities.items.length,
      },
    };
  }, [searchResults]);

  return (
    <Box>
      <PageHeader
        variant="explorer"
        title="AIDA Explorer: Discover Data Insights"
        subtitle="Discover insights, trends, and funding activities around the globe. Uncover valuable information to fuel your data-driven decisions."
        icon={<ExplorerHeaderIcon />}
      />
      {box}
      <Grid display="flex" justifyContent="space-between">
        <Typography variant="h2" fontSize="32px">
          Real-Time Insights: Aid Data
          <br />
          {period1Label}
        </Typography>
        <Grid display="flex" alignItems="flex-start" columnGap="8px">
          <ExplorerSearch
            searchValue={search1}
            results={search1Results}
            loading={search1Loading}
            setSearchValue={setSearch1}
            loadMore={onLoadMoreSearchActivities}
          />
          <Box display="flex" alignItems="center" gap="8px" flexWrap="wrap">
            <TransactionTypeSelect
              value={transactionType1}
              setValue={setTransactionType1}
            />
            <YearSelectButton
              data={selectedPeriod1}
              setData={setSelectedPeriod1}
            />
            <ShareButton id="share-button" />
            <DownloadButton id="download-button" />
          </Box>
        </Grid>
      </Grid>
      {box}
      <InsightsBlock period={period1Label} transactionType={transactionType1} />
      {box}
      <Grid display="flex" justifyContent="space-between">
        <Typography variant="h2" fontSize="32px">
          The World in
          <br />
          {period2Label}:<br />
          Key Projections
        </Typography>
        <Grid display="flex" alignItems="flex-start" columnGap="8px">
          <ExplorerSearch
            loading={false}
            searchValue={search2}
            setSearchValue={setSearch2}
            results={emptySearchResults}
          />
          <Box display="flex" alignItems="center" gap="8px" flexWrap="wrap">
            <TransactionTypeSelect
              value={transactionType2}
              setValue={setTransactionType2}
            />
            <YearSelectButton
              data={selectedPeriod2}
              setData={setSelectedPeriod2}
            />
            <ShareButton id="share-button" />
            <DownloadButton id="download-button" />
          </Box>
        </Grid>
      </Grid>
      {box}
      <KeyProjectionsBlock
        period={period2Label}
        transactionType={transactionType2}
      />
    </Box>
  );
};
