import React from "react";
import get from "lodash/get";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { CircleFlag } from "react-circle-flags";
import { SelectChangeEvent } from "@mui/material";
import { PageHeader } from "app/components/page-header";
import { SelectField } from "app/components/select-field";
import { ShareButton } from "app/components/share-button";
import { useNavigate, useParams } from "react-router-dom";
import { InsightsBlock } from "./components/insights-block";
import { DownloadButton } from "app/components/download-button";
import { YearSelectButton } from "app/components/year-select-button";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { TransactionTypeSelect } from "app/pages/explorer/components/transaction-type";
import { TRANSACTION_TYPES } from "app/pages/explorer/components/transaction-type/data";
import { ExpandedBlockType } from "app/pages/explorer/pages/locations/pages/location/data";
import { useUpdateEffect } from "react-use";

export const ExplorerLocation: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [activitiesPage, setActivitiesPage] = React.useState(1);
  const [transactionType, setTransactionType] = React.useState(
    TRANSACTION_TYPES[0]
  );
  const [selectedPeriod, setSelectedPeriod] = React.useState({
    startYear: "2023",
    endYear: "2023",
    startMonth: "1",
    endMonth: "12",
  });
  const [expandedBlock, setExpandedBlock] =
    React.useState<ExpandedBlockType>(null);

  const fetchOverviewStats = useStoreActions(
    (actions) => actions.ExplorerLocationPageOverviewStats.fetch
  );
  const fetchMap = useStoreActions(
    (actions) => actions.ExplorerLocationPageMap.fetch
  );
  const fetchSDGs = useStoreActions(
    (actions) => actions.ExplorerLocationPageSDGs.fetch
  );
  const fetchOrganisationsNetwork = useStoreActions(
    (actions) => actions.ExplorerLocationPageOrganisationsNetwork.fetch
  );
  const fetchOrganisationsDonut = useStoreActions(
    (actions) => actions.ExplorerLocationPageOrganisationsDonut.fetch
  );
  const fetchBudgetBySector = useStoreActions(
    (actions) => actions.ExplorerLocationPageBudgetBySector.fetch
  );
  const fetchSectors = useStoreActions(
    (actions) => actions.ExplorerLocationPageSectors.fetch
  );
  const fetchImpact = useStoreActions(
    (actions) => actions.ExplorerLocationPageImpactResults.fetch
  );
  const fetchActivities = useStoreActions(
    (actions) => actions.ExplorerLocationPageActivityTable.fetch
  );
  const loadingActivities = useStoreState(
    (state) => state.ExplorerLocationPageActivityTable.loading
  );

  const locationList = useStoreState((state) =>
    get(state.ExplorerLocationFilterOptions, "data.data.locations", [])
  );

  const handleLocationChange = (e: SelectChangeEvent) => {
    navigate(`/explorer/locations/${e.target.value}`);
  };

  const handleSetExpandedBlock = (block: ExpandedBlockType) => {
    setExpandedBlock((prev) => (prev === block ? null : block));
  };

  const onLoadMoreActivities = () => {
    if (loadingActivities) return;
    setActivitiesPage((prev) => {
      fetchActivities({
        addOnData: true,
        filterString: `country=${id}&period=${periodRouteParam}`,
        routeParams: {
          page: (prev + 1).toString(),
        },
      });
      return prev + 1;
    });
  };

  const countries: {
    code: string;
    name: string;
  }[] = React.useMemo(() => {
    return locationList.map((location: { value: string; label: string }) => ({
      code: location.value,
      name: location.label,
    }));
  }, [locationList]);

  const periodLabel = React.useMemo(() => {
    if (selectedPeriod.startYear === selectedPeriod.endYear) {
      return selectedPeriod.startYear;
    }
    if (selectedPeriod.startMonth === "1" && selectedPeriod.endMonth === "12") {
      return `${selectedPeriod.startYear} - ${selectedPeriod.endYear}`;
    }
    return `${selectedPeriod.startMonth.length === 1 ? "0" : ""}${
      selectedPeriod.startMonth
    }-${selectedPeriod.startYear} - ${
      selectedPeriod.endMonth.length === 1 ? "0" : ""
    }${selectedPeriod.endMonth}-${selectedPeriod.endYear}`;
  }, [selectedPeriod]);

  const periodRouteParam = React.useMemo(() => {
    return `${selectedPeriod.startYear}-${
      selectedPeriod.startMonth.length < 2 ? "0" : ""
    }${selectedPeriod.startMonth}|${selectedPeriod.endYear}-${
      selectedPeriod.endMonth.length < 2 ? "0" : ""
    }${selectedPeriod.endMonth}`;
  }, [selectedPeriod]);

  React.useEffect(() => {
    if (id) {
      fetchOverviewStats({
        routeParams: {
          code: id,
          period: periodRouteParam,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
      fetchMap({
        routeParams: { code: id },
        filterString: `startDate=${selectedPeriod.startYear}-01-01T00:00:00.000Z&endDate=${selectedPeriod.endYear}-12-31T00:00:00.000Z`,
      });
      fetchOrganisationsDonut({
        filterString: `country=${id}`,
        routeParams: {
          period: periodRouteParam,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
    }
  }, [id, periodRouteParam, transactionType]);

  React.useEffect(() => {
    if (id) {
      let fetchBudgetBySectorYears = [];
      if (selectedPeriod.startYear !== selectedPeriod.endYear) {
        fetchBudgetBySectorYears = [
          selectedPeriod.startYear,
          selectedPeriod.endYear,
        ];
      } else {
        fetchBudgetBySectorYears = [
          (parseInt(selectedPeriod.startYear, 10) - 10).toString(),
          selectedPeriod.startYear,
        ];
      }
      fetchBudgetBySector({
        routeParams: {
          years: fetchBudgetBySectorYears.join("|"),
        },
      });
    }
  }, [id, selectedPeriod.startYear]);

  React.useEffect(() => {
    if (id) {
      fetchOrganisationsNetwork({
        filterString: `country=${id}`,
        routeParams: {
          period: periodRouteParam,
        },
      });
      fetchImpact({
        filterString: `country=${id}`,
        routeParams: {
          period: periodRouteParam,
        },
      });
      fetchActivities({
        filterString: `country=${id}&period=${periodRouteParam}`,
        routeParams: {
          page: "1",
        },
      });
    }
  }, [id, periodRouteParam]);

  React.useEffect(() => {
    if (id) {
      let sdgsFetchPeriod = periodRouteParam;
      if (
        selectedPeriod.startYear === selectedPeriod.endYear &&
        selectedPeriod.startMonth === "1" &&
        selectedPeriod.endMonth === "12"
      ) {
        sdgsFetchPeriod = selectedPeriod.startYear;
      }
      fetchSDGs({
        filterString: `country=${id}`,
        routeParams: {
          year: sdgsFetchPeriod,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
    }
  }, [id, selectedPeriod, transactionType]);

  React.useEffect(() => {
    if (id) {
      fetchSectors({
        filterString: `country=${id}`,
        routeParams: {
          year: selectedPeriod.startYear,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
    }
  }, [id, selectedPeriod.startYear, transactionType.code]);

  const location = React.useMemo(() => {
    return countries.find(
      (location) => location.code.toLowerCase() === id?.toLowerCase()
    );
  }, [countries, id]);

  return (
    <Box>
      <PageHeader
        variant="explorer"
        title={location?.name!}
        subtitle={`Showcasing country-specific data of ${location?.name}.`}
        icon={
          <Box
            sx={{
              marginRight: "20px",
            }}
          >
            <CircleFlag
              countryCode={id?.toLowerCase()!}
              width={60}
              height={60}
            />
          </Box>
        }
      />
      <Box height={30} />
      <Grid display="flex" alignItems="center" justifyContent="space-between">
        <SelectField
          id="location-select"
          options={countries.map((s) => ({
            label: (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "10px",
                }}
              >
                <CircleFlag
                  width={12}
                  height={12}
                  countryCode={s.code.toLowerCase()}
                />
                {s.name}
              </Box>
            ),
            value: s.code,
          }))}
          placeholder="Select Another Location"
          value={id ?? ""}
          onChange={handleLocationChange}
          variant="outlined"
          width="300px"
        />
        <Grid display="flex" alignItems="center" columnGap="8px">
          <TransactionTypeSelect
            value={transactionType}
            setValue={setTransactionType}
          />
          <YearSelectButton data={selectedPeriod} setData={setSelectedPeriod} />
          <ShareButton id="share-button" />
          <DownloadButton id="download-button" />
        </Grid>
      </Grid>
      <Box height={30} />
      <InsightsBlock
        period={periodLabel}
        countries={countries}
        expandedBlock={expandedBlock}
        transactionType={transactionType}
        loadMoreActivities={onLoadMoreActivities}
        setExpandedBlock={handleSetExpandedBlock}
      />
    </Box>
  );
};
