import React from "react";
import { useStoreActions } from "app/state/store/hooks";

export const useInitialLoad = () => {
  const fetchLocationFilterOptions = useStoreActions(
    (actions) => actions.ExplorerLocationFilterOptions.fetch
  );
  const fetchPublisherFilterOptions = useStoreActions(
    (actions) => actions.ExplorerPublisherFilterOptions.fetch
  );
  const fetchSectorsFilterOptions = useStoreActions(
    (actions) => actions.ExplorerSectorsFilterOptions.fetch
  );
  const fetchActivityStatusFilterOptions = useStoreActions(
    (actions) => actions.ExplorerActivityStatusFilterOptions.fetch
  );
  const fetchYearFilterOptions = useStoreActions(
    (actions) => actions.ExplorerYearFilterOptions.fetch
  );

  React.useEffect(() => {
    fetchLocationFilterOptions({});
    fetchPublisherFilterOptions({});
    fetchSectorsFilterOptions({});
    fetchActivityStatusFilterOptions({});
    fetchYearFilterOptions({});
  }, []);

  return null;
};
