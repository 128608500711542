import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { ChevronDown } from "app/assets/vectors/jsx/arrow";
import { colors } from "app/theme";

export const Accord = ({
  title,
  subtitle,
  children,
}: {
  title: string;
  subtitle: string;
  children: React.ReactNode;
}) => {
  return (
    <Accordion
      sx={{
        backgroundColor: "transparent",
        borderBottom: "none",
        "&.Mui-expanded": {
          padding: 0,
          backgroundColor: "transparent",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ChevronDown />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          background: colors.primary.blue,
          padding: "15px 80px 15px 30px",
          minHeight: "unset",
          maxHeight: "unset",
          "&.Mui-expanded": {
            minHeight: "unset",
            maxHeight: "unset",
          },
        }}
      >
        <Box
          sx={{
            paddingRight: "50px",
          }}
        >
          <Typography
            lineHeight={"normal"}
            variant="h2"
            color={colors.primary.white}
            fontSize={"36px"}
          >
            {title}
          </Typography>
          <Typography
            marginTop={"10px"}
            lineHeight={"20px"}
            fontSize={"14px"}
            fontWeight={"400"}
            variant="h5"
            color={colors.primary.white}
          >
            {subtitle}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
