import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

export const ExplorerLocationFilterOptions: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/explorer/filter-options/locations`),
};

export const ExplorerPublisherFilterOptions: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/filter-options/publishers`
  ),
};

export const ExplorerSectorsFilterOptions: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/explorer/filter-options/sectors`),
};

export const ExplorerActivityStatusFilterOptions: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/filter-options/activity-status`
  ),
};

export const ExplorerYearFilterOptions: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/explorer/filter-options/years`),
};
