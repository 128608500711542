import React from "react";

export const AlertShield = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      {...props}
    >
      <path
        d="M4.65388 8.57316C4.84496 6.92575 5.84441 5.56573 7.46374 5.20762C9.9896 4.64902 14.8855 4 24 4C33.1145 4 38.0104 4.64902 40.5363 5.20762C42.1556 5.56574 43.155 6.92575 43.3461 8.57316C43.6351 11.0644 44 15.1673 44 20C44 29.0284 39.2351 37.4764 31.2074 41.6079C28.5258 42.988 25.9087 44 24 44C22.0913 44 19.4742 42.988 16.7925 41.6079C8.76493 37.4764 4 29.0284 4 20C4 15.1673 4.36493 11.0644 4.65388 8.57316Z"
        fill="#9DB6E9"
      />
      <path
        d="M21.7796 10.2959C20.5782 10.6388 20.0424 11.8711 20.1244 13.1178C20.3133 15.9924 20.6948 21.2959 21.1061 24.1431C21.2277 24.9854 21.7162 25.7208 22.5525 25.8785C22.9289 25.9495 23.4057 26 24.0003 26C24.5949 26 25.0717 25.9495 25.4481 25.8785C26.2844 25.7208 26.7729 24.9854 26.8945 24.1431C27.3058 21.2959 27.6873 15.9924 27.8762 13.1178C27.9582 11.8711 27.4224 10.6388 26.221 10.2959C25.6287 10.1268 24.8884 10 24.0003 10C23.1122 10 22.3719 10.1268 21.7796 10.2959Z"
        fill="#294C94"
      />
      <path
        d="M24 37C26.2091 37 28 35.2091 28 33C28 30.7909 26.2091 29 24 29C21.7909 29 20 30.7909 20 33C20 35.2091 21.7909 37 24 37Z"
        fill="#294C94"
      />
    </svg>
  );
};
