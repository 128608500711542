import { Box, Collapse, Typography } from "@mui/material";
import { colors } from "app/theme";
import {
  CONTENT_COLUMNS,
  METADATA_COLUMNS,
  VALIDATION_REPORT_COLUMNS,
} from "../../tableData";
import { SimpleTable } from "app/components/simple-table";

interface DetailPanelContentProps {
  row: any;
  expandedRowIds: string[];
}

export const DetailPanelContent = (props: DetailPanelContentProps) => {
  const showMetadata = props.expandedRowIds.includes(
    `${props.row.id}.showMetadata`
  );

  const showValidationReport = props.expandedRowIds.includes(
    `${props.row.id}.showValidationReport`
  );

  return (
    <Collapse in={showMetadata ?? showValidationReport}>
      <Box
        sx={{
          border:
            showMetadata || showValidationReport
              ? `0.5px solid ${colors.secondary.iconGray}`
              : "none",
          backgroundColor: colors.secondary.lightGrey,
          overflow: "hidden",
          padding: "20px",
        }}
      >
        <Collapse unmountOnExit in={showMetadata}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: "20px",
            }}
          >
            <Box>
              <Typography variant="h6" fontSize={"12px"}>
                Metadata
              </Typography>
              <Box height={16} />
              <SimpleTable
                variant="vertical"
                rows={[props.row]}
                columns={METADATA_COLUMNS}
              />
            </Box>
            <Box>
              <Typography variant="h6" fontSize={"12px"}>
                Contents
              </Typography>
              <Box height={16} />
              <SimpleTable
                variant="vertical"
                rows={[props.row]}
                columns={CONTENT_COLUMNS}
              />
            </Box>
          </Box>
        </Collapse>
        {showMetadata && showValidationReport && <Box height={16} />}
        <Collapse unmountOnExit in={showValidationReport}>
          <Typography variant="h6" fontSize={"12px"}>
            Validation Report
          </Typography>
          <Box height={16} />
          <SimpleTable
            columns={VALIDATION_REPORT_COLUMNS}
            rows={[props.row.validationReport]}
            variant="horizontal"
          />
        </Collapse>
      </Box>
    </Collapse>
  );
};
