import React from "react";

export const MicrosoftCloudLogo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_2809_194774)">
        <path
          d="M24.5086 23.8004C24.5086 23.8004 33.4078 17.94 33.5998 17.9526C32.9654 15.5681 31.6255 13.4124 29.7429 11.7476C27.8603 10.0827 25.5161 8.98027 22.9952 8.57435C20.4744 8.16843 17.8853 8.47646 15.5428 9.461C13.2003 10.4455 11.2051 12.0642 9.7998 14.1203H9.93449L24.5086 23.8004Z"
          fill="#0364B8"
        />
        <path
          d="M16.2515 15.5924C14.57 14.5483 12.6309 13.9967 10.6533 14H10.5173C8.58205 14.0254 6.69022 14.579 5.04489 15.6015C3.39956 16.6239 2.06285 18.0766 1.17825 19.8036C0.293642 21.5306 -0.105456 23.4666 0.0237923 25.4039C0.153041 27.3413 0.805755 29.2067 1.91187 30.8L16.2353 29.2727L25.2 20.9695L16.2515 15.5924Z"
          fill="#0F78D4"
        />
        <path
          d="M34.2152 18.2212C34.0276 18.2077 33.8396 18.2006 33.6515 18.2002C32.5131 18.1998 31.3866 18.4384 30.3418 18.9014L25.2002 21.1151L31.2353 28.279L41.0019 30.8002C41.6643 29.5414 42.0074 28.1326 42.0001 26.7028C41.9927 25.2731 41.635 23.8681 40.9597 22.6165C40.2845 21.3649 39.3132 20.3067 38.1349 19.5388C36.9566 18.7709 35.6089 18.3179 34.2152 18.2212Z"
          fill="#1490DF"
        />
        <path
          d="M33.128 26.1488L30.9441 24.8626L25.9364 21.9033L24.4082 21L20.3782 22.6741L12.6571 25.8777L9.19569 27.3124L1.39941 30.5477C2.36794 31.9225 3.65935 33.0455 5.16332 33.8208C6.66729 34.5961 8.33912 35.0007 10.036 35H33.0679C34.6121 35.0003 36.1276 34.5881 37.4536 33.8072C38.7796 33.0263 39.8667 31.9058 40.5994 30.5645L33.128 26.1488Z"
          fill="#28A8EA"
        />
      </g>
      <defs>
        <clipPath id="clip0_2809_194774">
          <rect width="42" height="42" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
