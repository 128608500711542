import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { formatNumber } from "app/utils/formatNumber";
import { PageHeader } from "app/components/page-header";
import { NetworkChart } from "app/components/charts/network";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { ExploreListAllBlock } from "app/components/explore-list-all-block";
import { EuropeanUnionLogo } from "app/assets/vectors/jsx/EuropeanUnionLogo";
import { ExploreListBlockItem } from "app/components/explore-list-block-Item";
import { ORGANISATION_COLUMNS } from "app/pages/explorer/pages/organisations/tableColumns";
import { ReactComponent as ExplorerOrganisationsHeaderIcon } from "app/assets/vectors/ExplorerOrganisationsHeaderIcon.svg";

const box = <Box height={40} />;

export const ExplorerOrganisations: React.FC = () => {
  const stats = useStoreState((state) =>
    get(state.ExplorerOrganisationStats, "data.data", {
      disbursement: 0,
      sdgActivities: 0,
      countries: [],
    })
  );
  const statsLoading = useStoreState(
    (state) => state.ExplorerOrganisationStats.loading
  );
  const fetchStats = useStoreActions(
    (actions) => actions.ExplorerOrganisationStats.fetch
  );
  const list = useStoreState((state) =>
    get(state.ExplorerOrganisationsList, "data.data", [])
  );
  const listLoading = useStoreState(
    (state) => state.ExplorerOrganisationsList.loading
  );
  const fetchList = useStoreActions(
    (actions) => actions.ExplorerOrganisationsList.fetch
  );
  const partnersNetwork = useStoreState((state) =>
    get(state.ExplorerOrganisationPartners, "data.data", {
      data: [],
      count: 0,
    })
  );
  const fetchPartnersNetwork = useStoreActions(
    (actions) => actions.ExplorerOrganisationPartners.fetch
  );

  React.useEffect(() => {
    fetchStats({
      routeParams: {
        code: "XI-IATI-EC_ECHO",
        year: "2023",
        transactionTypeCode: "1",
      },
    });
    fetchList({});
    fetchPartnersNetwork({
      routeParams: {
        code: "XI-IATI-EC_ECHO",
      },
    });
  }, []);

  return (
    <Box>
      <PageHeader
        variant="explorer"
        title="AIDA Explorer: Explore Publishing Organisations"
        subtitle="Get ready to dive into the heart of humanitarian aid with AIDA's organisation-specific data. This feature invites you to explore a network of dedicated organisations making a real difference in the world."
        icon={<ExplorerOrganisationsHeaderIcon />}
      />
      {box}
      <Typography variant="h2" fontSize={"36px"} lineHeight={"normal"}>
        Explore the Organisation of the Month
      </Typography>
      {box}
      <ExploreListBlockItem
        icon={<EuropeanUnionLogo />}
        title="ORGANISATION OF THE MONTH"
        description="European Commission - Humanitarian Aid & Civil Protection"
        loading={statsLoading}
        leftItems={[
          {
            title: "Overview",
            description: (
              <React.Fragment>
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {formatNumber(stats.disbursement, true)}
                </span>{" "}
                was spent for{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {stats.countries.length} recipient countries{" "}
                </span>{" "}
                in 2023.
              </React.Fragment>
            ),
          },
          {
            title: "Locations",
            description: (
              <React.Fragment>
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {stats.countries
                    .map((c: any) => c.name)
                    .slice(0, 4)
                    .join(", ")}{" "}
                </span>{" "}
                were the largest recipient countries of 2023.
              </React.Fragment>
            ),
          },
          {
            title: "Results",
            description: (
              <React.Fragment>
                In 2023, available results show activities surpassed about{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  4.8%
                </span>{" "}
                of their target results on average.
              </React.Fragment>
            ),
          },
          {
            title: "SUSTAINABLE DEVELOPMENT GOALS",
            description: (
              <React.Fragment>
                In 2023,{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {stats.sdgActivities} activities{" "}
                </span>
                were contributing to the SDGs.
              </React.Fragment>
            ),
          },
          {
            title: "Sectors",
            description: (
              <React.Fragment>
                European Commission - Humanitarian Aid & Civil Protection
                provided aid for{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {" "}
                  2 sectors{" "}
                </span>
                in all years.
              </React.Fragment>
            ),
          },
        ]}
        rightContent={{
          title: "NETWORKS",
          description: (
            <React.Fragment>
              <span
                style={{
                  color: colors.primary.blue,
                }}
              >
                {partnersNetwork.count} organisations are in
              </span>{" "}
              collaboration with European Commission - Humanitarian Aid & Civil
              Protection.
            </React.Fragment>
          ),
          content: (
            <NetworkChart height="319px" data={partnersNetwork.data} zoom={0} />
          ),
        }}
        buttonText="See the Organisation Page"
        buttonLink="/explorer/organisations/XI-IATI-EC_ECHO"
      />
      {box}
      <Typography variant="h2" fontSize={"36px"} lineHeight={"normal"}>
        Explore All Publishing Organisations & Search
      </Typography>
      {box}
      <ExploreListAllBlock
        rows={list}
        loading={listLoading}
        columns={ORGANISATION_COLUMNS}
      />
    </Box>
  );
};
