import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

export const ExplorerOverviewStats: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/overview-stats/{transactionTypeCode}/{period}`
  ),
};

export const ExplorerProjectionsOverviewStats: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/overview-stats/{transactionTypeCode}/{period}`
  ),
};
