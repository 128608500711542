export interface PieChartProps {
  id: string;
  labelGroup?: string;
  period?: string;
  unitType?: "organisations" | "sectors";
  data: { id: string; name: string; value: number }[];
  donut?: boolean;
  half?: boolean;
  unit?: string;
  labelFontSize?: string;
  height?: string;
  showLabel?: boolean;
  showTooltip?: boolean;
  variant?: "publisher" | "explorer";
}

export const PieChartSampleData: PieChartProps["data"] = [
  { id: "1", name: "Emergency Response", value: 37000000000 },
  { id: "2", name: "Basic", value: 24000000000 },
  { id: "3", name: "Government & Civil Society", value: 24000000000 },
  { id: "4", name: "Employment Creation", value: 18000000000 },
  { id: "5", name: "Immigration", value: 15000000000 },
  { id: "6", name: "Medical Services", value: 12000000000 },
  { id: "7", name: "Social Protection", value: 10000000000 },
  { id: "8", name: "Solar Energy ", value: 9000000000 },
  { id: "9", name: "Other", value: 9000000000 },
];

export const PieChartSampleData2: PieChartProps["data"] = [
  { id: "1", name: "Emergency Response", value: 37000000000 },
  { id: "2", name: "Unallocated / Unspecified", value: 3210000000 },
];
