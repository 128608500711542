import React from "react";
import get from "lodash/get";
import sum from "lodash/sum";
import find from "lodash/find";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import * as echarts from "echarts/core";
import ReactDOMServer from "react-dom/server";
import { useNavigate } from "react-router-dom";
import { SVGRenderer } from "echarts/renderers";
import { TooltipComponentOption } from "echarts";
import { GridComponent } from "echarts/components";
import { useStoreState } from "app/state/store/hooks";
import { formatNumber } from "app/utils/formatNumber";
import { TooltipComponent } from "echarts/components";
import { BarChartProps } from "app/components/charts/bar/data";
import { BarSeriesOption, BarChart as EChartsBar } from "echarts/charts";
import { useChartResizeObserver } from "app/hooks/useChartResizeObserver";
import {
  getRange,
  getFinancialValueWithMetricPrefix,
} from "app/utils/getFinancialValueWithMetricPrefix";

echarts.use([EChartsBar, TooltipComponent, SVGRenderer, GridComponent]);

const Tooltip: React.FC<any> = (props) => {
  return (
    <div
      style={{
        gap: "8px",
        width: "230px",
        lineHeight: 1.2,
        display: "flex",
        flexDirection: "column",
        color: colors.text.title,
      }}
    >
      <div
        style={{
          fontWeight: 700,
          fontSize: "12px",
          maxWidth: "200px",
          overflow: "hidden",
          whiteSpace: "wrap",
        }}
      >
        {props.seriesName}
      </div>
      <div style={{ fontSize: "12px" }}>
        {formatNumber(props.data, true).toUpperCase()} invested in this sector
        in {props.name}
      </div>
    </div>
  );
};

export const BarChart: React.FC<BarChartProps> = (props: BarChartProps) => {
  const navigate = useNavigate();
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [stateChart, setStateChart] =
    React.useState<echarts.EChartsType | null>(null);

  useChartResizeObserver({
    chart: stateChart,
    containerRef: containerRef,
    containerId: "bar-chart",
  });

  const sectorsFilterOptions = useStoreState((state) =>
    get(state.ExplorerSectorsFilterOptions, "data.data.sectors", [])
  );

  const range = React.useMemo(() => {
    const values = props.data.values.map((v: number[]) => ({ sum: sum(v) }));
    return getRange(values, ["sum"]);
  }, [props.data]);

  React.useEffect(() => {
    if (containerRef.current) {
      const chart = echarts.init(containerRef.current, undefined, {
        renderer: "svg",
      });
      const series: BarSeriesOption[] = props.data.series.map((name, i) => {
        return {
          name,
          type: "bar",
          stack: "total",
          barWidth: props.barWidth,
          label: {
            show: false,
          },
          data: props.data.values[i],
        };
      });
      const barColors = [
        "#2A1FF9",
        "#0026FF",
        "#0070CC",
        "#0086B3",
        "#009199",
        "#00806C",
        "#004D22",
        "#00330D",
        "#006642",
        "#009658",
        "#00BA2D",
        "#00D40D",
      ];

      const option: echarts.ComposeOption<
        BarSeriesOption | TooltipComponentOption
      > = {
        grid: {
          top: 40,
          left: 0,
          right: 0,
          bottom: 20,
          containLabel: true,
        },
        color:
          props.data.series.length > 1
            ? barColors.reverse()
            : barColors.slice(0, 1),
        xAxis: {
          type: "category",
          data: props.data.bars,
          axisLabel: {
            rotate: props.xAxisLabelRotate,
            overflow: "break",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            onZero: false,
          },
        },
        yAxis: {
          type: "value",
          name: `${props.yAxisLabelName} (${range.abbr.replace(" ", "")})`,
          axisLabel: {
            formatter: (value: number) => {
              return getFinancialValueWithMetricPrefix(value, range.index);
            },
          },
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
          },
        },
        series,
        tooltip: {
          show: true,
          padding: 10,
          backgroundColor: colors.secondary.lightGrey,
          borderColor: colors.secondary.lightGrayText,
          formatter: (params: any) => {
            return ReactDOMServer.renderToString(<Tooltip {...params} />);
          },
        },
      };

      chart.on("click", (params: any) => {
        const fSector = find(sectorsFilterOptions, {
          label: params.seriesName,
        });
        if (fSector) {
          navigate(`/explorer/sectors/${fSector.value}`);
        }
      });

      chart.setOption(option);
      setStateChart(chart);
    }
  }, [containerRef.current, props.data, range]);

  return (
    <Box
      width="100%"
      id="bar-chart"
      ref={containerRef}
      height={props.height ?? "450px"}
    />
  );
};
