import React from "react";
import { AccountProfileCard } from "app/components/account-profile-card";
import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { GoogleDriveLogo } from "app/assets/vectors/jsx/GoogleDriveLogo";
import { colors } from "app/theme";
import { SelectField } from "app/components/select-field";

const LabelCheckBox = (props: CheckboxProps & { label: React.ReactNode }) => {
  return (
    <FormControlLabel
      sx={{
        alignItems: "center",
      }}
      control={<Checkbox {...props} aria-label="interval" />}
      label={
        <Typography variant="body2" lineHeight="normal">
          {props.label}
        </Typography>
      }
    />
  );
};

export const FileAutomationSetting = (props: {
  option: "google-drive" | "microsoft-cloud";
  setActiveSetting?: (value: string) => void;
}) => {
  const [data, setData] = React.useState({
    startDate: "2024-03-01",
    startTime: "14:23",
    intervalType: "weekly",
    interval: ["Monday"],
    end: "endOnDate",
    endDate: "2024-03-01",
  });

  const intervals = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  return (
    <AccountProfileCard
      title="CONNECTED ACCOUNTS"
      content={
        <React.Fragment>
          <Box>
            <Typography
              variant="h6"
              fontSize={"12px"}
              lineHeight={"normal"}
              fontWeight={"400"}
              marginBottom={"20px"}
              display={"flex"}
              alignItems={"center"}
              gap={"12px"}
            >
              <GoogleDriveLogo width={20} height={20} /> Google Drive Connection
              Settings
            </Typography>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "30px",
                flexDirection: "column",
                input: {
                  width: "250px",
                  height: "40px",
                  fontSize: "12px",
                  padding: "10px 20px",
                  appearance: "none",
                  "-webkit-appearance": "none",
                  borderRadius: "5px",
                  color: colors.text.title,
                  background: colors.secondary.lightGrey,
                  border: `1px solid ${colors.secondary.iconGray}`,
                  ":focus": {
                    outline: "none",
                  },
                },
                label: {
                  display: "block",
                  lineHeight: "20px",
                  marginBottom: "10px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "50px",
                }}
              >
                <Box>
                  <Typography
                    variant="body2"
                    component={"label"}
                    htmlFor="start-date"
                  >
                    Start Date
                  </Typography>
                  <input
                    type="date"
                    placeholder="Jane Doe"
                    name="startDate"
                    id="start-date"
                    value={data.startDate}
                    onChange={handleChange}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography
                    variant="body2"
                    component={"label"}
                    htmlFor="start-time"
                  >
                    Start Time
                  </Typography>
                  <input
                    type="time"
                    placeholder="Jane Doe"
                    name="startTime"
                    id="start-time"
                    value={data.startTime}
                    onChange={handleChange}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "50px",
                }}
              >
                <Box>
                  <Typography
                    variant="body2"
                    component={"label"}
                    htmlFor="interval-type"
                  >
                    Interval Type
                  </Typography>

                  <SelectField
                    id="interval-type"
                    value={data.intervalType}
                    onChange={handleChange}
                    name="intervalType"
                    options={[{ label: "Weekly", value: "weekly" }]}
                    width="250px"
                    variant="outlined"
                    height="40px"
                    fontWeight="400"
                  />
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    component={"label"}
                    htmlFor="interval"
                  >
                    Interval
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      input: {
                        width: "auto",
                        height: "auto",
                        padding: "0",
                      },
                      label: {
                        display: "flex",
                        marginBottom: "0",
                        marginLeft: "0",
                      },
                    }}
                  >
                    {intervals.map((interval) => (
                      <LabelCheckBox
                        key={interval}
                        label={interval}
                        checked={data.interval.includes(interval)}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          setData((prev) => ({
                            ...prev,
                            interval: checked
                              ? [...prev.interval, interval]
                              : prev.interval.filter((i) => i !== interval),
                          }));
                        }}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "50px",
                }}
              >
                <Box>
                  <Typography variant="body2" component={"label"} htmlFor="end">
                    End
                  </Typography>

                  <SelectField
                    id="end"
                    value={data.end}
                    onChange={handleChange}
                    name="end"
                    options={[{ label: "End on Date", value: "endOnDate" }]}
                    width="250px"
                    variant="outlined"
                    height="40px"
                    fontWeight="400"
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography
                    variant="body2"
                    component={"label"}
                    htmlFor="end-date"
                  >
                    End Date
                  </Typography>
                  <input
                    type="date"
                    name="endDate"
                    id="end-date"
                    value={data.endDate}
                    onChange={handleChange}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </React.Fragment>
      }
      setActiveSetting={props.setActiveSetting}
      onBack={() => props.setActiveSetting?.("connected-accounts")}
    />
  );
};
