import React from "react";
import { Box, Typography } from "@mui/material";
import { LandingPageWidgetProps } from "./data";
import { colors } from "app/theme";
import { ArrowFall, ArrowRise } from "app/assets/vectors/jsx/arrow";
import { InfoIcon } from "app/assets/vectors/jsx/InfoIcon";

export const LandingPageWidget = (props: LandingPageWidgetProps) => {
  return (
    <Box sx={{ padding: "20px" }}>
      <Typography
        variant="subtitle1"
        fontSize={"14px"}
        color={colors.secondary.blue}
        lineHeight={"20px"}
      >
        {props.title}
      </Typography>
      <Box height={8} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            variant="subtitle1"
            fontSize={"12px"}
            fontWeight={"400"}
            lineHeight={"normal"}
          >
            {props.description}
          </Typography>
          <Typography
            variant="h6"
            fontSize={"18px"}
            fontWeight={400}
            lineHeight={"24px"}
          >
            {props.valueText}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "4px",
          }}
        >
          <Typography
            color={
              props.percentageChange < 0 ? colors.secondary.red : "#2EA390"
            }
            variant="subtitle1"
            fontSize={"12px"}
          >
            {props.percentageChange > 0 ? "+" : null}
            {props.percentageChange}%
          </Typography>
          {props.percentageChange < 0 ? <ArrowFall /> : <ArrowRise />}
          <InfoIcon />
        </Box>
      </Box>
    </Box>
  );
};
