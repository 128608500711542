import { Box, Typography } from "@mui/material";
import React from "react";
import { ProductOfferCard } from "app/components/product-offer-card";
import { ReactComponent as ProductOfferPublisher } from "app/assets/vectors/ProductOfferPublisher.svg";
import { ReactComponent as ProductOfferExplorer } from "app/assets/vectors/ProductOfferExplorer.svg";

export const ProductOffers = () => {
  return (
    <Box>
      <Typography
        variant="h2"
        lineHeight={"normal"}
        marginBottom={"10px"}
        fontSize={"36px"}
      >
        Product Offers
      </Typography>
      <Typography
        variant="h6"
        lineHeight={"normal"}
        fontSize={"12px"}
        fontWeight={"400"}
      >
        Feel the power of AIDA's dual offerings-streamlined publishing and
        robust exploration-to revolutionize the way you interact with
        humanitarian aid data!
      </Typography>
      <Box height={30} />
      <Box
        sx={{
          display: "grid",
          gap: "50px",
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        }}
      >
        <ProductOfferCard
          image={<ProductOfferPublisher />}
          title="AIDA IATI Publisher"
          tags={[
            "Data Visualizations",
            "Seamless Data Upload",
            "Real-time Validation",
            "User-Friendly Interface",
          ]}
          linkText="See AIDA IATI Publisher"
          linkUrl="/publisher"
          variant="publisher"
        />
        <ProductOfferCard
          image={<ProductOfferExplorer />}
          title="AIDA Explorer"
          tags={[
            "Rich Dataset Insights",
            "Curated Visualisations",
            "Future Projections",
            "Dynamic Filters",
          ]}
          linkText="See AIDA Explorer"
          linkUrl="/explorer"
          variant="explorer"
        />
      </Box>
    </Box>
  );
};
