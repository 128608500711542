import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { SelectChangeEvent } from "@mui/material";
import { ArrowDown } from "app/assets/vectors/jsx/arrow";
import { SelectField } from "app/components/select-field";
import {
  YEARS,
  MONTHS,
  ButtonStyle,
  YearSelectButtonProps,
} from "app/components/year-select-button/data";

export const YearSelectButton = (props: YearSelectButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleChange = (event: SelectChangeEvent) => {
    props.setData({
      ...props.data,
      [event.target.name]: event.target.value,
    });
  };

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    props.setData({
      startYear: "2023",
      endYear: "2023",
      startMonth: "1",
      endMonth: "12",
    });
  };

  const buttonLabel = React.useMemo(() => {
    if (props.data.startYear === props.data.endYear) {
      return props.data.startYear;
    }
    if (props.data.startMonth === "1" && props.data.endMonth === "12") {
      return `${props.data.startYear} - ${props.data.endYear}`;
    }
    return `${props.data.startMonth.length === 1 ? "0" : ""}${props.data.startMonth}-${props.data.startYear} - ${props.data.endMonth.length === 1 ? "0" : ""}${props.data.endMonth}-${props.data.endYear}`;
  }, [props.data]);

  const selectionLabel = React.useMemo(() => {
    const startMonth = MONTHS.find(
      (month) => month.value === props.data.startMonth
    )?.label;
    const endMonth = MONTHS.find(
      (month) => month.value === props.data.endMonth
    )?.label;
    return `${startMonth?.substring(0, 3)} ${props.data.startYear} - ${endMonth?.substring(0, 3)} ${props.data.endYear}`;
  }, [props.data]);

  const open = Boolean(anchorEl);
  const id = open ? "year-select-popup" : undefined;

  return (
    <Box>
      <Button
        aria-describedby={id}
        variant="general"
        sx={ButtonStyle}
        onClick={handleOpen}
      >
        {buttonLabel}
        <ArrowDown transform={`rotate(${open ? "180" : "0"})`} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        //   keepMounted
        sx={{
          zIndex: 3,
          ".MuiPopover-paper": {
            background: "transparent",
          },
        }}
      >
        <Box
          sx={{
            borderRadius: "5px",
            border: `1px solid ${colors.secondary.iconGray}`,
            background: colors.secondary.lightGrey,
            padding: "16px",
            width: "319px",
            marginTop: "13px",
          }}
        >
          <Typography variant="button" lineHeight="20px">
            {selectionLabel}{" "}
            <Typography variant="body2" fontSize="12px" component="span">
              selected.
            </Typography>
          </Typography>
          <Box height={10} />
          <Grid
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <Typography variant="button">from</Typography>
            <Grid display="flex" alignItems="center" columnGap="10px">
              <Box width={130} flexShrink="0">
                <SelectField
                  id="startMonth"
                  value={props.data.startMonth}
                  options={MONTHS}
                  onChange={handleChange}
                  variant="outlined"
                  name="startMonth"
                  label="Month"
                />
              </Box>
              <Box width={100} flexShrink="0">
                <SelectField
                  id="startYear"
                  value={props.data.startYear}
                  options={YEARS}
                  onChange={handleChange}
                  variant="outlined"
                  name="startYear"
                  label="Year"
                />
              </Box>
            </Grid>
          </Grid>
          <Box height={10} />
          <Grid
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="button">to</Typography>
            <Grid display="flex" alignItems="center" columnGap="10px">
              <Box width={130} flexShrink="0">
                <SelectField
                  id="endMonth"
                  value={props.data.endMonth}
                  options={MONTHS}
                  onChange={handleChange}
                  variant="outlined"
                  name="endMonth"
                  label="Month"
                />
              </Box>
              <Box width={100} flexShrink="0">
                <SelectField
                  id="endYear"
                  value={props.data.endYear}
                  options={YEARS}
                  onChange={handleChange}
                  variant="outlined"
                  name="endYear"
                  label="Year"
                />
              </Box>
            </Grid>
          </Grid>
          <Box height={16} />
          <Box display="flex" justifyContent="end" columnGap="10px">
            <Button variant="general" onClick={handleReset}>
              Reset
            </Button>
            <Button variant="general" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
