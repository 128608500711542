import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { FavouriteIcon } from "app/assets/vectors/jsx/FavouriteIcon";
import { colors } from "app/theme";

const boxStyle = {
  width: "100%",
  height: "22px",
  display: "flex",
  fontSize: "12px",
  fontWeight: "700",
  borderRadius: "5px",
  alignItems: "center",
  justifyContent: "center",
  color: colors.primary.white,
};

export const IMPACT_COLUMNS: GridColDef[] = [
  {
    field: "favourite",
    headerName: "Favourite",
    width: 76,
    align: "center",
    renderCell: (params: any) => (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FavouriteIcon />
      </Box>
    ),
  },
  {
    field: "title",
    headerName: "Activity Title",
    width: 500,
    flex: 1,
  },
  {
    field: "indicator",
    headerName: "Indicator",
    width: 500,
    flex: 1,
  },
  {
    field: "baseline",
    headerName: "Baseline",
    width: 105,
    renderCell: (params: any) => (
      <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
        <Box sx={boxStyle} bgcolor={colors.charts[1]}>
          {params.value}
        </Box>
      </Box>
    ),
  },
  {
    field: "target",
    headerName: "Target",
    width: 105,
    renderCell: (params: any) => (
      <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
        <Box sx={boxStyle} bgcolor={colors.charts[9]}>
          {params.value}
        </Box>
      </Box>
    ),
  },
  {
    field: "actual",
    headerName: "Actual",
    width: 105,
    renderCell: (params: any) => (
      <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
        <Box sx={boxStyle} bgcolor={colors.charts[7]}>
          {params.value}
        </Box>
      </Box>
    ),
  },
];

export const TARGET_COLUMNS: GridColDef[] = [
  {
    field: "favourite",
    headerName: "Favourite",
    width: 76,
    align: "center",
    renderCell: (params: any) => (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FavouriteIcon />
      </Box>
    ),
  },
  {
    field: "title",
    headerName: "Activity Title",
    width: 500,
    flex: 1,
  },
  {
    field: "indicator",
    headerName: "Indicator",
    width: 500,
    flex: 1,
  },
  {
    field: "target",
    headerName: "Target",
    width: 105,
    renderCell: (params: any) => (
      <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
        <Box sx={boxStyle} bgcolor={colors.charts[9]}>
          {params.value}
        </Box>
      </Box>
    ),
  },
];
