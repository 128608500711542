import React from "react";

export const PayPalLogo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="119"
      height="30"
      viewBox="0 0 119 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_5419_8623)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.3832 7.37109H18.5148C22.8808 7.37109 24.5244 9.58137 24.2704 12.8286C23.8506 18.1895 20.6097 21.1554 16.3109 21.1554H14.1405C13.5507 21.1554 13.1539 21.5458 12.9944 22.6037L12.0729 28.7539C12.0121 29.1527 11.8022 29.3836 11.4873 29.4151H6.37829C5.89761 29.4151 5.72759 29.0477 5.85353 28.2522L8.96849 8.53606C9.09023 7.74682 9.52263 7.37109 10.3832 7.37109Z"
          fill="#009EE3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M45.6879 7.00586C48.4313 7.00586 50.9627 8.49407 50.6164 12.203C50.1966 16.611 47.8352 19.0501 44.1094 19.0606H40.8538C40.3858 19.0606 40.1591 19.4426 40.0373 20.2255L39.4076 24.2284C39.3132 24.8329 39.0025 25.1309 38.5449 25.1309H35.516C35.0332 25.1309 34.8653 24.8224 34.9724 24.1318L37.4723 8.08896C37.5962 7.29972 37.8921 7.00586 38.4316 7.00586H45.6816H45.6879ZM40.7552 15.5951H43.2215C44.7643 15.5363 45.7886 14.4679 45.8915 12.541C45.9545 11.3508 45.1505 10.4986 43.8722 10.5049L41.5507 10.5154L40.7552 15.5951ZM58.8509 23.903C59.128 23.6511 59.4092 23.521 59.3693 23.8316L59.2707 24.5747C59.2203 24.963 59.3735 25.1687 59.7346 25.1687H62.4255C62.8789 25.1687 63.0993 24.9861 63.2106 24.285L64.8688 13.8781C64.9527 13.3554 64.8247 13.0993 64.428 13.0993H61.4684C61.2018 13.0993 61.0716 13.2484 61.0024 13.6556L60.8932 14.2958C60.8366 14.6295 60.6833 14.6883 60.5406 14.3524C60.0389 13.1644 58.7585 12.6312 56.9722 12.6732C52.8225 12.7593 50.0245 15.9099 49.7243 19.9484C49.4934 23.0718 51.731 25.5255 54.6822 25.5255C56.8232 25.5255 57.7804 24.8958 58.8593 23.9093L58.8509 23.903ZM56.5965 22.3014C54.8103 22.3014 53.5655 20.8762 53.8237 19.1298C54.0819 17.3834 55.7527 15.9582 57.539 15.9582C59.3253 15.9582 60.57 17.3834 60.3118 19.1298C60.0536 20.8762 58.3849 22.3014 56.5965 22.3014ZM70.1352 13.0657H67.4065C66.844 13.0657 66.6152 13.4855 66.7936 14.0019L70.1814 23.9219L66.8587 28.6426C66.5795 29.0372 66.7957 29.3962 67.1882 29.3962H70.2549C70.4336 29.4168 70.6145 29.3857 70.776 29.3064C70.9375 29.2271 71.0729 29.1031 71.1659 28.9491L81.5854 14.004C81.9066 13.5443 81.7554 13.0615 81.2286 13.0615H78.3256C77.8282 13.0615 77.6288 13.2589 77.3433 13.6724L72.9983 19.9694L71.0567 13.6577C70.9434 13.2756 70.66 13.0657 70.1373 13.0657H70.1352Z"
          fill="#113984"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M92.4241 7.00616C95.1675 7.00616 97.6989 8.49437 97.3526 12.2033C96.9328 16.6113 94.5714 19.0504 90.8456 19.0609H87.5921C87.124 19.0609 86.8973 19.4429 86.7756 20.2258L86.1459 24.2286C86.0514 24.8332 85.7408 25.1312 85.2832 25.1312H82.2543C81.7715 25.1312 81.6036 24.8227 81.7107 24.1321L84.2148 8.08505C84.3386 7.29582 84.6346 7.00195 85.174 7.00195H92.4241V7.00616ZM87.4914 15.5954H89.9577C91.5005 15.5366 92.5248 14.4682 92.6277 12.5413C92.6907 11.3511 91.8867 10.4989 90.6084 10.5052L88.2869 10.5157L87.4914 15.5954ZM105.587 23.9033C105.864 23.6514 106.145 23.5213 106.106 23.8319L106.007 24.575C105.956 24.9633 106.11 25.169 106.471 25.169H109.162C109.615 25.169 109.835 24.9864 109.947 24.2853L111.605 13.8784C111.689 13.3557 111.561 13.0996 111.164 13.0996H108.209C107.942 13.0996 107.812 13.2487 107.743 13.6559L107.634 14.2961C107.577 14.6298 107.424 14.6886 107.281 14.3527C106.779 13.1647 105.499 12.6315 103.713 12.6735C99.5629 12.7596 96.7649 15.9102 96.4647 19.9487C96.2338 23.0721 98.4714 25.5258 101.423 25.5258C103.564 25.5258 104.521 24.8961 105.6 23.9096L105.587 23.9033ZM103.335 22.3017C101.549 22.3017 100.304 20.8765 100.562 19.1301C100.82 17.3837 102.491 15.9585 104.277 15.9585C106.064 15.9585 107.308 17.3837 107.05 19.1301C106.792 20.8765 105.121 22.3017 103.335 22.3017ZM115.746 25.1858H112.64C112.586 25.1882 112.532 25.1787 112.482 25.1578C112.432 25.137 112.387 25.1054 112.351 25.0653C112.315 25.0252 112.288 24.9776 112.272 24.9259C112.256 24.8741 112.252 24.8195 112.26 24.766L114.989 7.47844C115.015 7.36045 115.08 7.25475 115.174 7.17853C115.267 7.10231 115.384 7.06005 115.505 7.05863H118.612C118.666 7.05621 118.719 7.06576 118.769 7.08659C118.819 7.10741 118.864 7.13901 118.9 7.17911C118.937 7.21921 118.964 7.26684 118.979 7.31858C118.995 7.37032 118.999 7.42491 118.991 7.47844L116.263 24.766C116.237 24.8848 116.173 24.9914 116.079 25.0685C115.985 25.1455 115.868 25.1884 115.746 25.19V25.1858Z"
          fill="#009EE3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.29856 0.584961H13.4386C15.7307 0.584961 18.451 0.658427 20.2688 2.26418C21.4841 3.33678 22.1222 5.04329 21.9753 6.88203C21.4757 13.0972 17.7584 16.5795 12.7711 16.5795H8.75775C8.07347 16.5795 7.62218 17.0329 7.42907 18.2587L6.30819 25.3954C6.23472 25.8572 6.03532 26.1301 5.67848 26.1637H0.655519C0.099278 26.1637 -0.0980322 25.7439 0.0468005 24.8161L3.65712 1.94093C3.80195 1.02156 4.30782 0.584961 5.29856 0.584961Z"
          fill="#113984"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.5459 17.5347L8.96694 8.5362C9.09078 7.74697 9.52318 7.36914 10.3838 7.36914H18.5154C19.8609 7.36914 20.9503 7.57904 21.8025 7.96736C20.986 13.5004 17.4071 16.5734 12.7221 16.5734H8.71506C8.17771 16.5755 7.78309 16.8441 7.5459 17.5347Z"
          fill="#172C70"
        />
      </g>
      <defs>
        <clipPath id="clip0_5419_8623">
          <rect
            width="119"
            height="28.8301"
            fill="white"
            transform="translate(0 0.584961)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
