import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { AccountProfileCard } from "app/components/account-profile-card";
import { SelectField } from "app/components/select-field";
import { colors } from "app/theme";
import React from "react";

export const ChangeBillingInformationSetting = (props: {
  setActiveSetting?: (value: string) => void;
}) => {
  const [data, setData] = React.useState({
    name: "",
    address: "",
    city: "",
    zipCode: "",
    country: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  return (
    <AccountProfileCard
      title="Change Billing Information"
      content={
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "30px",
            flexDirection: "column",
            input: {
              width: "100%",
              height: "32px",
              fontSize: "12px",
              padding: "10px 20px",
              appearance: "none",
              "-webkit-appearance": "none",
              borderRadius: "5px",
              color: colors.text.title,
              background: colors.secondary.lightGrey,
              border: `1px solid ${colors.secondary.iconGray}`,
              ":focus": {
                outline: "none",
              },
            },
            label: {
              display: "block",
              lineHeight: "20px",
              marginBottom: "10px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "30px",
            }}
          >
            <Box sx={{ width: "300px" }}>
              <input
                type="text"
                placeholder="Jane Doe"
                name="name"
                id="name"
                value={data.name}
                onChange={handleChange}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <input
                type="text"
                placeholder="Billing Address Lorem Ipsum"
                name="startTime"
                id="start-time"
                value={data.address}
                onChange={handleChange}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "30px",
            }}
          >
            <Box sx={{ width: "200px" }}>
              <input
                type="text"
                placeholder="X City"
                name="city"
                id="city"
                value={data.city}
                onChange={handleChange}
              />
            </Box>

            <Box sx={{ width: "150px" }}>
              <input
                type="text"
                placeholder="YYYY Zip Code"
                name="zipCode"
                id="zipCode"
                value={data.zipCode}
                onChange={handleChange}
              />
            </Box>

            <SelectField
              id="country"
              value={data.country}
              onChange={handleChange}
              name="country"
              options={[]}
              width="500px"
              variant="outlined"
              height="32px"
              placeholder="Z Country"
              fontWeight="400"
            />
          </Box>
        </Box>
      }
      setActiveSetting={props.setActiveSetting}
      onBack={() => props.setActiveSetting?.("payment-details")}
    />
  );
};
