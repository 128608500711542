import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import * as echarts from "echarts/core";
import { useSessionStorage } from "react-use";
import Typography from "@mui/material/Typography";
import { CanvasRenderer } from "echarts/renderers";
import { formatLocale } from "app/utils/formatLocale";
import { ZoomWidget } from "app/components/zoom-widget";
import { GeomapChartProps } from "app/components/charts/geomap/data";
import { GeomapChartTooltip } from "app/components/charts/geomap/tooltip";
import { useChartResizeObserver } from "app/hooks/useChartResizeObserver";
import {
  VisualMapComponent,
  VisualMapComponentOption,
} from "echarts/components";
import {
  MapSeriesOption,
  MapChart as EChartsMap,
  ScatterChart,
  ScatterSeriesOption,
} from "echarts/charts";

echarts.use([EChartsMap, ScatterChart, CanvasRenderer, VisualMapComponent]);

export const GeomapChart: React.FC<GeomapChartProps> = (
  props: GeomapChartProps
) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [geoJson, setGeoJson] = React.useState<any>(null);
  const [isLocked, setIsLocked] = useSessionStorage<boolean>(
    "geomap-is-locked",
    false
  );
  const [tooltip, setTooltip] = useSessionStorage<{
    code: string;
    label: string;
    value: number;
    count: number;
  } | null>("geomap-tooltip", null);

  function calculateCentroid(coordinates: [number, number][]) {
    let x = 0,
      y = 0;
    let signedArea = 0;

    for (let i = 0; i < coordinates.length - 1; i++) {
      const [x0, y0] = coordinates[i];
      const [x1, y1] = coordinates[i + 1];

      const a = x0 * y1 - x1 * y0;
      signedArea += a;

      x += (x0 + x1) * a;
      y += (y0 + y1) * a;
    }

    signedArea *= 0.5;
    x /= 6 * signedArea;
    y /= 6 * signedArea;

    return [x, y];
  }

  const [stateChart, setStateChart] =
    React.useState<echarts.EChartsType | null>(null);

  useChartResizeObserver({
    chart: stateChart,
    containerRef: containerRef,
    containerId: "geomap-chart",
  });

  const mapColors = [
    colors.shades.blue[200],
    colors.shades.blue[400],
    colors.shades.blue[500],
    colors.shades.blue[600],
  ];

  React.useEffect(() => {
    if (!geoJson) {
      fetch("/static/world.geo.json")
        .then((res) => res.json())
        .then((data) => {
          setGeoJson({
            ...data,
            features: data.features.filter(
              (feature: any) => feature.id !== "ATA"
            ),
          });
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const [zoom, setZoom] = React.useState({
    zoom: props.zoom ?? 1,
    roam: props.roam ?? false,
  });

  React.useEffect(() => {
    if (containerRef.current && geoJson) {
      const chart = echarts.init(containerRef.current, undefined, {
        renderer: "canvas",
      });

      echarts.registerMap("World", geoJson);

      const getCenter = (name: string) => {
        const feature = geoJson.features.find(
          (f: any) => f.properties.name === name
        );

        if (feature) {
          const centroid = calculateCentroid(feature.geometry.coordinates[0]);
          return centroid;
        }
        return [0, 0];
      };

      const sizes = [0, ...props.data.map((d) => d.value)];

      const geoData = {
        map: "World",
        roam: zoom.roam,
        top: 0,
        left: 0,
        right: 0,
        bottom: 20,
        center: props.focusCountry ? getCenter(props.focusCountry) : undefined,
        zoom: zoom.zoom,
        itemStyle: {
          borderWidth: zoom.zoom > 5 ? 1.3 : 0.8,
          borderColor: colors.text.title,
        },
        emphasis: {
          label: {
            show: false,
          },
          itemStyle: {
            areaColor: "#cdd4df",
          },
        },
        select: {
          disabled: true,
        },
      };

      const option: echarts.ComposeOption<
        MapSeriesOption | VisualMapComponentOption | ScatterSeriesOption
      > = {
        visualMap: {
          left: "right",
          min: Math.min(...sizes),
          max: Math.max(...sizes),
          inRange: {
            color: mapColors,
          },
          text: ["High", "Low"],
          calculable: true,
          show: false,
        },
        geo: geoData,
        series: [
          {
            type: "map",
            data: props.data,
            ...geoData,
          },
          props.showScatter
            ? {
                type: "scatter",
                coordinateSystem: "geo",
                data: props.scatterData?.map((i) => {
                  return {
                    value: [i.lat, i.lng],
                    symbolSize: 5,
                    itemStyle: {
                      opacity: 1,
                      color: "#ED6060",
                    },
                  };
                }),
                symbolSize: 10,
                encode: {
                  value: 2,
                },
                itemStyle: {
                  color: "red", // Change color of the points
                },
              }
            : {},
        ],
      };

      chart.setOption(option);

      chart.on("click", (params: any) => {
        const locked = sessionStorage.getItem("geomap-is-locked");
        const gtooltip = JSON.parse(
          sessionStorage.getItem("geomap-tooltip")?.toString() ?? "{code: ''}"
        );

        if (params.data) {
          if (
            params.data.iso2 === gtooltip?.code &&
            locked &&
            locked === "true"
          ) {
            setIsLocked(false);
          } else {
            setTooltip({
              code: params.data?.iso2 ?? params.data?.name,
              label: params.data?.full_name ?? params.data?.name,
              value:
                params.componentSubType === "scatter"
                  ? params.data.newValue
                  : params.data.value,
              count: params.data?.count ?? 0,
            });
            setIsLocked(true);
          }
        } else {
          setIsLocked(false);
          if (tooltip) {
            setTooltip(null);
          }
        }
      });
      chart.on("mouseover", (params: any) => {
        const locked = sessionStorage.getItem("geomap-is-locked");
        if (params.data && (!locked || locked === "false")) {
          setTooltip({
            code: params.data?.iso2 ?? params.data?.name,
            label: params.data?.full_name ?? params.data?.name,
            value:
              params.componentSubType === "scatter"
                ? params.data.newValue
                : params.data.value,
            count: params.data?.count ?? 0,
          });
        }
      });
      chart.on("mouseout", () => {
        const locked = sessionStorage.getItem("geomap-is-locked");
        if (!locked || locked === "false") {
          setTooltip(null);
        }
      });

      setStateChart(chart);
    }
  }, [containerRef.current, props.data, geoJson, props.focusCountry, zoom]);

  return (
    <Box
      sx={{
        position: "relative",
        pointerEvents: !props.focusCountry ? "auto" : "none",
      }}
    >
      {props.showZoomWidget && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <ZoomWidget zoomProperties={zoom} setZoomProperties={setZoom} />
        </Box>
      )}
      <Box
        id="geomap-chart"
        ref={containerRef}
        width="100%"
        height={props.height ?? "450px"}
      />
      {props.showLegend && (
        <Box
          sx={{
            right: 0,
            bottom: 0,
            width: "350px",
            display: "flex",
            position: "absolute",
            flexDirection: "column",
            "@media (max-width: 768px)": {
              width: "80%",
            },
          }}
        >
          <Typography fontSize="12px">Total commitment in US$</Typography>
          <Box
            sx={{
              width: "100%",
              height: "14px",
              display: "flex",
              padding: "0 7px",
              flexDirection: "row",
              alignItems: "center",
              borderRadius: "14px",
              justifyContent: "space-between",
              border: `1px solid ${colors.primary.blue}`,
              background: `linear-gradient(90deg, ${mapColors[0]} 5.78%, ${
                mapColors[mapColors.length - 1]
              } 93.75%)`,
            }}
          >
            <Typography fontSize="12px">0</Typography>
            <Typography fontSize="12px" color={colors.primary.white}>
              {formatLocale(Math.max(...props.data.map((d) => d.value)))}
            </Typography>
          </Box>
        </Box>
      )}
      {props.showTooltip && tooltip && (
        <Box sx={{ left: 0, bottom: 0, position: "absolute" }}>
          <GeomapChartTooltip
            isLocked={isLocked}
            label={tooltip.label}
            value={tooltip.value}
            variant={props.variant}
            buttonText="Country Page"
            subLabel="Incoming Funds"
            activityCount={tooltip.count}
            buttonLink={`/explorer/locations/${tooltip.code}`}
            lockUnlock={() => {
              setTooltip(null);
              setIsLocked(!isLocked);
            }}
          />
        </Box>
      )}
    </Box>
  );
};
