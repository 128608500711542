import React from "react";

export const VisaLogo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="39"
      height="22"
      viewBox="0 0 39 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5888"
        y="1.05364"
        width="37.5534"
        height="19.8932"
        rx="1.92842"
        stroke="#A1AEBD"
        stroke-width="0.202991"
      />
      <g clip-path="url(#clip0_5419_8636)">
        <path
          d="M19.0344 9.12132C19.0185 10.3815 20.1575 11.0846 21.0155 11.5027C21.897 11.9317 22.1931 12.2068 22.1896 12.5904C22.183 13.1775 21.4864 13.4366 20.8346 13.4467C19.6973 13.4643 19.0361 13.1396 18.5104 12.8941L18.1007 14.8111C18.6281 15.0541 19.6047 15.2661 20.6175 15.2754C22.9947 15.2754 24.55 14.1019 24.5584 12.2825C24.5677 9.97342 21.3645 9.84561 21.3864 8.81351C21.394 8.50056 21.6926 8.16659 22.3469 8.08168C22.6708 8.03878 23.565 8.00595 24.5787 8.47276L24.9765 6.61801C24.4314 6.41951 23.7307 6.22944 22.8584 6.22944C20.6209 6.22944 19.0471 7.41889 19.0344 9.12132ZM28.7996 6.3892C28.3655 6.3892 27.9997 6.64241 27.8365 7.03098L24.4407 15.1392H26.8162L27.2889 13.8327H30.1917L30.466 15.1392H32.5597L30.7326 6.3892H28.7996ZM29.132 8.75289L29.8175 12.0386H27.94L29.132 8.75289ZM16.1542 6.38931L14.2818 15.139H16.5454L18.4171 6.38909H16.1542V6.38931ZM12.8055 6.38909L10.4494 12.3448L9.49628 7.2809C9.38445 6.71561 8.94281 6.3892 8.45237 6.3892H4.60082L4.54688 6.64328C5.33758 6.81486 6.23596 7.0916 6.78024 7.3877C7.11333 7.56858 7.20831 7.7267 7.31774 8.15652L9.12292 15.1392H11.5151L15.1826 6.3892H12.8055"
          fill="url(#paint0_linear_5419_8636)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5419_8636"
          x1="17.4255"
          y1="15.4569"
          x2="17.6873"
          y2="6.16653"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#222357" />
          <stop offset="1" stop-color="#254AA5" />
        </linearGradient>
        <clipPath id="clip0_5419_8636">
          <rect
            width="28.0128"
            height="9.13462"
            fill="white"
            transform="translate(4.54688 6.22949)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
