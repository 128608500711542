import { Box, Button, SxProps } from "@mui/material";
import { MinusIcon } from "app/assets/vectors/jsx/MinusIcon";
import { PanIcon } from "app/assets/vectors/jsx/PanIcon";
import { PlusIcon } from "app/assets/vectors/jsx/PlusIcon";
import { ResetIcon } from "app/assets/vectors/jsx/ResetIcon";
import { colors } from "app/theme";
import React from "react";

interface ZoomProperties {
  zoom: number;
  roam: boolean;
}

interface ZoomWidgetProps {
  zoomProperties: ZoomProperties;
  setZoomProperties: (zoomProperties: ZoomProperties) => void;
  defaultZoom?: number;
}

export const ZoomWidget = (props: ZoomWidgetProps) => {
  const ButtonStyle: SxProps = {
    padding: "0",
    boxShadow:
      "0px 0.807px 1.613px 0px rgba(97, 97, 97, 0.20), 0px 1.613px 3.226px 0px rgba(97, 97, 97, 0.20)",
    background: colors.primary.white,
    width: "24.53px",
    minWidth: "24.53px",
    height: "24.53px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0",
  };

  const zoomIn = () => {
    props.setZoomProperties({
      ...props.zoomProperties,
      zoom: props.zoomProperties.zoom + 0.5,
    });
  };

  const zoomOut = () => {
    props.setZoomProperties({
      ...props.zoomProperties,
      zoom: props.zoomProperties.zoom - 0.5,
    });
  };

  const reset = () => {
    props.setZoomProperties({
      roam: false,
      zoom: props.defaultZoom ?? 1,
    });
  };

  const roam = () => {
    props.setZoomProperties({
      ...props.zoomProperties,
      roam: !props.zoomProperties.roam,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        columnGap: "11.15px",
      }}
    >
      <Button sx={ButtonStyle} onClick={zoomIn}>
        <PlusIcon fill={colors.text.body} width={18} height={18} />
      </Button>
      <Button sx={ButtonStyle} onClick={zoomOut}>
        <MinusIcon fill={colors.text.body} width={18} />
      </Button>
      <Button
        sx={{
          ...ButtonStyle,
          background: props.zoomProperties?.roam
            ? colors.secondary.blue
            : "white",
        }}
        onClick={roam}
      >
        <PanIcon width={18} />
      </Button>
      <Button sx={ButtonStyle} onClick={reset}>
        <ResetIcon width={18} />
      </Button>
    </Box>
  );
};
