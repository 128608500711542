import React from "react";

export const DownloadIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M11.3109 16.54L7.58086 12.82C7.42269 12.6624 7.33379 12.4483 7.33379 12.225C7.33379 12.0017 7.42269 11.7876 7.58086 11.63C7.91349 11.3113 8.43822 11.3113 8.77086 11.63L11.0609 13.93V3.84C11.0609 3.37608 11.4369 3 11.9009 3C12.3648 3 12.7409 3.37608 12.7409 3.84V13.93L15.0309 11.63C15.3635 11.3113 15.8882 11.3113 16.2209 11.63C16.379 11.7876 16.4679 12.0017 16.4679 12.225C16.4679 12.4483 16.379 12.6624 16.2209 12.82L12.4909 16.54C12.3369 16.7009 12.1235 16.7913 11.9009 16.79C11.6777 16.7937 11.4634 16.7029 11.3109 16.54Z"
        fill="black"
      />
      <path
        d="M17.1209 8.42H18.3309C20.8471 8.47468 22.8455 10.5536 22.8009 13.07V16.88C22.8455 19.3964 20.8471 21.4753 18.3309 21.53L5.47086 21.45C2.95462 21.3953 0.956211 19.3164 1.00086 16.8V12.99C0.971355 11.7762 1.4261 10.6005 2.26474 9.72245C3.10338 8.84442 4.25696 8.33621 5.47086 8.31H6.68086C7.14478 8.31 7.52086 8.68608 7.52086 9.15C7.52086 9.61392 7.14478 9.99 6.68086 9.99H5.47086C4.70152 10.0134 3.97354 10.3437 3.44936 10.9074C2.92519 11.471 2.64844 12.221 2.68086 12.99V16.77C2.64844 17.539 2.92519 18.289 3.44936 18.8526C3.97354 19.4163 4.70152 19.7466 5.47086 19.77L18.3309 19.91C19.1002 19.8866 19.8282 19.5563 20.3524 18.9926C20.8765 18.429 21.1533 17.679 21.1209 16.91V13.1C21.1533 12.331 20.8765 11.581 20.3524 11.0174C19.8282 10.4537 19.1002 10.1234 18.3309 10.1H17.1209C16.6569 10.1 16.2809 9.72392 16.2809 9.26C16.2809 8.79608 16.6569 8.42 17.1209 8.42Z"
        fill="black"
      />
    </svg>
  );
};
