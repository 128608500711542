import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { SelectChangeEvent } from "@mui/material";
import Typography from "@mui/material/Typography";
import { formatLocaleN } from "app/utils/formatLocale";
import { SearchInput } from "app/components/search-input";
import { SelectField } from "app/components/select-field";
import { PlusIcon } from "app/assets/vectors/jsx/PlusIcon";
import { ResetIcon } from "app/assets/vectors/jsx/ResetIcon";
import { MinusIcon } from "app/assets/vectors/jsx/MinusIcon";
import { useStoreState } from "app/state/store/hooks";
import get from "lodash/get";

const ResetButtonStyle = {
  background: colors.shades.blue[100],
  padding: "6px 12px",
  lineHeight: "20px",
  fontWeight: 400,
  color: colors.text.body,
  borderRadius: "5px",
  fontSize: "14px",
};

const inputStyle = {
  border: `1px solid ${colors.primary.blue}`,
  borderRadius: "5px",
  background: colors.secondary.lightGrey,
  fontWeight: "12px",
  color: colors.text.title,
  height: "40px",
  padding: "0px 20px",
  outline: "none",
  width: "100%",
};

interface FilterBlockProps {
  count: number;
  search: string;
  searchBtnEnabled: boolean;
  onSearchBtnClick: () => void;
  setSearch: (search: string) => void;
  selectedFilters: {
    location: string[];
    organisation: string[];
    sector: string[];
    year1: string;
    year2: string;
    activityStatus: string[];
    budgetMin: string;
    budgetMax: string;
  };
  resetFilters: () => void;
  onFilterChange: (
    value: string | string[],
    type: string,
    action: "add" | "remove"
  ) => void;
}

export const FilterBlock: React.FC<FilterBlockProps> = (
  props: FilterBlockProps
) => {
  const [filtersOpen, setFiltersOpen] = React.useState(false);

  const locationFilterOptions = useStoreState((state) =>
    get(state.ExplorerLocationFilterOptions, "data.data.locations", [])
  );
  const publisherFilterOptions = useStoreState((state) =>
    get(state.ExplorerPublisherFilterOptions, "data.data.publishers", [])
  );
  const sectorsFilterOptions = useStoreState((state) =>
    get(state.ExplorerSectorsFilterOptions, "data.data.sectors", [])
  );
  const activityStatusFilterOptions = useStoreState((state) =>
    get(state.ExplorerActivityStatusFilterOptions, "data.data.statuses", [])
  );
  const yearFilterOptions = useStoreState((state) =>
    get(state.ExplorerYearFilterOptions, "data.data.years", [])
  );

  const handleChange = (e: SelectChangeEvent) => {
    props.onFilterChange(e.target.value, e.target.name, "add");
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setSearch(e.target.value);
  };

  const handleSearchInputKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      props.onSearchBtnClick();
    }
  };

  const resetFiltersBtnDisabled = React.useMemo(() => {
    return !Object.values(props.selectedFilters).some(
      (filter) => filter.length > 0
    );
  }, [props.selectedFilters]);

  const appliedFiltersCount = React.useMemo(() => {
    let count = 0;
    Object.values(props.selectedFilters).forEach(
      (filter) => (count += filter.length)
    );
    return count > 0 ? `${count} filter${count > 1 ? "s" : ""} applied` : null;
  }, [props.selectedFilters]);

  return (
    <Box
      sx={{
        padding: "20px 30px",
        background: colors.primary.white,
      }}
    >
      <Grid display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle2" fontSize="18px" lineHeight="24px">
          Search through {formatLocaleN(props.count)} activities.
        </Typography>
        <Grid display="flex" alignItems="flex-start" columnGap="20px">
          <Box sx={ResetButtonStyle}>
            {appliedFiltersCount ?? "No filters added yet"}
          </Box>
          <Button
            sx={{
              ...ResetButtonStyle,
              "&:hover": {
                background: colors.shades.blue[200],
              },
            }}
            onClick={props.resetFilters}
            disabled={resetFiltersBtnDisabled}
            endIcon={
              <ResetIcon
                style={{ opacity: resetFiltersBtnDisabled ? "0.26" : "1" }}
              />
            }
          >
            Reset Filters
          </Button>
        </Grid>
      </Grid>
      <Box height={20} />
      <SearchInput
        showBorder
        placeholder="Search"
        value={props.search}
        style={{ width: "100%" }}
        onChange={handleSearchInputChange}
        onKeyDown={handleSearchInputKeyDown}
      />
      <Box height={40} />
      <Grid display="flex" alignItems="center" columnGap="20px">
        <Divider
          sx={{
            flex: 1,
            borderBottom: `0.5px solid ${colors.secondary.iconGray}`,
          }}
        />
        <Button
          variant="contained"
          sx={{
            background: filtersOpen ? colors.shades.blue[400] : "auto",
          }}
          endIcon={filtersOpen ? <MinusIcon /> : <PlusIcon />}
          onClick={() => setFiltersOpen(!filtersOpen)}
        >
          Add Filters
        </Button>
        <Divider
          sx={{
            flex: 1,
            borderBottom: `0.5px solid ${colors.secondary.iconGray}`,
          }}
        />
      </Grid>
      <Grid
        sx={{
          maxHeight: filtersOpen ? "250px" : 0,
          overflow: "hidden",
          margin: filtersOpen ? "40px 0" : "10px 0",
          transition: "all 0.3s ease-in-out",
          gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
          display: "grid",
          gap: "20px",
        }}
      >
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" marginBottom="10px">
            Location
          </Typography>
          <SelectField
            multiple
            variant="outlined"
            id="location"
            name="location"
            value={props.selectedFilters.location}
            onChange={handleChange}
            fontWeight="400"
            height="40px"
            placeholder="Select one or multiple options"
            options={locationFilterOptions}
            borderColor={colors.primary.blue}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" marginBottom="10px">
            Organisations
          </Typography>
          <SelectField
            multiple
            variant="outlined"
            id="organisations"
            name="organisations"
            value={props.selectedFilters.organisation}
            onChange={handleChange}
            fontWeight="400"
            height="40px"
            placeholder="Select one or multiple options"
            options={publisherFilterOptions}
            borderColor={colors.primary.blue}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" marginBottom="10px">
            Sectors
          </Typography>
          <SelectField
            multiple
            variant="outlined"
            id="sectors"
            name="sectors"
            value={props.selectedFilters.sector}
            onChange={handleChange}
            height="40px"
            placeholder="Select one or multiple options"
            fontWeight="400"
            options={sectorsFilterOptions}
            borderColor={colors.primary.blue}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" marginBottom="10px">
            Year Range
          </Typography>
          <Grid display="flex" columnGap="10px">
            <SelectField
              variant="outlined"
              id="year1"
              name="year1"
              value={props.selectedFilters.year1}
              onChange={handleChange}
              height="40px"
              placeholder="Select an option"
              fontWeight="400"
              options={yearFilterOptions}
              borderColor={colors.primary.blue}
            />
            <SelectField
              variant="outlined"
              id="year2"
              name="year2"
              value={props.selectedFilters.year2}
              onChange={handleChange}
              height="40px"
              placeholder="Select an option"
              fontWeight="400"
              options={yearFilterOptions}
              borderColor={colors.primary.blue}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" marginBottom="10px">
            Activity Status
          </Typography>
          <SelectField
            width="95%"
            multiple
            variant="outlined"
            id="activity-status"
            name="activity-status"
            value={props.selectedFilters.sector}
            onChange={handleChange}
            height="40px"
            placeholder="Select one or multiple options"
            fontWeight="400"
            options={activityStatusFilterOptions}
            borderColor={colors.primary.blue}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" marginBottom="10px">
            Total Budget
          </Typography>
          <Grid
            display="flex"
            columnGap="10px"
            sx={{
              "> input": inputStyle,
              width: "100%",
            }}
          >
            <input
              name="budgetMin"
              placeholder="Minimum"
              onChange={handleChange}
              value={props.selectedFilters.budgetMin}
            />
            <input
              name="budgetMax"
              placeholder="Maximum"
              onChange={handleChange}
              value={props.selectedFilters.budgetMax}
            />
          </Grid>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        onClick={props.onSearchBtnClick}
        disabled={!props.searchBtnEnabled}
        sx={{
          margin: "0 auto",
          display: "block",
        }}
      >
        Search
      </Button>
    </Box>
  );
};
