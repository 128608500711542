export interface TransactionType {
  code: number;
  value: string;
  label: string;
}

export const TRANSACTION_TYPES: TransactionType[] = [
  { value: "incoming_funds", label: "Incoming Funds", code: 1 },
  { value: "outgoing_commitment", label: "Outgoing Commitment", code: 2 },
  { value: "disbursement", label: "Disbursement", code: 3 },
  { value: "expenditure", label: "Expenditure", code: 4 },
  { value: "interest_payment", label: "Interest Payment", code: 5 },
  { value: "loan_payment", label: "Loan Payment", code: 6 },
  { value: "reimbursement", label: "Reimbursement", code: 7 },
  { value: "purchase_of_equity", label: "Purchase of Equity", code: 8 },
  { value: "sale_of_equity", label: "Sale of Equity", code: 9 },
  { value: "credit_guarantee", label: "Credit Guarantee", code: 10 },
  { value: "incoming_commitment", label: "Incoming Commitment", code: 11 },
  { value: "outgoing_pledge", label: "Outgoing Pledge", code: 12 },
  { value: "incoming_pledge", label: "Incoming Pledge", code: 13 },
];

export interface TransactionTypeProps {
  value: TransactionType;
  setValue: (value: TransactionType) => void;
}
