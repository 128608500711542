import React from "react";
import get from "lodash/get";
import Box from "@mui/material/Box";
import { Table } from "app/components/table";
import Typography from "@mui/material/Typography";
import { SelectChangeEvent } from "@mui/material";
import { useStoreState } from "app/state/store/hooks";
import { LineChart } from "app/components/charts/line";
import { SelectField } from "app/components/select-field";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { TRANSACTION_COLUMNS } from "app/pages/explorer/pages/activities/pages/activity/tableData";

export const TransactionsBlock: React.FC = () => {
  const [view, setView] = React.useState("table");

  const transactionsTable = useStoreState((state) =>
    get(state.ExplorerActivityPageTransactions, "data.data", [])
  );
  const transactionsLineChart = useStoreState((state) =>
    get(state.ExplorerActivityPageTransactionsLineChart, "data.data", [])
  );

  const handleChange = (event: SelectChangeEvent) => {
    setView(event.target.value);
  };

  return (
    <GenericPageBlockItem
      toolbarShare
      description=""
      title="Transactions"
      content={
        <React.Fragment>
          <SelectField
            value={view}
            variant="filled"
            width="106px"
            onChange={handleChange}
            id="transactions-view-select"
            options={[
              { label: "Table View", value: "table" },
              { label: "Chart View", value: "chart" },
            ]}
          />
          <Box sx={{ height: "20px" }} />
          {view === "table" ? (
            <Table
              headerFontSize="12px"
              getRowHeight={() => 31}
              rows={transactionsTable}
              columns={TRANSACTION_COLUMNS}
              autoHeight={transactionsTable.length < 20}
            />
          ) : (
            <Box>
              <Typography variant="h6" fontSize="12px">
                Transaction Types
              </Typography>
              <LineChart
                showLegend
                step="end"
                height="470px"
                symbolSize={10}
                xAxisType="time"
                lineThickness={1}
                yAxisLabelName="USD"
                id="transaction-line"
                data={{
                  xAxisValues: [],
                  series: transactionsLineChart,
                }}
              />
            </Box>
          )}
        </React.Fragment>
      }
    />
  );
};
