import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { CircleFlag } from "react-circle-flags";
import Typography from "@mui/material/Typography";
import ArrowOutward from "@mui/icons-material/ArrowOutward";
import { PageHeaderProps } from "app/components/page-header/data";

const Container = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PageHeader: React.FC<PageHeaderProps> = (
  props: PageHeaderProps
) => {
  const icon = React.useMemo(() => {
    if (props.locationISO2) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <CircleFlag
            width="60"
            height="60"
            countryCode={props.locationISO2.toLowerCase()}
          />
        </Box>
      );
    }
    if (props.icon) {
      return props.icon;
    }
    return null;
  }, [props.icon, props.locationISO2]);

  const title = React.useMemo(() => {
    return (
      <Typography
        margin={0}
        variant="h1"
        fontSize="40px"
        letterSpacing="-1.5px"
        color={colors.primary.white}
        sx={{
          "line-clamp": "2",
          overflow: "hidden",
          display: "-webkit-box",
          "-webkit-line-clamp": "2",
          "-webkit-box-orient": "vertical",
        }}
      >
        {props.title}
      </Typography>
    );
  }, [props.title]);

  const subtitle = React.useMemo(() => {
    if (!props.subtitle) return null;
    return (
      <Typography
        margin={0}
        variant="h5"
        fontSize="14px"
        color={colors.primary.white}
      >
        {props.subtitle}
      </Typography>
    );
  }, [props.subtitle]);

  const content = React.useMemo(() => {
    return (
      <React.Fragment>
        <Container>
          {icon}
          <Box
            sx={{
              gap: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              maxWidth: "calc(100% - 120px)",
            }}
          >
            {title}
            {subtitle}
          </Box>
        </Container>
        <Button
          variant="outlined"
          color="primary"
          endIcon={<ArrowOutward fontSize="small" />}
          sx={{
            fontSize: "14px",
            fontWeight: "700",
            textWrap: "nowrap",
            borderStyle: "none",
            height: "fit-content",
            color: colors.primary.white,
            ":hover": {
              borderStyle: "none",
            },
            ".MuiButton-icon": {
              borderRadius: "50%",
              background:
                props.variant === "explorer"
                  ? "#638ADC"
                  : colors.shades.green[200],
            },
          }}
        >
          Switch to {props.variant === "explorer" ? "Publisher" : "Explorer"}
        </Button>
      </React.Fragment>
    );
  }, [icon, title, subtitle, props.variant]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        padding: "20px",
        height: "130px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        background:
          props.variant === "explorer"
            ? colors.primary.blue
            : colors.primary.green,
      }}
    >
      {content}
    </Box>
  );
};
