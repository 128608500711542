import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import cloneDeep from "lodash/cloneDeep";
import { Table } from "app/components/table";
import { SelectChangeEvent } from "@mui/material";
import { formatLocaleN } from "app/utils/formatLocale";
import { PageHeader } from "app/components/page-header";
import { useNavigate, useParams } from "react-router-dom";
import { SelectField } from "app/components/select-field";
import { ShareButton } from "app/components/share-button";
import { InsightsBlock } from "./components/insights-block";
import { DownloadButton } from "app/components/download-button";
import { YearSelectButton } from "app/components/year-select-button";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { TransactionTypeSelect } from "app/pages/explorer/components/transaction-type";
import { TRANSACTION_TYPES } from "app/pages/explorer/components/transaction-type/data";
import { ACTIVITIES_COLUMNS } from "app/pages/home/explorer/components/live-data-updates/tableColumns";
import { getDatasetColumns } from "app/pages/explorer/pages/organisations/pages/organisation/tableData";
import { ReactComponent as ExplorerOrganisationsHeaderIcon } from "app/assets/vectors/ExplorerOrganisationsHeaderIcon.svg";
import { DetailPanelContent } from "app/pages/explorer/pages/organisations/pages/organisation/components/detail-panel-content";

const box = <Box height={30} />;

export const ExplorerOrganisation: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchOverviewStats = useStoreActions(
    (actions) => actions.ExplorerOrganisationPageOverviewStats.fetch
  );
  const fetchLocations = useStoreActions(
    (actions) => actions.ExplorerOrganisationPageLocations.fetch
  );
  const fetchSDGs = useStoreActions(
    (actions) => actions.ExplorerOrganisationPageSDGs.fetch
  );
  const fetchOrganisationsNetwork = useStoreActions(
    (actions) => actions.ExplorerOrganisationOrganisationsNetwork.fetch
  );
  const fetchSectors = useStoreActions(
    (actions) => actions.ExplorerOrganisationSectors.fetch
  );
  const fetchImpact = useStoreActions(
    (actions) => actions.ExplorerOrganisationPageImpactResults.fetch
  );
  const fetchActivityTable = useStoreActions(
    (actions) => actions.ExplorerOrganisationPageActivityTable.fetch
  );
  const fetchDatasetTable = useStoreActions(
    (actions) => actions.ExplorerOrganisationPageDatasetTable.fetch
  );

  const orgName = useStoreState((state) =>
    get(state.ExplorerOrganisationPageOverviewStats, "data.data.name", "")
  );
  const organisationFilterOptions = useStoreState((state) =>
    get(state.ExplorerPublisherFilterOptions, "data.data.publishers", [])
  );
  const activities = useStoreState((state) =>
    get(state.ExplorerOrganisationPageActivityTable, "data.data", [])
  );
  const activitiesCount = useStoreState((state) =>
    get(state.ExplorerOrganisationPageActivityTable, "data.count", 0)
  );
  const activitiesLoading = useStoreState(
    (state) => state.ExplorerOrganisationPageActivityTable.loading
  );
  const datasets = useStoreState((state) =>
    get(state.ExplorerOrganisationPageDatasetTable, "data.data", [])
  );
  const datasetsLoading = useStoreState(
    (state) => state.ExplorerOrganisationPageDatasetTable.loading
  );

  const [activitiesPage, setActivitiesPage] = React.useState(1);
  const [transactionType, setTransactionType] = React.useState(
    TRANSACTION_TYPES[0]
  );
  const [selectedPeriod, setSelectedPeriod] = React.useState({
    startYear: "2023",
    endYear: "2023",
    startMonth: "1",
    endMonth: "12",
  });
  const [expandedBlock, setExpandedBlock] = React.useState<
    "locations" | "results" | "sdgs" | "networks" | "sectors" | "impact" | null
  >(null);
  const [expandedRowIds, setExpandedRowIds] = React.useState<string[]>([]);

  const handleSetExpandedBlock = (block: typeof expandedBlock) => {
    setExpandedBlock((prev) => (prev === block ? null : block));
  };

  const handleExpandRow = (rowId: string, field: string) => {
    const newExpandedRowIds = cloneDeep(expandedRowIds);
    const index = newExpandedRowIds.indexOf(`${rowId}.${field}`);
    if (index === -1) {
      newExpandedRowIds.push(`${rowId}.${field}`);
    } else {
      newExpandedRowIds.splice(index, 1);
    }
    setExpandedRowIds(newExpandedRowIds);
  };

  const detailPanelContent = React.useCallback(
    (row: any) => {
      return <DetailPanelContent row={row} expandedRowIds={expandedRowIds} />;
    },
    [expandedRowIds]
  );

  const handleOrganisationChange = (e: SelectChangeEvent) => {
    navigate(`/explorer/organisations/${e.target.value}`);
  };

  const onLoadMoreActivities = () => {
    if (activitiesLoading) return;
    setActivitiesPage((prev) => {
      fetchActivityTable({
        addOnData: true,
        filterString: `publisher=${id}&period=${periodRouteParam}`,
        routeParams: {
          page: (prev + 1).toString(),
        },
      });
      return prev + 1;
    });
  };

  const periodLabel = React.useMemo(() => {
    if (selectedPeriod.startYear === selectedPeriod.endYear) {
      return selectedPeriod.startYear;
    }
    if (selectedPeriod.startMonth === "1" && selectedPeriod.endMonth === "12") {
      return `${selectedPeriod.startYear} - ${selectedPeriod.endYear}`;
    }
    return `${selectedPeriod.startMonth.length === 1 ? "0" : ""}${
      selectedPeriod.startMonth
    }-${selectedPeriod.startYear} - ${
      selectedPeriod.endMonth.length === 1 ? "0" : ""
    }${selectedPeriod.endMonth}-${selectedPeriod.endYear}`;
  }, [selectedPeriod]);

  const periodRouteParam = React.useMemo(() => {
    return `${selectedPeriod.startYear}-${
      selectedPeriod.startMonth.length < 2 ? "0" : ""
    }${selectedPeriod.startMonth}|${selectedPeriod.endYear}-${
      selectedPeriod.endMonth.length < 2 ? "0" : ""
    }${selectedPeriod.endMonth}`;
  }, [selectedPeriod]);

  React.useEffect(() => {
    if (id) {
      fetchOverviewStats({
        routeParams: {
          code: id,
          period: periodRouteParam,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
      fetchLocations({
        filterString: `publisher=${id}`,
        routeParams: {
          period: periodRouteParam,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
      fetchSectors({
        filterString: `publisher=${id}`,
        routeParams: {
          period: periodRouteParam,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
    }
  }, [id, periodRouteParam, transactionType]);

  React.useEffect(() => {
    if (id) {
      let sdgsFetchPeriod = periodRouteParam;
      if (
        selectedPeriod.startYear === selectedPeriod.endYear &&
        selectedPeriod.startMonth === "1" &&
        selectedPeriod.endMonth === "12"
      ) {
        sdgsFetchPeriod = selectedPeriod.startYear;
      }
      fetchSDGs({
        filterString: `publisher=${id}`,
        routeParams: {
          year: sdgsFetchPeriod,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
    }
  }, [id, selectedPeriod, transactionType]);

  React.useEffect(() => {
    if (id) {
      fetchOrganisationsNetwork({
        filterString: `publisher=${id}`,
        routeParams: {
          period: periodRouteParam,
        },
      });
      fetchImpact({
        filterString: `publisher=${id}`,
        routeParams: {
          period: periodRouteParam,
        },
      });
      fetchActivityTable({
        filterString: `publisher=${id}&period=${periodRouteParam}`,
        routeParams: {
          page: "1",
        },
      });
    }
  }, [id, periodRouteParam]);

  React.useEffect(() => {
    if (id) {
      fetchDatasetTable({
        routeParams: {
          code: id,
        },
      });
    }
  }, [id]);

  return (
    <Box>
      <PageHeader
        title={orgName}
        variant="explorer"
        subtitle={`Showcasing organisation-specific data of ${orgName}.`}
        icon={<ExplorerOrganisationsHeaderIcon />}
      />
      {box}
      <Grid display="flex" alignItems="center" justifyContent="space-between">
        <SelectField
          id="organisation-select"
          options={organisationFilterOptions}
          placeholder="Select Another Organisation"
          value={id ?? ""}
          onChange={handleOrganisationChange}
          variant="outlined"
          width="300px"
        />
        <Grid display="flex" alignItems="center" columnGap="8px">
          <TransactionTypeSelect
            value={transactionType}
            setValue={setTransactionType}
          />
          <YearSelectButton data={selectedPeriod} setData={setSelectedPeriod} />
          <ShareButton id="share-button" />
          <DownloadButton id="download-button" />
        </Grid>
      </Grid>
      {box}
      <InsightsBlock
        period={periodLabel}
        organisation={orgName}
        expandedBlock={expandedBlock}
        transactionType={transactionType}
        loadMoreActivities={onLoadMoreActivities}
        setExpandedBlock={handleSetExpandedBlock}
      />
      {box}
      <GenericPageBlockItem
        title="Recent Activities"
        loading={activitiesLoading}
        description={
          <React.Fragment>
            Currently{" "}
            <span style={{ color: colors.primary.blue }}>
              {formatLocaleN(activitiesCount)} activities{" "}
            </span>
            taking place for {orgName} in {periodLabel}.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Table
              withSearch
              withSortButton
              rows={activities}
              autoHeight={false}
              headerFontSize="12px"
              columns={ACTIVITIES_COLUMNS}
              loadMore={onLoadMoreActivities}
              hasMore={activitiesCount > activities.length}
            />
          </React.Fragment>
        }
        toolbarShare
      />
      {box}
      <GenericPageBlockItem
        title="IATI CLOUD DATA / ORGANISATION DATASETS"
        loading={datasetsLoading}
        description={
          <React.Fragment>
            Currently{" "}
            <span style={{ color: colors.primary.blue }}>
              {datasets.length} datasets
            </span>{" "}
            found for {orgName} in the IATI registry.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Table
              autoHeight={false}
              withSearch
              withSortButton
              withCollapseButton
              rows={datasets}
              columns={getDatasetColumns(handleExpandRow, expandedRowIds)}
              headerFontSize="12px"
              getDetailPanelContent={detailPanelContent}
            />
          </React.Fragment>
        }
        toolbarShare
      />
    </Box>
  );
};
