import get from "lodash/get";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { CircleFlag } from "react-circle-flags";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { GenericTableColumn } from "app/components/generic-table/data";
import { ReactComponent as ListIcon } from "app/assets/vectors/GenericListIcon.svg";
import { ReactComponent as ArrowRight } from "app/assets/vectors/TableArrowRight.svg";

const ArrowRightIconButtonCell = (params: { value: string; type: string }) => {
  const navigate = useNavigate();

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <IconButton
        onClick={() => navigate(`/explorer/${params.type}/${params.value}`)}
      >
        <ArrowRight />
      </IconButton>
    </Box>
  );
};

export const ACTIVITY_STATUS_COLORS = {
  "Pipeline/Identification": "#333ED4",
  Finalisation: "#30A235",
  Implementation: "#A0D736",
  Suspended: " #EEDE03",
  Closed: "#FD0200",
  Cancelled: "#F76915",
};

export const ACTIVITY_COLUMNS: GenericTableColumn[] = [
  {
    field: "name",
    headerName: "Activity Name",
    width: 675,
  },
  {
    width: 150,
    field: "status",
    align: "center",
    headerName: "Status",
    renderCell: (params: any) => (
      <Grid display={"flex"} alignItems={"center"} columnGap={"6px"}>
        <Box
          sx={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            background: get(ACTIVITY_STATUS_COLORS, params.value, "#333ED4"),
          }}
        />
        <Typography variant="body2" fontSize={"12px"}>
          {params.value}
        </Typography>
      </Grid>
    ),
    headerStyle: { justifyContent: "center" },
  },
  {
    width: 150,
    align: "center",
    field: "location",
    headerName: "Location(s)",
    headerStyle: { justifyContent: "center" },
    renderCell: (params: any) => {
      const multiValues = params.value.split(",");

      if (multiValues.length === 1)
        return (
          <Typography fontSize="12px" fontWeight={400} variant="body2">
            {params.value}
          </Typography>
        );

      return (
        <Tooltip
          title={
            <Box gap="5px" display="flex" flexDirection="column">
              {multiValues.map((location: any) => (
                <Typography fontSize="12px" fontWeight={400} variant="body2">
                  {location}
                </Typography>
              ))}
            </Box>
          }
        >
          <Typography fontSize="12px" fontWeight={400} variant="body2">
            {params.value.split(",")[0]}, +{multiValues.length - 1} more
          </Typography>
        </Tooltip>
      );
    },
  },
];

export const LOCATION_COLUMNS: GenericTableColumn[] = [
  {
    field: "code",
    headerName: "Code",
    width: 50,
  },
  {
    field: "location",
    headerName: "Location",
    width: 200,
    align: "left",
    headerAlign: "left",
    renderCell: (params: any) => (
      <Box display={"flex"} alignItems={"center"} columnGap={"8px"}>
        <CircleFlag
          width={20}
          height={20}
          countryCode={params.row.code.toLowerCase()}
        />
        <Typography variant="body2" fontSize={"12px"}>
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "relatedContent",
    headerName: "Related Content",
    width: 650,
    renderCell: (params: any) => (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
          <ListIcon />
          <Typography variant="body2" fontSize={"12px"}>
            {params.value}
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    field: "code",
    headerName: "",
    width: 50,
    renderCell: (params: any) => (
      <ArrowRightIconButtonCell {...params} type="locations" />
    ),
  },
];

export const ORGANISATION_COLUMNS: GenericTableColumn[] = [
  {
    field: "iatiId",
    headerName: "IATI ID",
    width: 120,
  },
  {
    field: "organisation",
    headerName: "Organisation",
    width: 500,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "relatedContent",
    headerName: "Related Content",
    width: 250,
    renderCell: (params: any) => (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
          <ListIcon />
          <Typography variant="body2" fontSize={"12px"}>
            {params.value}
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    field: "code",
    headerName: "",
    width: 50,
    renderCell: (params: any) => (
      <ArrowRightIconButtonCell {...params} type="organisations" />
    ),
  },
];

export const SECTOR_COLUMNS: GenericTableColumn[] = [
  {
    field: "code",
    headerName: "Code",
    width: 70,
  },
  {
    field: "category",
    headerName: "Category",
    width: 70,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "sectorName",
    headerName: "Sector Name",
    width: 500,
  },
  {
    field: "relatedContent",
    headerName: "Related Content",
    width: 300,
    renderCell: (params: any) => (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
          <ListIcon />
          <Typography variant="body2" fontSize={"12px"}>
            {params.value}
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    field: "code",
    headerName: "",
    width: 50,
    renderCell: (params: any) => (
      <ArrowRightIconButtonCell {...params} type="sectors" />
    ),
  },
];
