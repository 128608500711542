import React from "react";

export const PlayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
    >
      <circle cx="22.6666" cy="22.0636" r="21.9923" fill="#3B6CD3" />
      <path
        d="M34.3967 22.3076L17.1694 32.2538L17.1694 12.3614L34.3967 22.3076Z"
        fill="white"
      />
    </svg>
  );
};
