import React from "react";
import get from "lodash/get";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { SelectChangeEvent } from "@mui/material";
import { PageHeader } from "app/components/page-header";
import { useNavigate, useParams } from "react-router-dom";
import { SelectField } from "app/components/select-field";
import { ShareButton } from "app/components/share-button";
import { DownloadButton } from "app/components/download-button";
import { YearSelectButton } from "app/components/year-select-button";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { TransactionTypeSelect } from "app/pages/explorer/components/transaction-type";
import { TRANSACTION_TYPES } from "app/pages/explorer/components/transaction-type/data";
import { InsightsBlock } from "app/pages/explorer/pages/sectors/pages/sector/components/insights-block";
import { ReactComponent as ExplorerSectorHeaderIcon } from "app/assets/vectors/ExplorerSectorHeaderIcon.svg";

type ExpandedBlockType =
  | "locations"
  | "results"
  | "sdgs"
  | "networks"
  | "sectors"
  | "impact"
  | "organisations"
  | "recent-activities"
  | null;

export const ExplorerSector: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchOverviewStats = useStoreActions(
    (actions) => actions.ExplorerSectorPageOverviewStats.fetch
  );
  const fetchLocations = useStoreActions(
    (actions) => actions.ExplorerSectorPageLocations.fetch
  );
  const fetchSDGs = useStoreActions(
    (actions) => actions.ExplorerSectorPageSDGs.fetch
  );
  const fetchOrganisationsNetwork = useStoreActions(
    (actions) => actions.ExplorerSectorOrganisationsNetwork.fetch
  );
  const fetchSectorFunding = useStoreActions(
    (actions) => actions.ExplorerSectorPageSectorFunding.fetch
  );
  const fetchOrganisationsDonut = useStoreActions(
    (actions) => actions.ExplorerSectorOrganisationsDonut.fetch
  );
  const fetchImpact = useStoreActions(
    (actions) => actions.ExplorerSectorPageImpactResults.fetch
  );
  const fetchActivityTable = useStoreActions(
    (actions) => actions.ExplorerSectorPageActivityTable.fetch
  );

  const sectorName = useStoreState((state) =>
    get(state.ExplorerSectorPageOverviewStats, "data.data.name", "")
  );
  const sectorFilterOptions = useStoreState((state) =>
    get(state.ExplorerSectorsFilterOptions, "data.data.sectors", [])
  );
  const activitiesLoading = useStoreState(
    (state) => state.ExplorerOrganisationPageActivityTable.loading
  );

  const sectors: {
    code: string;
    name: string;
  }[] = React.useMemo(() => {
    return sectorFilterOptions.map(
      (sector: { value: string; label: string }) => ({
        code: sector.value,
        name: sector.label,
      })
    );
  }, [sectorFilterOptions]);

  const [activitiesPage, setActivitiesPage] = React.useState(1);
  const [transactionType, setTransactionType] = React.useState(
    TRANSACTION_TYPES[0]
  );
  const [selectedPeriod, setSelectedPeriod] = React.useState({
    startYear: "2023",
    endYear: "2023",
    startMonth: "1",
    endMonth: "12",
  });
  const [expandedBlock, setExpandedBlock] =
    React.useState<ExpandedBlockType>(null);

  const handleSectorChange = (e: SelectChangeEvent) => {
    navigate(`/explorer/sectors/${e.target.value}`);
  };

  const handleSetExpandedBlock = (block: typeof expandedBlock) => {
    setExpandedBlock((prev) => (prev === block ? null : block));
  };

  const onLoadMoreActivities = () => {
    if (activitiesLoading) return;
    setActivitiesPage((prev) => {
      fetchActivityTable({
        addOnData: true,
        filterString: `sector=${id}&period=${periodRouteParam}`,
        routeParams: {
          page: (prev + 1).toString(),
        },
      });
      return prev + 1;
    });
  };

  const periodLabel = React.useMemo(() => {
    if (selectedPeriod.startYear === selectedPeriod.endYear) {
      return selectedPeriod.startYear;
    }
    if (selectedPeriod.startMonth === "1" && selectedPeriod.endMonth === "12") {
      return `${selectedPeriod.startYear} - ${selectedPeriod.endYear}`;
    }
    return `${selectedPeriod.startMonth.length === 1 ? "0" : ""}${
      selectedPeriod.startMonth
    }-${selectedPeriod.startYear} - ${
      selectedPeriod.endMonth.length === 1 ? "0" : ""
    }${selectedPeriod.endMonth}-${selectedPeriod.endYear}`;
  }, [selectedPeriod]);

  const periodRouteParam = React.useMemo(() => {
    return `${selectedPeriod.startYear}-${
      selectedPeriod.startMonth.length < 2 ? "0" : ""
    }${selectedPeriod.startMonth}|${selectedPeriod.endYear}-${
      selectedPeriod.endMonth.length < 2 ? "0" : ""
    }${selectedPeriod.endMonth}`;
  }, [selectedPeriod]);

  React.useEffect(() => {
    if (id) {
      fetchOverviewStats({
        routeParams: {
          code: id,
          period: periodRouteParam,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
      fetchLocations({
        filterString: `sector=${id}`,
        routeParams: {
          period: periodRouteParam,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
      fetchOrganisationsDonut({
        filterString: `sector=${id}`,
        routeParams: {
          period: periodRouteParam,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
    }
  }, [id, periodRouteParam, transactionType]);

  React.useEffect(() => {
    if (id) {
      let sdgsFetchPeriod = periodRouteParam;
      if (
        selectedPeriod.startYear === selectedPeriod.endYear &&
        selectedPeriod.startMonth === "1" &&
        selectedPeriod.endMonth === "12"
      ) {
        sdgsFetchPeriod = selectedPeriod.startYear;
      }
      fetchSDGs({
        filterString: `sector=${id}`,
        routeParams: {
          year: sdgsFetchPeriod,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
    }
  }, [id, selectedPeriod, transactionType]);

  React.useEffect(() => {
    if (id) {
      fetchSectorFunding({
        filterString: `sector=${id}`,
        routeParams: {
          year: selectedPeriod.startYear,
          transactionTypeCode: transactionType.code.toString(),
        },
      });
    }
  }, [id, selectedPeriod.startYear, transactionType]);

  React.useEffect(() => {
    if (id) {
      fetchOrganisationsNetwork({
        filterString: `sector=${id}`,
        routeParams: {
          period: periodRouteParam,
        },
      });
      fetchImpact({
        filterString: `sector=${id}`,
        routeParams: {
          period: periodRouteParam,
        },
      });
      fetchActivityTable({
        filterString: `sector=${id}&period=${periodRouteParam}`,
        routeParams: {
          page: "1",
        },
      });
    }
  }, [id, periodRouteParam]);

  return (
    <Box>
      <PageHeader
        variant="explorer"
        title={sectorName}
        subtitle={`Showcasing sector-specific data for ${sectorName}.`}
        icon={<ExplorerSectorHeaderIcon />}
      />
      <Box height={30} />
      <Grid display="flex" alignItems="center" justifyContent="space-between">
        <SelectField
          id="sector-select"
          options={sectors.map((s) => ({
            label: s.name,
            value: String(s.code),
          }))}
          placeholder="Select Another Sector"
          value={id ?? ""}
          onChange={handleSectorChange}
          variant="outlined"
          width="300px"
        />
        <Grid display="flex" alignItems="center" columnGap="8px">
          <TransactionTypeSelect
            value={transactionType}
            setValue={setTransactionType}
          />
          <YearSelectButton data={selectedPeriod} setData={setSelectedPeriod} />
          <ShareButton id="share-button" />
          <DownloadButton id="download-button" />
        </Grid>
      </Grid>
      <Box height={30} />
      <InsightsBlock
        sector={sectorName}
        period={periodLabel}
        expandedBlock={expandedBlock}
        transactionType={transactionType}
        setExpandedBlock={handleSetExpandedBlock}
        loadMoreActivities={onLoadMoreActivities}
      />
    </Box>
  );
};
