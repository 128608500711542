import React from "react";
import { Accord } from "../accord";
import { Box, Typography } from "@mui/material";

export const About = () => {
  return (
    <Accord
      title="About AIDA"
      subtitle="Discover the heart and soul behind AIDA. Dive into the journey that brought us here, our mission, and the passionate team driving innovation. Get to know the faces behind the brand and explore the values that shape every product we create."
    >
      <Box
        sx={{
          padding: "20px",
          paddingTop: "40px",
        }}
      >
        <Typography variant="h3" fontSize={"24px"} lineHeight={"normal"}>
          Vision and mission
        </Typography>
        <Typography
          marginTop={"10px"}
          variant="h3"
          lineHeight={"24px"}
          fontSize={"18px"}
          fontWeight={"400"}
        >
          We aim to create a transparent world where you are empowered to use
          data to drive change for a better future. The Open Data Company
          empowers organizations by unlocking the full potential of data in
          order for them to drive change in today's complex & interconnected
          world.
        </Typography>
        <Box height={30} />
        <Typography variant="h3" fontSize={"24px"} lineHeight={"normal"}>
          About
        </Typography>
        <Typography
          marginTop={"10px"}
          variant="h3"
          lineHeight={"24px"}
          fontSize={"18px"}
          fontWeight={"400"}
        >
          The Open Data Company B.V. was set up in 2021 and is a joint venture
          between <u>Data4Development B.V.</u> and <u>Zimmerman B.V.</u> We are
          committed to guiding global organisations in their journey towards
          data-driven decision making. AIDA is the ultimate aid professional
          toolkit, the place where your data journey starts.
        </Typography>
      </Box>
    </Accord>
  );
};
