import React from "react";

export const MastercardLogo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="38"
      height="22"
      viewBox="0 0 38 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.345636"
        y="1.05364"
        width="37.5534"
        height="19.8932"
        rx="1.92842"
        stroke="#A1AEBD"
        stroke-width="0.202991"
      />
      <g clip-path="url(#clip0_5419_8640)">
        <path
          d="M21.6706 15.6929H16.4385V6.29004H21.6707L21.6706 15.6929Z"
          fill="#FF5F00"
        />
        <path
          d="M16.7697 10.9918C16.7697 9.08438 17.6627 7.38528 19.0534 6.29031C18.0008 5.46025 16.6987 5.00974 15.3582 5.01173C12.0558 5.01173 9.37891 7.68903 9.37891 10.9918C9.37891 14.2945 12.0558 16.9718 15.3582 16.9718C16.6988 16.9738 18.0008 16.5233 19.0535 15.6932C17.6629 14.5984 16.7697 12.8992 16.7697 10.9918Z"
          fill="#EB001B"
        />
        <path
          d="M28.7288 10.9918C28.7288 14.2945 26.0519 16.9718 22.7496 16.9718C21.4088 16.9737 20.1066 16.5233 19.0537 15.6932C20.4448 14.5982 21.3379 12.8992 21.3379 10.9918C21.3379 9.08428 20.4448 7.38528 19.0537 6.29031C20.1066 5.46027 21.4088 5.00978 22.7495 5.01173C26.0518 5.01173 28.7287 7.68903 28.7287 10.9918"
          fill="#F79E1B"
        />
      </g>
      <defs>
        <clipPath id="clip0_5419_8640">
          <rect
            width="19.4872"
            height="11.9765"
            fill="white"
            transform="translate(9.37891 5.01172)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
