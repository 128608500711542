import { AccountProfileCard } from "app/components/account-profile-card";
import { Box } from "@mui/material";
import { Table } from "app/components/table";
import { OrganisationmemberColumns, OrganisationmemberData } from "./tableData";

export const OrganisationMembersSetting = (props: {
  setActiveSetting?: (value: string) => void;
}) => {
  return (
    <AccountProfileCard
      title="Organisation"
      content={
        <Box>
          <Table
            columns={OrganisationmemberColumns}
            rows={OrganisationmemberData}
            hideHeader
            withAddFileButton
            withDeleteButton
            withSearchButton
            title="5  Members"
            getRowHeight={() => "auto"}
            onAddButtonClick={() => props.setActiveSetting?.("add-member")}
          />
        </Box>
      }
      setActiveSetting={props.setActiveSetting}
      onBack={() => props.setActiveSetting?.("")}
    />
  );
};
