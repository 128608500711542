import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { FileRejection } from "react-dropzone";
import { Stepper } from "app/components/stepper";
import Typography from "@mui/material/Typography";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { PublisherBlockUploadView } from "app/pages/publisher/components/publisher-block/views/upload";
import { PublisherBlockPublishView } from "app/pages/publisher/components/publisher-block/views/publish";
import { PublisherBlockValidateView } from "app/pages/publisher/components/publisher-block/views/validate";
import { PublisherBlockPreVizPopup } from "app/pages/publisher/components/publisher-block/views/preVizPopup";
import { PublisherBlockConnectToIATIView } from "app/pages/publisher/components/publisher-block/views/connectToIATI";
import { PublisherBlockValidityReportPopup } from "app/pages/publisher/components/publisher-block/views/validityReportPopup";
import {
  ConvertedFile,
  CONVERTED_FILES_ROWS,
  CONVERTED_FILE_ACTIVITIES_ROWS,
} from "app/components/table/data";

export const PublisherBlock: React.FC = () => {
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = React.useState(-1);
  const [uploading, setUploading] = React.useState(false);
  const [preVizPopupOpen, setPreVizPopupOpen] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = React.useState<File[]>([]);
  const [uploadViewMessage, setUploadViewMessage] = React.useState("");
  const [nextButtonPopupOpen, setNextButtonPopupOpen] = React.useState(false);
  const [rejectedFiles, setRejectedFiles] = React.useState<FileRejection[]>([]);
  const [uploadSuccess, setUploadSuccess] = React.useState<boolean | null>(
    null
  );
  const [validityReportPopupOpen, setValidityReportPopupOpen] = React.useState<
    string | null
  >(null);
  const [convertedFiles, setConvertedFiles] =
    React.useState<ConvertedFile[]>(CONVERTED_FILES_ROWS);

  const isAuth = false;

  const onNextClick = () => {
    if (!uploading) {
      if (activeStep === 0 && selectedFiles.length > 0 && !uploadSuccess) {
        setUploading(true);
        setUploadViewMessage(
          `Uploading ${selectedFiles.length} file${selectedFiles.length > 1 ? "s" : ""}, please wait...`
        );
        setTimeout(() => {
          setUploading(false);
          setUploadViewMessage(
            `Upload of the ${selectedFiles.length} file${selectedFiles.length > 1 ? "s" : ""} is successful!`
          );
          setUploadSuccess(true);
        }, 2000);
      } else {
        setActiveStep((prev) => prev + 1);
      }
    }
  };

  const onBackClick = () => {
    if (!uploading) {
      setActiveStep((prev) => prev - 1);
    }
  };

  const isNextActive = React.useMemo(() => {
    if (uploading) return false;
    if (activeStep === 0) return selectedFiles.length > 0;
    return !nextButtonPopupOpen;
  }, [activeStep, selectedFiles, uploading, nextButtonPopupOpen]);

  const isBackActive = React.useMemo(() => {
    if (uploading) return false;
    return true;
  }, [uploading]);

  const handlePreVizPopupOpenChange = (value: boolean) => () => {
    setPreVizPopupOpen(value);
  };

  const handleValidityReportOpenChange = (value: string | null) => {
    setValidityReportPopupOpen(value);
  };

  const handleConvertedFilePublishStatusChange = (
    fileId: string,
    value: boolean
  ) => {
    setConvertedFiles((prev) =>
      prev.map((file) =>
        file.id === fileId ? { ...file, isPublished: value } : file
      )
    );
  };

  const buttons = React.useMemo(() => {
    if (activeStep === 0 || activeStep === 1 || activeStep === 2) {
      return (
        <Box
          sx={{
            gap: "10px",
            width: "100%",
            height: "33px",
            display: "flex",
            padding: "0 15px",
            marginTop: "10px",
            flexDirection: "row",
            justifyContent: "flex-end",
            "> button": {
              padding: "2px 10px",
              color: colors.primary.white,
              background: colors.primary.green,
              ":hover": {
                background: colors.shades.green[500],
              },
              ":disabled": {
                color: colors.primary.white,
                background: colors.secondary.lightGrayText,
              },
            },
          }}
        >
          {activeStep === 1 && (
            <Box>
              <Tooltip
                placement="top"
                title={
                  <Box
                    sx={{
                      gap: "8px",
                      width: "230px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography fontSize="10px" fontWeight="700">
                      See the preview of your data visualisation.
                    </Typography>
                    <Typography fontSize="10px">
                      Proceed with the publishing steps to interact with and
                      expand the charts.
                    </Typography>
                  </Box>
                }
              >
                <Button
                  disableRipple
                  onClick={handlePreVizPopupOpenChange(true)}
                  sx={{
                    fontSize: "12px",
                    fontWeight: "normal",
                    color: colors.text.title,
                    textDecoration: "underline",
                    ":hover": {
                      background: "transparent",
                      textDecoration: "underline",
                    },
                  }}
                >
                  See Pre-Visualisations of Your Data
                </Button>
              </Tooltip>
            </Box>
          )}
          <Button
            onClick={onBackClick}
            disabled={!isBackActive}
            startIcon={<ArrowBack />}
          >
            Back
          </Button>
          <Tooltip
            open={nextButtonPopupOpen}
            placement="top"
            title={
              <Box
                sx={{
                  gap: "8px",
                  width: "230px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography fontSize="10px" fontWeight="700">
                  Sign Up & Register to Continue!
                </Typography>
                <Typography fontSize="10px">
                  Dear user, your credentials are needed to complete the upload
                  process, so that AIDA can match your data files with your
                  organisation!
                </Typography>
              </Box>
            }
          >
            <Button
              onClick={onNextClick}
              disabled={!isNextActive}
              endIcon={<ArrowForward />}
            >
              Next
            </Button>
          </Tooltip>
        </Box>
      );
    }
    if (activeStep === 3) {
      return (
        <Box
          sx={{
            gap: "10px",
            width: "100%",
            height: "33px",
            display: "flex",
            padding: "0 15px",
            marginTop: "10px",
            flexDirection: "row",
            justifyContent: "flex-end",
            "> button": {
              padding: "2px 10px",
              color: colors.primary.white,
              background: colors.primary.green,
              ":hover": {
                background: colors.shades.green[500],
              },
              ":disabled": {
                color: colors.primary.white,
                background: colors.secondary.lightGrayText,
              },
            },
          }}
        >
          <Button
            onClick={onBackClick}
            disabled={!isBackActive}
            startIcon={<ArrowBack />}
          >
            Back
          </Button>
          <Button
            disabled={!isNextActive}
            endIcon={<ArrowForward />}
            onClick={() => navigate("/data-hub")}
          >
            Your Data Hub
          </Button>
        </Box>
      );
    }
  }, [activeStep, isBackActive, isNextActive, nextButtonPopupOpen]);

  const steps = React.useMemo(() => {
    const result = [
      {
        title: "Connect Your Data",
        description:
          "Connect your files and convert to IATI. Upload your data through online data clouds, or manually.",
      },
      {
        title: "Validate & Visualise Your Data",
        description:
          "Validate & Visualise the converted files. See your validity report, browse the visualisations of your uploaded files before publishing.",
      },
      {
        title: "Connect to IATI Registry",
        description:
          "Connect to IATI registry to streamline your publishing process.",
        info: "This step is a one time process only! Register once to remove this step from your publishing flow.",
      },
      {
        title: "Publish to the IATI Registry",
        description:
          "Publish to the IATI registry and share your impact to the world.",
      },
    ];
    if (!isAuth) {
      return result;
    }
    return [result[0], result[1], result[3]];
  }, [isAuth]);

  const onToolbarButtonClick = () => {
    if (activeStep > -1) {
      setActiveStep(-1);
    } else {
      setActiveStep(0);
    }
  };

  const description = React.useMemo(() => {
    if (activeStep > -1) {
      return (
        <React.Fragment>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<ArrowBack fontSize="small" />}
            onClick={onToolbarButtonClick}
            sx={{
              top: "10px",
              right: "10px",
              fontSize: "12px",
              textWrap: "nowrap",
              padding: "3px 10px",
              position: "absolute",
            }}
          >
            Back to Dashboard
          </Button>
          <Box marginTop="10px">
            <Stepper
              steps={[
                "connect your files",
                "validate & visualise",
                "connect to iati",
                "publish to iati registry",
                "",
              ]}
              activeStep={activeStep === 3 ? 4 : activeStep}
            />
          </Box>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        You haven't publish{" "}
        <span
          style={{
            color: colors.primary.green,
          }}
        >
          any IATI files yet
        </span>
        . Start publishing today and visualise your impact.
      </React.Fragment>
    );
  }, [activeStep]);

  const view = React.useMemo(() => {
    if (activeStep > -1) {
      switch (activeStep) {
        case 0:
          return (
            <React.Fragment>
              <PublisherBlockUploadView
                message={uploadViewMessage}
                selectedFiles={selectedFiles}
                rejectedFiles={rejectedFiles}
                setSelectedFiles={setSelectedFiles}
                setRejectedFiles={setRejectedFiles}
              />
              {buttons}
            </React.Fragment>
          );
        case 1:
          return (
            <React.Fragment>
              <PublisherBlockValidateView
                activities={CONVERTED_FILE_ACTIVITIES_ROWS}
                seeTheReportAction={handleValidityReportOpenChange}
              />
              {buttons}
            </React.Fragment>
          );
        case 2:
          return (
            <React.Fragment>
              <PublisherBlockConnectToIATIView
                setNextButtonPopupOpen={setNextButtonPopupOpen}
              />
              {buttons}
            </React.Fragment>
          );
        case 3:
          return (
            <React.Fragment>
              <PublisherBlockPublishView
                files={convertedFiles}
                changePublishStatus={handleConvertedFilePublishStatusChange}
              />
              {buttons}
            </React.Fragment>
          );
        default:
          return null;
      }
    }
    return (
      <Box
        sx={{
          width: "100%",
          height: "450px",
          display: "flex",
          padding: "15px",
          flexDirection: "column",
          background: colors.secondary.lightGrey,
        }}
      >
        <Box
          sx={{
            gap: "10px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.0007 31.1654C24.8247 31.1654 31.1673 24.8227 31.1673 16.9987C31.1673 9.17466 24.8247 2.83203 17.0007 2.83203C9.17662 2.83203 2.83398 9.17466 2.83398 16.9987C2.83398 24.8227 9.17662 31.1654 17.0007 31.1654Z"
              fill="#66BCB9"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.2216 11.015C24.7969 11.4604 24.9646 12.3123 24.5988 12.969C21.7608 18.0634 19.1784 21.453 17.6524 23.2674C16.8316 24.2433 15.5119 24.3685 14.5812 23.517C13.2525 22.3013 11.2997 20.2375 9.4309 17.2313C9.07871 16.6648 9.155 15.9094 9.59388 15.4195L10.7191 14.1636C11.243 13.5789 12.1041 13.5628 12.6325 14.1427C14.4931 16.1852 15.9362 18.0372 15.9362 18.0372C15.9362 18.0372 18.0568 14.5971 21.3497 10.4261C21.8048 9.84961 22.59 9.75214 23.1593 10.1928L24.2216 11.015Z"
              fill="#004845"
            />
          </svg>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h5" fontSize="14px" fontWeight="700">
              Connect and transform your data faster than ever!
            </Typography>
            <Typography variant="h5" fontSize="14px" fontWeight="400">
              Follow the streamlined 4-step upload process to share your impact
              with ease.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            padding: "0 70px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container rowSpacing={6} columnSpacing={4}>
            {steps.map((step, index) => (
              <Grid key={step.title} item xs={12} sm={isAuth ? 12 : 6}>
                <Box
                  sx={{
                    gap: "20px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      fontSize: "32px",
                      minWidth: "44px",
                      maxWidth: "44px",
                      minHeight: "44px",
                      maxHeight: "44px",
                      fontWeight: "700",
                      borderRadius: "50%",
                      alignItems: "center",
                      justifyContent: "center",
                      color: colors.primary.white,
                      background: colors.shades.green[400],
                    }}
                  >
                    {index + 1}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h4" fontSize="18px" fontWeight="700">
                      {step.title}
                    </Typography>
                    <Typography
                      variant="h5"
                      flexWrap="wrap"
                      fontSize="14px"
                      fontWeight="400"
                    >
                      {step.description}
                    </Typography>
                    {step.info && (
                      <Typography
                        variant="h5"
                        padding="5px"
                        flexWrap="wrap"
                        fontSize="12px"
                        fontWeight="400"
                        fontStyle="italic"
                        color={colors.text.title}
                        border={`1px solid ${colors.shades.green[400]}`}
                        sx={{
                          marginTop: "5px",
                          borderRadius: "5px",
                          background: colors.shades.green[100],
                        }}
                      >
                        {step.info}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    );
  }, [steps, activeStep, buttons, uploadViewMessage, convertedFiles]);

  React.useEffect(() => {
    if (!uploading && activeStep === 0 && selectedFiles.length > 0) {
      setUploadViewMessage(
        `${selectedFiles.length} file${selectedFiles.length > 1 ? "s" : ""} selected`
      );
    }
  }, [selectedFiles]);

  return (
    <React.Fragment>
      <GenericPageBlockItem
        title="Publisher"
        description={description}
        rightToolbar={
          activeStep === -1 ? (
            <Button
              color="secondary"
              variant="contained"
              onClick={onToolbarButtonClick}
              endIcon={<ArrowForward fontSize="small" />}
              sx={{
                fontSize: "12px",
                textWrap: "nowrap",
                padding: "3px 10px",
              }}
            >
              Start Publishing
            </Button>
          ) : null
        }
        content={view}
      />
      <PublisherBlockPreVizPopup
        open={preVizPopupOpen}
        onClose={handlePreVizPopupOpenChange(false)}
      />
      <PublisherBlockValidityReportPopup
        value={validityReportPopupOpen}
        open={Boolean(validityReportPopupOpen)}
        onClose={() => handleValidityReportOpenChange(null)}
      />
    </React.Fragment>
  );
};
