import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { colors } from "app/theme";
import { useNavigate } from "react-router-dom";

export interface HeaderExpandedViewProps {
  activeButton: string;
  handleButtonClick: (label: string) => void;
}

interface HeaderViewCommonBlockItemProps {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  link?: string;
}

export const HeaderViewCommonBlockItem: React.FC<
  HeaderViewCommonBlockItemProps
> = (props: HeaderViewCommonBlockItemProps) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        gap: "10px",
        padding: "5px",
        display: "flex",
        cursor: "pointer",
        borderRadius: "5px",
        flexDirection: "row",
        alignItems: "center",
        border: "1px solid transparent",
        ":hover": {
          borderColor: colors.secondary.iconGray,
          background: colors.secondary.disableButton,
          h5: {
            color: colors.primary.blue,
          },
        },
      }}
      onClick={() => {
        if (props.link) {
          navigate(props.link);
        }
      }}
    >
      {props.icon}
      <Box
        sx={{
          gap: "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography variant="h5" fontSize="14px" margin="0">
          {props.title}
        </Typography>
        <Typography fontSize="10px">{props.subtitle}</Typography>
      </Box>
    </Box>
  );
};
