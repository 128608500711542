import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { AccountProfileCard } from "app/components/account-profile-card";
import { UsernameSettings } from "app/pages/account-profile/components/username";
import { JobTitleSettings } from "app/pages/account-profile/components/job-title";
import { AccountProfileLayout } from "app/pages/account-profile/components/layout";
import { ReactComponent as GoogleDriveIcon } from "app/assets/vectors/ExploreGoogleDriveLogo.svg";
import { OrganisationInformationSetting } from "app/pages/account-profile/components/organisation-information";
import { OrganisationMembersSetting } from "./components/organisation-members";
import { AddMemberSetting } from "./components/add-member";
import { ConnectedAccountsSetting } from "./components/connected-accounts";
import { FileAutomationSetting } from "./components/file-automation";
import { PricingPlanSetting } from "./components/pricing-plan";
import { PaymentDetailsSetting } from "./components/payment-details";
import { ChangePaymentMethodSetting } from "./components/change-payment-method";
import { ChangeBillingInformationSetting } from "./components/change-billing-information";

export const AccountProfile: React.FC = () => {
  const [activeSetting, setActiveSetting] = React.useState("");

  const activeSettingView = React.useMemo(() => {
    switch (activeSetting) {
      case "username":
        return <UsernameSettings setActiveSetting={setActiveSetting} />;
      case "job-title":
        return <JobTitleSettings setActiveSetting={setActiveSetting} />;
      case "organisation-information":
        return (
          <OrganisationInformationSetting setActiveSetting={setActiveSetting} />
        );

      case "organisation-members":
        return (
          <OrganisationMembersSetting setActiveSetting={setActiveSetting} />
        );
      case "add-member":
        return <AddMemberSetting setActiveSetting={setActiveSetting} />;
      case "connected-accounts":
        return <ConnectedAccountsSetting setActiveSetting={setActiveSetting} />;
      case "google-drive-file-automation":
        return (
          <FileAutomationSetting
            option="google-drive"
            setActiveSetting={setActiveSetting}
          />
        );
      case "microsoft-cloud-file-automation":
        return (
          <FileAutomationSetting
            option="microsoft-cloud"
            setActiveSetting={setActiveSetting}
          />
        );
      case "pricing-plan":
        return <PricingPlanSetting setActiveSetting={setActiveSetting} />;

      case "payment-details":
        return <PaymentDetailsSetting setActiveSetting={setActiveSetting} />;

      case "change-payment-method":
        return (
          <ChangePaymentMethodSetting setActiveSetting={setActiveSetting} />
        );

      case "change-billing-information":
        return (
          <ChangeBillingInformationSetting
            setActiveSetting={setActiveSetting}
          />
        );
      default:
        return (
          <AccountProfileCard
            title=""
            content={<React.Fragment />}
            setActiveSetting={setActiveSetting}
          />
        );
    }
  }, [activeSetting]);

  return (
    <AccountProfileLayout>
      {activeSetting ? (
        <Box>{activeSettingView}</Box>
      ) : (
        <Box>
          <AccountProfileCard
            title="Profile"
            content={[
              {
                key: "username",
                content: ["User Name", "Jane Doe"],
              },
              {
                key: "job-title",
                content: ["Job Title", "Finance Officer"],
              },
            ]}
            setActiveSetting={setActiveSetting}
          />
          <Box height={40} />
          <AccountProfileCard
            title="Organisation"
            content={[
              {
                key: "organisation-information",
                content: ["Organisation Information", "Zimmerman"],
              },
              {
                key: "organisation-members",
                content: ["Members", "5"],
              },
              {
                key: "connected-accounts",
                content: [
                  "Connected Accounts",
                  <Button
                    sx={{
                      fontSize: "12px",
                      lineHeight: "normal",
                      display: "flex",
                      alignItems: "center",
                    }}
                    variant="contained"
                    startIcon={<GoogleDriveIcon />}
                  >
                    Connected to Google Drive
                  </Button>,
                ],
              },
            ]}
            setActiveSetting={setActiveSetting}
          />
          <Box height={40} />
          <AccountProfileCard
            title="Pricing Plan"
            content={[
              {
                key: "pricing-plan",
                content: [
                  "Pricing Plan",
                  <b>Standard</b>,
                  <span>
                    <b>5</b> User Licenses
                  </span>,
                  <span>
                    <b>Active</b> Support
                  </span>,
                ],
              },
              {
                key: "payment-details",
                content: ["Payment Method"],
              },
            ]}
            setActiveSetting={setActiveSetting}
          />
        </Box>
      )}
    </AccountProfileLayout>
  );
};
