import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

export const ExplorerActivityTable: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/explorer/activity/list/{page}`),
};

export const ExplorerHomeActivityTable: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/explorer/activity/list/{page}`),
};

// Activity page

export const ExplorerActivityPage: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/explorer/activity/{id}/detail`),
};

export const ExplorerActivityPageTransactions: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/activity/{id}/transactions`
  ),
};

export const ExplorerActivityPageTransactionsLineChart: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/activity/{id}/transactions/line-chart`
  ),
};

export const ExplorerActivityPageRelatedActivities: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/activity/{id}/related-activities`
  ),
};

export const ExplorerActivityPageOrganisations: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/activity/{id}/organisations`
  ),
};

export const ExplorerActivityPageResults: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/explorer/activity/{id}/results`),
};
