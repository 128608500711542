import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

export const ExplorerImpactResults: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/impact-results/{period}`
  ),
};

export const ExplorerLocationPageImpactResults: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/impact-results/{period}`
  ),
};

export const ExplorerOrganisationPageImpactResults: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/impact-results/{period}`
  ),
};

export const ExplorerSectorPageImpactResults: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/impact-results/{period}`
  ),
};
