import React from "react";
import get from "lodash/get";
import sumBy from "lodash/sumBy";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import groupBy from "lodash/groupBy";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Table } from "app/components/table";
import Typography from "@mui/material/Typography";
import { PieChart } from "app/components/charts/pie";
import { SDGChart } from "app/components/charts/sdg";
import { formatNumber } from "app/utils/formatNumber";
import { useStoreState } from "app/state/store/hooks";
import { formatLocaleN } from "app/utils/formatLocale";
import { LineChart } from "app/components/charts/line";
import { GeomapChart } from "app/components/charts/geomap";
import { ImpactChart } from "app/components/charts/impact";
import { CtaCommonBtn } from "app/components/cta-common-btn";
import { NetworkChart } from "app/components/charts/network";
import { ScrollIntoView } from "app/components/scroll-into-view";
import { ArrowFall, ArrowRise } from "app/assets/vectors/jsx/arrow";
import { OverviewBlock } from "app/components/grid-blocks/overview";
import { ChartPlaceholder } from "app/components/chart-placeholder";
import { YearSelectWidget } from "app/components/year-select-widget";
import { GeomapSampleData } from "app/components/charts/geomap/data";
import { CtaSectorIcon } from "app/assets/vectors/jsx/CtaSectorIcon";
import { LocationsBlock } from "app/components/grid-blocks/locations";
import { IMPACT_COLUMNS } from "app/components/charts/impact/tableData";
import { CtaActivityIcon } from "app/assets/vectors/jsx/CtaActivityIcon";
import { ImpactChartItemProps } from "app/components/charts/impact/data";
import { CtaLocationIcon } from "app/assets/vectors/jsx/CtaLocationIcon";
import { CtaFavouriteIcon } from "app/assets/vectors/jsx/CtaFavouriteIcon";
import { CtaPublisherIcon } from "app/assets/vectors/jsx/CtaPublisherIcon";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { CtaOrganisationIcon } from "app/assets/vectors/jsx/CtaOrganisationIcon";
import { GenericPageBlockItemLocationDescription } from "app/components/generic-page-block-item/data";
import { ACTIVITIES_COLUMNS } from "app/pages/home/explorer/components/live-data-updates/tableColumns";
import { InsightsBlockProps } from "app/pages/explorer/pages/sectors/pages/sector/components/insights-block/data";

export const InsightsBlock: React.FC<InsightsBlockProps> = (
  props: InsightsBlockProps
) => {
  const overviewStats: {
    name: string;
    value: number;
    countries: number;
    activities: number;
    plannedActivities: number;
  } = useStoreState((state) =>
    get(state.ExplorerSectorPageOverviewStats.data, "data", {
      name: "",
      value: 0,
      countries: 0,
      activities: 0,
      plannedActivities: 0,
    })
  );
  const overviewStatsLoading = useStoreState(
    (state) => state.ExplorerSectorPageOverviewStats.loading
  );
  const overview = React.useMemo(() => {
    const items = [
      {
        name: props.transactionType.label,
        value: `USD ${formatNumber(overviewStats.value, true)}`,
      },
      { name: "Activities", value: overviewStats.activities },
      { name: "Planned Activities", value: overviewStats.plannedActivities },
      { name: "Recipient Countries", value: overviewStats.countries },
    ];

    return (
      <GenericPageBlockItem
        toolbarShare
        height="100%"
        title="Overview"
        loading={overviewStatsLoading}
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              USD {formatNumber(overviewStats.value, true)}
            </span>{" "}
            was spent for{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {overviewStats.countries} recipient countries
            </span>{" "}
            in {props.period}.
          </React.Fragment>
        }
        content={<OverviewBlock items={items} variant="grid" />}
      />
    );
  }, [props.sector, overviewStats, props.period, props.transactionType]);

  const locationsData: {
    stats: {
      code: string;
      name: string;
      value: number;
      count: number;
    }[];
    geo_data: {
      name: string;
      iso2: string;
      value: number;
      full_name: string;
    }[];
  } = useStoreState((state) =>
    get(state.ExplorerSectorPageLocations.data, "data", {
      stats: [],
      geo_data: [],
    })
  );
  const locationsLoading = useStoreState(
    (state) => state.ExplorerSectorPageLocations.loading
  );
  const locations = React.useMemo(() => {
    const items = locationsData.stats.map((item) => ({
      code: item.code.toLowerCase(),
      name: item.name,
      funds: `USD ${formatNumber(item.value, true)}`,
      activities: item.count,
    }));

    return (
      <React.Fragment>
        <GenericPageBlockItem
          toolbarShare
          toolbarExpand
          title="Locations"
          loading={locationsLoading}
          onExpand={() => props.setExpandedBlock("locations")}
          description={
            <GenericPageBlockItemLocationDescription
              period={props.period}
              items={locationsData.stats.map((item) => item.name)}
            />
          }
          content={
            <LocationsBlock
              items={items}
              transactionType={props.transactionType.label}
            />
          }
          expanded={props.expandedBlock === "locations"}
        />
        {props.expandedBlock === "locations" && (
          <Box
            height="650px"
            padding="0 30px 20px 30px"
            bgcolor={colors.primary.white}
          >
            <Divider />
            <Box height={50} />

            <GeomapChart
              data={GeomapSampleData}
              showLegend
              showTooltip
              height="500px"
              variant="explorer"
              showZoomWidget
            />
          </Box>
        )}
      </React.Fragment>
    );
  }, [props.expandedBlock, locationsData, props.transactionType]);

  const sdgsData: {
    count: number;
    data: {
      code: number;
      name: string;
      value: number;
    }[];
    changePercentage: number;
  } = useStoreState((state) =>
    get(state.ExplorerSectorPageSDGs.data, "data", {
      count: 0,
      data: [],
      changePercentage: 0,
    })
  );
  const sdgsLoading = useStoreState(
    (state) => state.ExplorerSectorPageSDGs.loading
  );
  const sdgs = React.useMemo(() => {
    const items = sdgsData.data.map((item) => ({
      sdg: item.code,
      name: item.name,
      value: item.value,
    }));

    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        loading={sdgsLoading}
        title="Sustainable Development Goals"
        onExpand={() => props.setExpandedBlock("sdgs")}
        description={
          <React.Fragment>
            In {props.period},{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {sdgsData.count} activities
            </span>{" "}
            were contributing to the SDGs.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize="12px" lineHeight="normal">
              There was a {sdgsData.changePercentage.toFixed(2)}% increase in{" "}
              {props.period} in the number of organisations publishing data on
              SDGs, compared to previous period. <br />
              Top six trending Sustainable Development Goals in {
                props.period
              }{" "}
              for {props.sector}:
            </Typography>
            <Box height={28} />
            <SDGChart
              labelGroup="Activities"
              expanded={props.expandedBlock === "sdgs"}
              data={props.expandedBlock === "sdgs" ? items : items.slice(0, 6)}
            />
          </React.Fragment>
        }
        expanded={props.expandedBlock === "sdgs"}
      />
    );
  }, [props.expandedBlock, props.sector, sdgsData, props.period]);

  const networksData: {
    nodes: {
      id: string;
      name: string;
      symbolSize: number;
      category: number;
    }[];
    links: {
      source: string;
      target: string;
    }[];
    categories: {
      name: string;
    }[];
  } = useStoreState((state) =>
    get(state.ExplorerSectorOrganisationsNetwork.data, "data", {
      nodes: [],
      links: [],
      categories: [],
    })
  );
  const networksLoading = useStoreState(
    (state) => state.ExplorerSectorOrganisationsNetwork.loading
  );
  const networks = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Networks"
        loading={networksLoading}
        onExpand={() => props.setExpandedBlock("networks")}
        height="100%"
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {networksData.nodes.length} organisations
            </span>{" "}
            have published data on their development and humanitarian resources
            and activities.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize="12px" lineHeight="normal">
              See the organisation network by their organisation type:
            </Typography>
            <NetworkChart
              zoom={0}
              data={networksData}
              showZoomWidget={props.expandedBlock === "networks"}
            />
          </React.Fragment>
        }
        expanded={props.expandedBlock === "networks"}
      />
    );
  }, [props.expandedBlock, networksData, networksLoading, props.period]);

  const sectorsData: {
    code: string;
    name: string;
    value: number;
    prevValue: number;
    changePercentage: number;
  }[] = useStoreState((state) =>
    get(state.ExplorerSectorPageSectorFunding.data, "data", [])
  );
  const sectorsLoading = useStoreState(
    (state) => state.ExplorerSectorPageSectorFunding.loading
  );
  const sectors = React.useMemo(() => {
    const item = get(sectorsData, "[0]", {
      code: "",
      name: "",
      value: 0,
      prevValue: 0,
      changePercentage: 0,
    });

    const Legend = (props: {
      percentageChange: number;
      year: number;
      value: string;
      name: string;
    }) => {
      return (
        <Box>
          <Typography variant="subtitle2" fontWeight="bold">
            {props.name}
          </Typography>
          <Box height={20} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                lineHeight="normal"
                color={colors.secondary.blue}
              >
                {props.value}
              </Typography>
              <Typography variant="body2" fontSize="12px" lineHeight="normal">
                {props.year}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "5px",
              }}
            >
              <Typography
                variant="button"
                color={
                  props.percentageChange < 0 ? colors.secondary.red : "#2EA390"
                }
              >
                {props.percentageChange > 0 ? "+" : null}
                {props.percentageChange.toFixed(2)}%
              </Typography>
              {props.percentageChange < 0 ? <ArrowFall /> : <ArrowRise />}
            </Box>
          </Box>
        </Box>
      );
    };

    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Sector Funding"
        loading={sectorsLoading}
        onExpand={() => props.setExpandedBlock("sectors")}
        height="100%"
        description={
          <React.Fragment>
            Funding for Emergency Response sector has been{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              increased {item.changePercentage.toFixed(2)}%
            </span>{" "}
            in {props.period} compared to previous period data.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Box height={64} />
            {props.expandedBlock === "sectors" ? (
              <LineChart
                id="sector-funding"
                data={{
                  xAxisValues: [
                    (parseInt(props.period, 10) - 1).toString(),
                    props.period,
                  ],
                  series: [
                    {
                      name: "USD",
                      values: [item.prevValue, item.value],
                    },
                  ],
                }}
                yAxisLabelName="USD"
                height="594px"
                lineColor={colors.secondary.blue}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <LineChart
                  id="sector-funding"
                  yAxisLabelName="USD"
                  variant="signalLine"
                  height="250px"
                  width="80%"
                  hideXaxisLine
                  data={{
                    xAxisValues: [
                      (parseInt(props.period, 10) - 1).toString(),
                      props.period,
                    ],
                    series: [
                      {
                        name: "USD",
                        values: [item.prevValue, item.value],
                      },
                    ],
                  }}
                />
              </Box>
            )}

            {props.expandedBlock === "sectors" ? null : (
              <>
                <Box height={10} />
                <Legend
                  name=""
                  percentageChange={item.changePercentage}
                  value={`USD ${formatNumber(item.value, true)}`}
                  year={2023}
                />
              </>
            )}
            {props.expandedBlock === "sectors" ? (
              <Box
                sx={{
                  padding: "10px 0",
                  marginTop: "20px",
                }}
              >
                <YearSelectWidget />
              </Box>
            ) : null}
          </React.Fragment>
        }
        expanded={props.expandedBlock === "sectors"}
      />
    );
  }, [props.expandedBlock, sectorsData, sectorsLoading, props.period]);

  const organisationsData: {
    id: string;
    name: string;
    count: number;
    value: number;
  }[] = useStoreState((state) =>
    get(state.ExplorerSectorOrganisationsDonut.data, "data", [])
  );
  const organisationsLoading = useStoreState(
    (state) => state.ExplorerSectorOrganisationsDonut.loading
  );
  const organisations = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Organisations"
        loading={organisationsLoading}
        onExpand={() => props.setExpandedBlock("organisations")}
        description={
          <React.Fragment>
            Top 10 organisations provides in total of{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {formatNumber(sumBy(organisationsData, "value"), true)}{" "}
              development aid
            </span>{" "}
            in {props.period}. Which makes up the{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              85% of the total development aid.
            </span>
          </React.Fragment>
        }
        content={
          <PieChart
            id="organisations-pie"
            donut
            half
            data={organisationsData}
            unit="USD"
            unitType="organisations"
            period={props.period}
            height={props.expandedBlock === "organisations" ? "500px" : "350px"}
          />
        }
        expanded={props.expandedBlock === "organisations"}
      />
    );
  }, [
    props.expandedBlock,
    organisationsData,
    props.period,
    organisationsLoading,
  ]);

  const impactData = useStoreState(
    (state) =>
      get(
        state.ExplorerSectorPageImpactResults,
        "data.data",
        []
      ) as ImpactChartItemProps[]
  );
  const impactLoading = useStoreState(
    (state) => state.ExplorerSectorPageImpactResults.loading
  );
  const impact = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Impact"
        loading={impactLoading}
        onExpand={() => props.setExpandedBlock("impact")}
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {Object.keys(groupBy(impactData, "title")).length} activities
            </span>{" "}
            went above and beyond their target results in {props.period},
            showcasing noteworthy achievements and positive change.
          </React.Fragment>
        }
        content={
          props.expandedBlock === "impact" ? (
            <Table
              withSearch
              rows={impactData}
              headerFontSize="12px"
              columns={IMPACT_COLUMNS}
            />
          ) : (
            <ImpactChart items={impactData.slice(0, 4)} />
          )
        }
        expanded={props.expandedBlock === "impact"}
      />
    );
  }, [props.expandedBlock, impactData, props.period, impactLoading]);

  const activities = useStoreState((state) =>
    get(state.ExplorerSectorPageActivityTable, "data.data", [])
  );
  const activitiesCount = useStoreState((state) =>
    get(state.ExplorerSectorPageActivityTable, "data.count", 0)
  );
  const activitiesLoading = useStoreState(
    (state) => state.ExplorerSectorPageActivityTable.loading
  );
  const recentActivities = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Recent Activities"
        altLoading={activitiesLoading}
        expanded={props.expandedBlock === "recent-activities"}
        onExpand={() => props.setExpandedBlock("recent-activities")}
        description={
          <React.Fragment>
            Currently{" "}
            <span style={{ color: colors.primary.blue }}>
              {formatLocaleN(activitiesCount)} activities{" "}
            </span>
            taking place for {props.sector} in {props.period}.
          </React.Fragment>
        }
        content={
          <Table
            rows={activities}
            autoHeight={false}
            headerFontSize="12px"
            loading={activitiesLoading}
            columns={ACTIVITIES_COLUMNS}
            loadMore={props.loadMoreActivities}
            hasMore={activitiesCount > activities.length}
          />
        }
      />
    );
  }, [
    activities,
    props.period,
    props.sector,
    activitiesCount,
    activitiesLoading,
    props.expandedBlock,
    props.loadMoreActivities,
  ]);

  const expandedBlockView = React.useMemo(() => {
    switch (props.expandedBlock) {
      case "locations":
        return locations;
      case "sdgs":
        return sdgs;
      case "networks":
        return networks;
      case "organisations":
        return organisations;
      case "sectors":
        return sectors;
      case "impact":
        return impact;
      case "recent-activities":
        return recentActivities;
      default:
        return null;
    }
  }, [
    props.expandedBlock,
    locations,
    sdgs,
    networks,
    organisations,
    sectors,
    recentActivities,
    impact,
  ]);

  const getBlockHeight = React.useCallback((blockId: string) => {
    const el = document.getElementById(blockId);
    return el ? `${el.offsetHeight}px` : "100%";
  }, []);

  const locationsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Locations"),
    [locations]
  );
  const sdgsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Sustainable Development Goals"),
    [sdgs]
  );
  const networksBlockHeight = React.useMemo(
    () => getBlockHeight("block-Networks"),
    [networks]
  );
  const organisationsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Organisations"),
    [organisations]
  );
  const sectorsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Sector Funding"),
    [sectors]
  );
  const impactBlockHeight = React.useMemo(
    () => getBlockHeight("block-Impact"),
    [impact]
  );
  const recentActivitiesBlockHeight = React.useMemo(
    () => getBlockHeight("block-Recent Activities"),
    [recentActivities]
  );

  return (
    <Grid container spacing={4}>
      {props.expandedBlock && (
        <Grid item xs={12}>
          <ScrollIntoView trigger={props.expandedBlock} />
          {expandedBlockView}
        </Grid>
      )}
      <Grid item xs={12} md={5}>
        {overview}
      </Grid>
      <Grid item xs={12} md={7}>
        {props.expandedBlock !== "locations" ? (
          locations
        ) : (
          <ChartPlaceholder height={locationsBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} md={5}>
        {props.expandedBlock !== "sdgs" ? (
          sdgs
        ) : (
          <ChartPlaceholder height={sdgsBlockHeight} />
        )}
        <Box sx={{ height: 20 }} />
        <Box
          sx={{
            gap: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CtaCommonBtn
            label="Your Favourites"
            variant="medium"
            bgColor="blue"
            bgIcon={<CtaFavouriteIcon />}
          />
          <CtaCommonBtn
            label="Publish Your Data"
            variant="medium"
            bgColor="blue"
            bgIcon={<CtaPublisherIcon />}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={7}>
        {props.expandedBlock !== "networks" ? (
          networks
        ) : (
          <ChartPlaceholder height={networksBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} md={5}>
        {props.expandedBlock !== "sectors" ? (
          sectors
        ) : (
          <ChartPlaceholder height={sectorsBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} md={7}>
        {props.expandedBlock !== "organisations" ? (
          organisations
        ) : (
          <ChartPlaceholder height={organisationsBlockHeight} />
        )}

        <Box
          sx={{
            gap: "20px",
            display: "flex",
            flexDirection: "row",
            marginTop: "30px",
          }}
        >
          <CtaCommonBtn
            label="Discover Locations"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaLocationIcon />}
          />
          <CtaCommonBtn
            label="Discover Organisations"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaOrganisationIcon />}
          />
          <CtaCommonBtn
            label="Discover Sectors"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaSectorIcon />}
          />
          <CtaCommonBtn
            label="Search & Filter Activities"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaActivityIcon />}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        {props.expandedBlock !== "impact" ? (
          impact
        ) : (
          <ChartPlaceholder height={impactBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} md={12}>
        {props.expandedBlock !== "recent-activities" ? (
          recentActivities
        ) : (
          <ChartPlaceholder height={recentActivitiesBlockHeight} />
        )}
      </Grid>
    </Grid>
  );
};
