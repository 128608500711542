import { colors } from "app/theme";

interface ExplorerSearchResultProps {
  activities: {
    count: number;
    items: Activity[];
    hasMore?: boolean;
  };
  locations: {
    count: number;
    items: ILocation[];
  };
  organisations: {
    count: number;
    items: Organisation[];
  };
  sectors: {
    count: number;
    items: Sector[];
  };
}

export const emptySearchResults: ExplorerSearchProps["results"] = {
  activities: {
    count: 0,
    items: [],
  },
  locations: {
    count: 0,
    items: [],
  },
  organisations: {
    count: 0,
    items: [],
  },
  sectors: {
    count: 0,
    items: [],
  },
};

export interface ExplorerSearchProps {
  loading: boolean;
  searchValue: string;
  loadMore?: () => void;
  results: ExplorerSearchResultProps;
  setSearchValue: (value: string) => void;
}

export interface ExplorerSearchResultsPopupProps {
  id: string;
  loading: boolean;
  searchValue: string;
  loadMore?: () => void;
  handleClose: () => void;
  anchorEl: HTMLInputElement | null;
  results: ExplorerSearchResultProps;
}

export const ButtonStyle = {
  fontSize: "12px",
  fontWeight: "700",
  padding: "6px 10px",
  textTransform: "none",
  color: colors.primary.white,
  background: colors.primary.blue,
};

export type TabType = "activities" | "locations" | "organisations" | "sectors";

export interface TabProps {
  value: TabType;
  activeTab: TabType;
  children: React.ReactNode;
  setActiveTab: React.Dispatch<React.SetStateAction<TabType>>;
}

interface Activity {
  id: string;
  name: string;
  status: string;
  location: string;
}

export const ACTIVITIES: Activity[] = [
  {
    id: "1",
    name: "Nexus Energy Water in Zambia (NEWZA)",
    status: "Pipeline/Identification",
    location: "Zambia",
  },
];

interface ILocation {
  id: string;
  code: string;
  location: string;
  relatedContent: string;
}

export const LOCATIONS: ILocation[] = [
  {
    id: "1",
    code: "SO",
    location: "Somalia",
    relatedContent: "3256 related activities.",
  },
];

interface Organisation {
  id: string;
  iatiId: string;
  organisation: string;
  relatedContent: string;
}

export const ORGANISATIONS: Organisation[] = [
  {
    id: "1",
    iatiId: "XM-DAC-41301",
    organisation: "Food and Agriculture Organization (FAO)",
    relatedContent: "3256 related activities.",
  },
];

interface Sector {
  id: string;
  code: string;
  category: string;
  sectorName: string;
  relatedContent: string;
}

export const SECTORS: Sector[] = [
  {
    id: "1",
    code: "14010",
    category: "140",
    sectorName: "Water sector policy and administrative management",
    relatedContent: "3256 related activities.",
  },
];
