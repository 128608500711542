import React from "react";
import { AccountProfileCard } from "app/components/account-profile-card";
import { Box, Typography } from "@mui/material";
import { SelectField } from "app/components/select-field";

export const JobTitleSettings = (props: {
  setActiveSetting?: (value: string) => void;
}) => {
  const [job, setJob] = React.useState("Finance Manager");
  return (
    <AccountProfileCard
      title="profile"
      disableSave={!job}
      content={
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            variant="body2"
            lineHeight={"20px"}
            marginBottom={"10px"}
            component={"label"}
            htmlFor="job-title"
            display={"block"}
          >
            Job Title
          </Typography>

          <SelectField
            id="job-title"
            value={job}
            onChange={(e) => setJob(e.target.value)}
            options={[
              { label: "Academic", value: "Academic" },
              { label: "Business Controller", value: "Business Controller" },
              { label: "Compliance Director", value: "Compliance Director" },
              {
                label: "Compliance Coordinator",
                value: "Compliance Coordinator",
              },
              { label: "Data Consultant", value: "Data Consultant" },
              {
                label: "Donor Relations Manager",
                value: "Donor Relations Manager",
              },
              { label: "Finance Manager", value: "Finance Manager" },
              { label: "Fundraising Manager", value: "Fundraising Manager" },
              {
                label: "Grant Proposal Manager",
                value: "Grant Proposal Manager",
              },
              {
                label: "Monitoring and Evaluation Officer",
                value: "Monitoring and Evaluation Officer",
              },
              { label: "Program Director", value: "Program Director" },
              { label: "Program Manager", value: "Program Manager" },
              { label: "Program Officer", value: "Program Officer" },
              { label: "Project Manager", value: "Project Manager" },
              { label: "Policy Officer", value: "Policy Officer" },
              { label: "Researcher", value: "Researcher" },
              { label: "Student", value: "Student" },
            ]}
            width="300px"
            variant="outlined"
            fontWeight="400"
          />
        </Box>
      }
      setActiveSetting={props.setActiveSetting}
      onBack={() => props.setActiveSetting?.("")}
    />
  );
};
