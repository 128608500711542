import { Home } from "app/pages/home";
import { Page } from "app/components/page";
import { DataHub } from "app/pages/data-hub";
import { Explorer } from "app/pages/explorer";
import { RouteObject } from "react-router-dom";
import { Publisher } from "app/pages/publisher";
import { ExplorerSectors } from "app/pages/explorer/pages/sectors";
import { ExplorerLocations } from "app/pages/explorer/pages/locations";
import { ExplorerActivities } from "app/pages/explorer/pages/activities";
import { ExplorerFavourites } from "app/pages/explorer/pages/favourites";
import { ExplorerOrganisations } from "app/pages/explorer/pages/organisations";
import { ExplorerSector } from "app/pages/explorer/pages/sectors/pages/sector";
import { ExplorerLocation } from "app/pages/explorer/pages/locations/pages/location";
import { ExplorerActivity } from "app/pages/explorer/pages/activities/pages/activity";
import { Login } from "app/pages/login";
import { AccountProfile } from "app/pages/account-profile";
import { UserGuide } from "app/pages/user-guide";
import { DataServices } from "app/pages/data-services";
import { ExplorerOrganisation } from "app/pages/explorer/pages/organisations/pages/organisation";

export const ROUTES: RouteObject[] = [
  {
    path: "/",
    element: <Page />,
    errorElement: <div>404</div>,
    children: [
      { path: "/", element: <Home /> },
      { path: "/publisher", element: <Publisher /> },
      { path: "/explorer", element: <Explorer /> },
      { path: "/explorer/locations", element: <ExplorerLocations /> },
      { path: "/explorer/locations/:id", element: <ExplorerLocation /> },
      { path: "/explorer/sectors/:id", element: <ExplorerSector /> },
      {
        path: "/explorer/organisations/:id",
        element: <ExplorerOrganisation />,
      },
      { path: "/explorer/organisations", element: <ExplorerOrganisations /> },
      { path: "/explorer/activities", element: <ExplorerActivities /> },
      { path: "/explorer/activities/:id", element: <ExplorerActivity /> },
      { path: "/explorer/sectors", element: <ExplorerSectors /> },
      { path: "/explorer/favourites", element: <ExplorerFavourites /> },
      { path: "/data-services", element: <DataServices /> },
      { path: "/data-hub", element: <DataHub /> },
      { path: "/login", element: <Login /> },
      { path: "/account-profile", element: <AccountProfile /> },
      { path: "/user-guide", element: <UserGuide /> },
      {
        path: "*",
        element: (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "calc(100vh - 91px - 256px - 150px)",
            }}
          >
            Page not found
          </div>
        ),
      },
    ],
  },
];
