import React from "react";
import { useSearchParams } from "react-router-dom";
import { Hero } from "./components/hero";
import { Box } from "@mui/material";
import { ProductOffers } from "./components/product-offers";
import { PublisherBanner } from "./components/publisher-banner";
import { ExplorerBanner } from "./components/explorer-banner";

export const DataServices = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const variant = searchParams.get("v");

  React.useEffect(() => {
    if (!variant || !["publisher", "explorer"].includes(variant)) {
      setSearchParams({ v: "explorer" });
    }
  }, [variant]);
  return (
    <React.Fragment>
      <Hero />
      <Box height={50} />
      <ProductOffers />
      <Box height={50} />
      <PublisherBanner />

      <Box height={60} />
      <ExplorerBanner />
    </React.Fragment>
  );
};
