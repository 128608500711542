import React from "react";
import { colors } from "app/theme";
import MuiLinearProgress from "@mui/material/LinearProgress";

export const LinearProgress = () => {
  return (
    <MuiLinearProgress
      sx={{
        height: "8px",
        borderRadius: "5px",
        background: colors.shades.blue[200],
        "> span": {
          borderRadius: "5px",
          background: colors.text.title,
        },
      }}
    />
  );
};
