import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import * as echarts from "echarts/core";
import { SVGRenderer } from "echarts/renderers";
import { useChartResizeObserver } from "app/hooks/useChartResizeObserver";
import {
  BarSeriesOption,
  LineSeriesOption,
  LineChart as EChartsLine,
  BarChart as EChartsBar,
} from "echarts/charts";
import { GridComponent, LegendComponent } from "echarts/components";
import { BarLineChartProps } from "app/components/charts/bar-line/data";

echarts.use([
  EChartsBar,
  EChartsLine,
  SVGRenderer,
  GridComponent,
  LegendComponent,
]);

export const BarLineChart: React.FC<BarLineChartProps> = (
  props: BarLineChartProps
) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [stateChart, setStateChart] =
    React.useState<echarts.EChartsType | null>(null);

  useChartResizeObserver({
    chart: stateChart,
    containerRef: containerRef,
    containerId: `${props.id}-bar-line-chart`,
  });

  React.useEffect(() => {
    if (containerRef.current) {
      const chart = echarts.init(containerRef.current, undefined, {
        renderer: "svg",
      });
      const series: (BarSeriesOption | LineSeriesOption)[] =
        props.data.series.map((s) => {
          return {
            name: s.name,
            type: s.type,
            barWidth: props.barWidth ?? "40",
            label: {
              show: false,
            },
            data: s.values,
            lineStyle: {
              color: colors.charts[5],
              width: 2,
            },
            symbolSize: 10,
            symbol: "emptyCircle",
            itemStyle: {
              color: s.type === "line" ? colors.charts[5] : undefined,
            },
          };
        });
      const option: echarts.ComposeOption<BarSeriesOption | LineSeriesOption> =
        {
          grid: {
            containLabel: true,
            top: 80,
            left: 0,
            bottom: 0,
            right: 0,
          },
          legend: {
            data: props.data.series.map((s) => s.name),
            show: !!props.showLegend,
            left: 0,
            textStyle: {
              fontSize: 10,
            },
          },
          color:
            props.data.series.length > 2
              ? colors.charts.reverse()
              : colors.charts.slice(0, 1),
          xAxis: {
            type: "category",
            data: props.data.bars,
            axisLabel: {
              rotate: props.xAxisLabelRotate,
              overflow: "break",
              fontSize: 8.5,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              onZero: false,
            },
          },
          yAxis: {
            type: "value",
            name: props.yAxisLabelName,
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
            },
            axisLabel: {
              fontSize: 10,
            },
          },
          series,
        };

      chart.setOption(option);
      setStateChart(chart);
    }
  }, [containerRef.current, props.data]);

  return (
    <Box
      id={`${props.id}-bar-line-chart`}
      ref={containerRef}
      width="100%"
      height={props.height ?? "450px"}
    />
  );
};
