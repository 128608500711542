import React from "react";

const SearchBigIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4831 22.202C27.6571 20.2727 28.3333 18.0071 28.3333 15.5835C28.3333 8.54188 22.6249 2.8335 15.5833 2.8335C8.54164 2.8335 2.83325 8.54188 2.83325 15.5835C2.83325 22.6251 8.54164 28.3335 15.5833 28.3335C18.0068 28.3335 20.2724 27.6573 22.2017 26.4833C22.3182 26.6236 22.4342 26.7636 22.5501 26.9034C23.7477 28.3483 24.9243 29.7678 26.2789 31.1656C27.1006 32.0134 28.3269 32.1359 29.2045 31.3461C29.5154 31.0663 29.8791 30.7226 30.3007 30.301C30.7223 29.8794 31.0661 29.5156 31.3459 29.2047C32.1357 28.3272 32.0131 27.1009 31.1653 26.2792C29.7676 24.9246 28.3481 23.748 26.9031 22.5503C26.7634 22.4344 26.6233 22.3184 26.4831 22.202Z"
        fill="#182B54"
      />
      <path
        d="M15.5833 24.0835C20.2777 24.0835 24.0833 20.2779 24.0833 15.5835C24.0833 10.8891 20.2777 7.0835 15.5833 7.0835C10.8888 7.0835 7.08325 10.8891 7.08325 15.5835C7.08325 20.2779 10.8888 24.0835 15.5833 24.0835Z"
        fill="#9DB6E9"
      />
    </svg>
  );
};

export default SearchBigIcon;
