import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link, useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import ExplorerHeroImage from "app/assets/images/data-services-explorer-hero-image.png";
import PublisherHeroImage from "app/assets/images/data-services-publisher-hero-image.png";

const ButtonStyle = {
  color: colors.primary.white,
  padding: "10px 15px",
  width: "max-content",
  fontSize: "18px",
  lineHeight: "normal",
};

export const Hero: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const variant = searchParams.get("v");
  return (
    <Grid
      container
      columnSpacing={"50px"}
      columns={100}
      sx={{
        padding: "20px 0",
      }}
    >
      {" "}
      <Grid
        item
        xs={100}
        md={54.4}
        sx={{
          "> img": {
            width: "100%",
            height: "auto",
          },
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h3">
          Unlock Impact with AIDA Data Services!
        </Typography>
        <Box height={10} />
        <Typography variant="body1" fontSize={"18px"}>
          Explore the power of AIDA's premier data services designed to elevate
          your experience. From seamless publishing tools for sharing your
          insights to robust data exploration features, our platform is your
          gateway to a world of impactful information. Discover, publish, and
          thrive with AIDA!
        </Typography>
      </Grid>
      <Grid
        item
        xs={100}
        md={44.6}
        sx={{
          "> img": {
            width: "100%",
            height: "auto",
            filter:
              "drop-shadow(0px 4.982px 9.964px rgba(97, 97, 97, 0.20)) drop-shadow(0px 2.491px 4.982px rgba(97, 97, 97, 0.20))",
          },
        }}
      >
        <img
          src={variant === "publisher" ? PublisherHeroImage : ExplorerHeroImage}
          alt="explore-hero-image"
        />
      </Grid>
    </Grid>
  );
};
