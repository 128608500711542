import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ChartTooltipProps } from "app/components/charts/tooltip/data";

export const ChartTooltip: React.FC<ChartTooltipProps> = (
  props: ChartTooltipProps
) => {
  return (
    <Box zIndex={100} top={props.top} left={props.left} position="absolute">
      <Box
        sx={{
          gap: "8px",
          width: "230px",
          display: "flex",
          padding: "10px",
          borderRadius: "4px",
          position: "relative",
          flexDirection: "column",
          background: colors.secondary.lightGrey,
          boxShadow: "0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
          border: `1px solid ${colors.secondary.lightGrayText}`,
          "::after": {
            top: "-11px",
            content: '""',
            right: "110px",
            position: "absolute",
            border: "5px solid transparent",
            borderBottomColor: colors.secondary.lightGrayText,
          },
        }}
      >
        <Typography fontSize="12px" fontWeight="700">
          {props.title}
        </Typography>
        <Typography fontSize="12px">{props.subtitle}</Typography>
      </Box>
    </Box>
  );
};
